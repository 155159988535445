import React from 'react'

export class FlyoutTooltip extends React.Component {
  render() {
    const { x, y, activePoints } = this.props
    const getLabelName = (activePoint) => {
      return activePoint.status
        ? `${activePoint.status.toUpperCase()}`
        : activePoint.x
    }
    return (
      <g style={{ pointerEvents: 'none' }}>
        <foreignObject
          x={x}
          y={y}
          width={activePoints.length > 1 ? 100 : 75}
          height="100"
          style={{ overflow: 'visible' }}>
          <div
            className={`flyout-tooltip bg-white shadow-md px-2 py-1 ${
              activePoints.length > 1 ? 'rounded-md' : 'rounded-sm'
            }`}>
            {activePoints.map((activePoint, i) => (
              <div
                className="text-dark-50 flex justify-between items-center"
                style={{ fontSize: activePoints.length > 1 ? '7px' : '9px' }}
                key={`${activePoint.status}-${i}`}>
                <strong>{getLabelName(activePoint)}:</strong>
                <span className="text-navy-4 font-bold ml-2">
                  {activePoint.y}
                </span>
              </div>
            ))}
          </div>
        </foreignObject>
      </g>
    )
  }
}

export default FlyoutTooltip
