import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const FormCheckbox = ({
  checked = false,
  onChange,
  label,
  classes = '',
  radioStyle = false,
  disabled = false,
}) => {
  return (
    <label
      className={`flex items-center my-2 ${classes} ${
        disabled ? 'opacity-25' : ''
      }`}>
      <div
        className={`h-6 w-6 relative flex items-center transition-all duration-300 justify-center ${
          radioStyle ? 'rounded-full' : 'rounded-md'
        } ${
          checked
            ? 'bg-teal-4 border-teal-4'
            : 'border border-dark-50 hover:border-navy-5'
        }`}>
        {radioStyle ? (
          <div
            className={`bg-white rounded-full h-2 w-2 transition-opacity ${
              checked ? 'opacity-1' : 'opacity-0'
            }`}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCheck}
            className={`h-3 w-3 text-white transition-opacity ${
              checked ? 'opacity-1' : 'opacity-0'
            }`}
          />
        )}
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="absolute h-full w-full top-0 left-0 opacity-0"
          disabled={disabled}
        />
      </div>
      <span className="ml-2 font-light">{label}</span>
    </label>
  )
}

export default FormCheckbox
