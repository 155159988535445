import { Fragment, useContext, useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { useToasts } from 'react-toast-notifications'
import { useRouter } from 'next/router'

import { GlobalContext } from '../context/GlobalContext'
import { Button, Alert } from '.'
import api from '../services/api'
import ArrowIcon from '../public/arrow_right.svg'
import Icon from './Icon'

const VerifyEmailPrompt = ({ token }) => {
  const globalContext = useContext(GlobalContext)
  const router = useRouter()
  const [isWorking, setIsWorking] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const { addToast } = useToasts()

  useEffect(() => {
    if (token) {
      setIsWorking(true)
      api
        .verifyEmail(token)
        .then((response) => {
          if (globalContext.user) {
            globalContext.updateUser({
              user: response,
            })
          }
          router.push('/workspaces')
        })
        .catch(globalContext.handleFetchError)
        .finally(() => setIsWorking(false))
    }
  }, [token])

  const sendNewEmail = () => {
    setIsWorking(true)
    api
      .requestEmailVerification()
      .then((response) => {
        addToast('Check your email for verification instructions!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000,
        })
      })
      .catch(globalContext.handleFetchError)
      .finally(() => setIsWorking(false))
  }

  return (
    <div className="flex flex-col py-12 md:py-24 px-4 items-center">
      {isWorking && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50 z-50">
          <Loader type="TailSpin" height="72" width="72" color="white" />
        </div>
      )}
      {isConfirmed ? (
        <Fragment>
          <h1 className="mb-4">Email confirmed!</h1>
          <Button
            href={globalContext.user ? '/workspaces' : '/'}
            text="Let's Get Started"
            IconRight={() => <Icon icon={ArrowIcon} fill="white" />}
          />
        </Fragment>
      ) : (
        <Fragment>
          {token && isWorking ? (
            <div>Verifying email...</div>
          ) : (
            <h1 className="mb-5 text-2xl font-serif">
              Before you get started, you need to verify your email!
            </h1>
          )}
          <div className="flex">
            {globalContext.user && (
              <Fragment>
                <Button
                  theme="ghost"
                  text="Send another verification email"
                  action={() => null}
                  classes="mr-3"
                  action={sendNewEmail}
                />
                <Button
                  text="Refresh"
                  action={() =>
                    globalContext.refreshUser(globalContext.user?.id)
                  }
                />
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default VerifyEmailPrompt
