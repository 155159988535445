import { useState, useContext } from 'react'
import { useMutation } from 'react-query'
import { useRouter } from 'next/router'
import { useToasts } from 'react-toast-notifications'
import Loader from 'react-loader-spinner'

import {
  CardContainer,
  CardHeader,
  Icon,
  CardBody,
  FormCheckbox,
  Modal,
  Button,
  Avatar,
} from '.'
import CloseIcon from '../public/close_circle.svg'
import api from '../services/api'
import { GlobalContext } from '../context/GlobalContext'
import { isWorkspaceAdmin } from '../utils'

const ChangeWorkspaceAdminModal = ({ isOpen, close, workspace }) => {
  const [newAdminUserId, setNewAdminUserId] = useState()
  const globalContext = useContext(GlobalContext)
  const router = useRouter()
  const { addToast } = useToasts()

  const mutation = useMutation(
    () => api.updateWorkspaceAdmin(router.query.workspaceId, newAdminUserId),
    {
      onSuccess: () => {
        globalContext.refreshUser(globalContext.user.id)
        addToast('Admin transferred successfully!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        })
        router.push(`/workspaces/${router.query.workspaceId}/dashboard`)
      },
    },
  )

  const workspaceAdmins = workspace?.team_users.filter((u) =>
    isWorkspaceAdmin(u),
  )
  const workspaceNonAdmins = workspace?.team_users.filter(
    (u) => !isWorkspaceAdmin(u),
  )

  return (
    <Modal isOpen={isOpen}>
      <CardContainer
        classes="relative w-186 max-w-full max-h-full overflow-auto mx-5"
        hasHeader>
        <CardHeader
          title="Change Workspace Admin"
          ButtonComponent={() => (
            <button onClick={close}>
              <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
            </button>
          )}
        />
        <CardBody disablePadding>
          <div className="flex flex-col">
            <div>
              {workspaceAdmins
                ?.concat(workspaceNonAdmins)
                .map((workspaceUser, index) => {
                  const isCurrentAdmin =
                    workspaceAdmins.length &&
                    workspaceUser.id === workspaceAdmins[0].id
                  return (
                    <button
                      key={workspaceUser.id}
                      onClick={
                        isCurrentAdmin
                          ? () => null
                          : () =>
                              setNewAdminUserId(
                                workspaceUser.id === newAdminUserId
                                  ? null
                                  : workspaceUser.id,
                              )
                      }
                      className={`flex sm:items-center sm:justify-between py-4 px-2 w-full relative border-b border-dark-15 ${
                        isCurrentAdmin ? 'cursor-default' : ''
                      }`}>
                      <div className="flex flex-col sm:flex-row sm:items-center flex-1">
                        <Avatar
                          image={workspaceUser.user?.image}
                          type="user"
                          size="small"
                        />
                        <div className="flex flex-col items-start justify-start mt-4 sm:mt-0">
                          <div className="text-left">
                            <span className="font-bold">
                              {workspaceUser.user
                                ? `${workspaceUser.user.first_name} ${workspaceUser.user.last_name}`
                                : `${workspaceUser.first_name} ${workspaceUser.last_name}`}
                            </span>
                            <span className="font-light text-sm">{`${
                              workspaceUser.user?.title
                                ? `, ${workspaceUser.user.title}`
                                : ''
                            }`}</span>
                          </div>
                          <div className="text-dark-50 text-xs font-bold">
                            {workspaceUser.user?.email || workspaceUser.email}
                          </div>
                        </div>
                      </div>
                      <div className="w-24 flex justify-end items-center">
                        {isCurrentAdmin ? (
                          <div className="text-dark-50 uppercase text-xs">
                            Current Admin
                          </div>
                        ) : (
                          <FormCheckbox
                            checked={newAdminUserId === workspaceUser.id}
                            label=""
                            classes="px-2"
                            onChange={() => null}
                          />
                        )}
                      </div>
                    </button>
                  )
                })}
            </div>
            <div className="flex justify-end mt-5 p-4">
              <Button
                action={close}
                text="Cancel"
                theme="ghost"
                classes="mr-3"
              />
              <Button
                action={mutation.mutate}
                disabled={!newAdminUserId}
                text="Confirm"
              />
            </div>
          </div>
        </CardBody>
        {mutation.isLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-15 rounded-xl">
            <Loader type="TailSpin" height="72" width="72" color="white" />
          </div>
        )}
      </CardContainer>
    </Modal>
  )
}

export default ChangeWorkspaceAdminModal
