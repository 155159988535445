import { useState, useContext } from 'react'
import { useMutation } from 'react-query'
import Loader from 'react-loader-spinner'
import { useToasts } from 'react-toast-notifications'

import {
  TextInput,
  FormSelect,
  Button,
  FileInput,
  Icon,
  Avatar,
  FormCheckbox,
} from '.'
import { GlobalContext } from '../context/GlobalContext'
import { WorkspaceSettingsContext } from '../context/WorkspaceSettingsContext'
import api from '../services/api'
import CloseIcon from '../public/close_circle.svg'
import PencilIcon from '../public/pencil.svg'
import TrashIcon from '../public/trash.svg'
import timezones from '../utils/timezones'
import { isWorkspaceAdmin } from '../utils'

const WorkspaceGeneralSettings = ({
  workspace,
  openChangeAdminModal,
  workspacePaymentHasFailed,
}) => {
  const globalContext = useContext(GlobalContext)
  const workspaceSettingsContext = useContext(WorkspaceSettingsContext)
  const { addToast } = useToasts()
  const [hasMadeChange, setHasMadeChange] = useState(false)
  const [localWorkspace, setLocalWorkspace] = useState(
    workspace || {
      timezone: 'America/New_York',
    },
  )
  const [revertToWorkspace, setRevertToWorkspace] = useState(workspace || null)

  const updatelocalWorkspace = (key, val) => {
    setHasMadeChange(true)
    setLocalWorkspace({
      ...localWorkspace,
      [key]: val,
    })
  }

  const handleManageAccountClick = async () => {
    const portalSession = await api.getStripePortalSession({
      workspaceId: workspace.id,
      back: `/workspaces/${workspace.id}/settings`,
    })

    if (portalSession) {
      document.location.href = portalSession.url
    } else {
      addToast(
        'Sorry, there was an error connecting to the payment system. Please try again later',
        {
          appearance: 'error',
        },
      )
    }
  }

  const mutation = useMutation(() => api.updateWorkspace(localWorkspace), {
    onSuccess: (response) => {
      api
        .getWorkspace(response.id)
        .then((response) => {
          globalContext.setActiveWorkspace(response)
          workspaceSettingsContext.refetchWorkspace()
        })
        .catch(globalContext.handleFetchError)
      setHasMadeChange(false)
      setRevertToWorkspace(localWorkspace)
      addToast('Workspace updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })
    },
  })

  const revert = () => {
    setLocalWorkspace(revertToWorkspace)
    setHasMadeChange(false)
  }

  const adminWorkspaceUser = localWorkspace.team_users?.find((workspaceUser) =>
    isWorkspaceAdmin(workspaceUser),
  )

  return (
    <div>
      <div className="flex flex-col sm:flex-row items-center mb-8 mt-8">
        <Avatar
          image={localWorkspace ? localWorkspace.image : null}
          type="team"
        />
        <div className="flex-1 flex-col flex justify-center">
          <div className="flex flex-col sm:flex-row items-center">
            <FileInput
              onUpdate={(imageString) =>
                updatelocalWorkspace('image', imageString)
              }
              fileLabel="Workspace Icon"
              disabled={workspacePaymentHasFailed}
            />
            {localWorkspace?.image && (
              <Button
                action={() => updatelocalWorkspace('image', '')}
                text="REMOVE"
                size="small"
                theme="red-transparent"
                disabled={workspacePaymentHasFailed}
                IconLeft={() => (
                  <Icon
                    icon={(props) => <CloseIcon {...props} />}
                    size="small"
                    fill="#de4d15"
                  />
                )}
              />
            )}
          </div>
          <div className="text-dark-50 mt-4">
            Upload a photo for your profile (JPG, JPEG, PNG only; 1 MB Maximum File Size).
          </div>
        </div>
      </div>
      <TextInput
        label="Workspace Name"
        onChange={(e) => updatelocalWorkspace('name', e.target.value)}
        value={localWorkspace?.name}
        disabled={workspacePaymentHasFailed}
      />
      <FormSelect
        options={timezones.map((timezone) => ({
          value: timezone,
          label: timezone,
        }))}
        label="Workspace Timezone"
        onChange={(timezone) => {
          updatelocalWorkspace('timezone', timezone.value)
        }}
        value={{
          value: localWorkspace.timezone,
          label: localWorkspace.timezone,
        }}
        disabled={workspacePaymentHasFailed}
      />
      <FormCheckbox
        checked={localWorkspace.members_can_create_goals}
        label="Allow non-admins to create goals?"
        classes="my-10"
        disabled={workspacePaymentHasFailed}
        onChange={() =>
          updatelocalWorkspace(
            'members_can_create_goals',
            !localWorkspace.members_can_create_goals,
          )
        }
      />
      <div className="">
        <h2 className="mb-3">Account Admin</h2>
        <div className="flex flex-col sm:flex-row sm:justify-between pb-8 mt-4">
          <div className="flex items-center">
            <Avatar
              image={adminWorkspaceUser?.user.image}
              type="user"
              classes="w-12"
            />
            <div className="flex flex-col ml-2">
              <div>
                <span className="font-bold">
                  {adminWorkspaceUser?.user.first_name
                    ? `${adminWorkspaceUser?.user.first_name} ${adminWorkspaceUser?.user.last_name}`
                    : globalContext.name}
                </span>
                <span className="font-light text-sm">{`${
                  adminWorkspaceUser?.user.title
                    ? `, ${globalContext.user.title}`
                    : ''
                }`}</span>
              </div>
              <div className="text-dark-50 font-bold text-xs">
                {adminWorkspaceUser?.user.email}
              </div>
            </div>
          </div>
          <div className="flex mt-5 sm:mt-0">
            <Button
              action={openChangeAdminModal}
              size="small"
              theme="dark-transparent"
              text="EDIT"
              IconLeft={() => (
                <Icon icon={(props) => <PencilIcon {...props} />} />
              )}
              classes="self-center"
            />
            <Button
              action={openChangeAdminModal}
              text="REMOVE"
              size="small"
              theme="red-transparent"
              classes="self-center"
              IconLeft={() => (
                <Icon
                  icon={(props) => <CloseIcon {...props} />}
                  size="small"
                  fill="#de4d15"
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="mb-5">
        <h2 className="font-bold text-sm mb-2 mt-4">Cancel Account</h2>
        <p className="font-light">Would you like delete your account?</p>
        <p className="text-sm text-dark-50 mb-4">
          Click below to cancel your subscription and delete your workspace.
          Your account will remain active until the end of your current billing
          period.
        </p>
        <Button
          IconLeft={() => (
            <Icon icon={(props) => <TrashIcon {...props} />} fill="#de4d15" />
          )}
          text="DELETE WORKSPACE"
          action={handleManageAccountClick}
          theme="red"
          size="x-small"
        />
      </div>
      <div className="flex justify-end">
        <Button
          text="Discard"
          theme="ghost"
          classes="mr-3"
          disabled={!hasMadeChange}
          action={revert}
        />
        <Button
          text="Save"
          disabled={!hasMadeChange}
          action={mutation.mutate}
        />
      </div>
      {mutation.isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-dark-15 flex items-center justify-center z-50">
          <Loader type="TailSpin" height="72" width="72" color="white" />
        </div>
      )}
    </div>
  )
}

export default WorkspaceGeneralSettings
