import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'

export const WorkspaceSettingsContext = createContext({})
import api from '../services/api'
import { GlobalContext } from './GlobalContext'

const WorkspaceSettingsProvider = (props) => {
  const { children } = props
  const router = useRouter()
  const globalContext = useContext(GlobalContext)

  const {
    isLoading,
    error,
    data: workspace,
    refetch: refetchWorkspace,
  } = useQuery(
    ['workspace', { workspaceId: router.query.workspaceId }],
    () =>
      api.getWorkspace(router.query.workspaceId).catch((e) => {
        globalContext.handleFetchError(e)
        router.push(`/workspaces/${router.query.workspaceId}/dashboard`)
      }),
    {
      enabled: !!router.query?.workspaceId,
    },
  )

  const value = useMemo(() => {
    return {
      isLoading,
      workspace,
      refetchWorkspace,
    }
  }, [isLoading, workspace, refetchWorkspace])

  return (
    <WorkspaceSettingsContext.Provider value={value} {...props}>
      {children}
    </WorkspaceSettingsContext.Provider>
  )
}

export default WorkspaceSettingsProvider
