import { useContext, Fragment, useState } from 'react'
import { useQuery } from 'react-query'

import { GlobalContext } from '../context/GlobalContext'
import {
  FormSelect,
  FormCheckbox,
  FormDatePicker,
  TextInput,
  TextArea,
} from '.'
import api from '../services/api'

const EditGoalForm = ({ formValues, updateFormValue }) => {
  const globalContext = useContext(GlobalContext)
  const [showEndDateChangeWarning, setShowEndDateWarning] = useState(false)
  const {
    isLoading: optionsIsLoading,
    error: optionsError,
    data: options,
  } = useQuery(
    ['data-source-options', { id: formValues?.data_source_id }],
    () => api.getGoalMetaOptions(formValues.data_source_id),
    {
      enabled: !!formValues?.data_source_id,
    },
  )

  const {
    isLoading: filtersIsLoading,
    error: filtersError,
    data: filters,
  } = useQuery(
    ['goal-filters', `${formValues.dataSource}-${formValues.category?.value}`],
    () =>
      api.getGoalFilterOptions(
        formValues.data_source_id,
        formValues.category.value,
      ),
    {
      enabled: !!formValues.category.value,
    },
  )

  return (
    <Fragment>
      <div className="mt-6">
        <TextInput
          required
          label="Goal Name"
          placeholder=""
          value={formValues.name}
          onChange={(e) => updateFormValue('name', e.target.value)}
          classes="w-full"
        />
        <TextArea
          label="Description"
          placeholder="This is where you can put important details about your goal."
          value={formValues.description || ''}
          onChange={(e) => updateFormValue('description', e.target.value)}
        />
        <div className="flex-1 sm:mr-3">
          <FormSelect
            disabled={!formValues.goalCategory?.value}
            options={
              !!options?.categoryOptions &&
              options.categoryOptions
                ?.find(
                  (o) =>
                    o.goal_category.id ===
                    (formValues.category.value || formValues.category.id),
                )
                ?.goal_category.goal_category_directions.map((direction) => ({
                  value: direction.id,
                  label: direction.name,
                }))
            }
            label="My goal is to:"
            onChange={(direction) => {
              updateFormValue('direction', direction)
            }}
            value={formValues.direction}
          />
          <div className="flex-1">
            <label className="text-sm text-navy-4">By how much?</label>
            <div className="flex flex-1 items-center">
              <FormSelect
                disabled
                placeholder="Select a measure"
                options={
                  !!options?.categoryOptions &&
                  options.categoryOptions
                    ?.find(
                      (o) =>
                        o.goal_category.id ===
                        (formValues.category.value || formValues.category.id),
                    )
                    ?.goal_category.goal_category_directions.find(
                      (dir) =>  dir.direction.id === formValues.direction.value,
                    )
                    ?.value_types.map((valueType) => ({
                      value: valueType.value_type.id,
                      label: valueType.value_type.name,
                    }))
                }
                onChange={(valueType) => {
                  updateFormValue('valueType', valueType)
                }}
                value={formValues.valueType}
                classes="mr-3 w-24"
              />
              <TextInput
                required
                label=""
                placeholder=""
                value={formValues.goal_value}
                onChange={(e) => updateFormValue('goal_value', e.target.value)}
                className="flex-1"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-6">
          <div className="flex-1 mb-4 sm:mb-0 sm:mr-2">
            <FormDatePicker
              value={formValues.start_date}
              onChange={(val) => updateFormValue('start_date', val)}
              label="Start Date"
              disabled
            />
          </div>
          <div className="flex-1 mb-4 sm:mb-0 sm:ml-2">
            <FormDatePicker
              value={new Date(formValues.end_date)}
              onChange={(val) => {
                updateFormValue('end_date', val)
                setShowEndDateWarning(true)
              }}
              label="End Date"
            />
          </div>
        </div>
        {showEndDateChangeWarning && (
          <p className="text-red-4 mb-4 sm:-mt-4 text-sm">
            WARNING: Changing the end date of your goal will only impact goal
            statuses going forward. Past goal statuses will <em>not</em> be
            recalculated.
          </p>
        )}
        <FormSelect
          disabled
          options={
            !!options &&
            options.categoryOptions.map((cat) => ({
              value: cat.id,
              label: cat.name,
            }))
          }
          label="What do you want to set a goal for?"
          onChange={(category) => {
            updateFormValue('goalCategory', category)
          }}
          value={formValues.category}
        />
      </div>
      <div className="mt-6">
        <label className="text-sm">
          How would you like to filter the source data?
        </label>
        <div className="flex justify-start pt-3">
          {!formValues.filters?.length && (
            <p className="text-dark-50 text-xs">No filters applied.</p>
          )}
          {formValues.filters?.map((filter) => {
            const filterData = filters?.find(
              (f) => f.filter.id === filter.id,
            ).filter
            const filterValue = formValues.filters.find(
              (f) => f.pivot.filter_option_id == filter.id,
            ).pivot.value
            return (
              <div
                className="bg-dark-15 rounded-full text-navy-4 py-1 px-4 text-sm mx-2"
                key={filter.id}>
                <span className="opacity-50">
                  {filterData?.name}: {filterValue}
                </span>
              </div>
            )
          })}
        </div>
      </div>
      { /* <div className="mt-6">
        <h2 className="font-bold text-sm">
          How would you like to set up benchmarks? (Select all that apply)
        </h2>
        <div className="flex">
          {!!options &&
            options.benchmarkOptions.map((bOption) => (
              <div className="mr-3 mt-1" key={bOption.id}>
                <FormCheckbox
                  checked={formValues.benchmarks
                    .map((benchmark) => benchmark.value)
                    .includes(bOption.id)}
                  label={bOption.name}
                  onChange={() => {
                    const hasMatch = formValues.benchmarks
                      .map((gb) => gb.value)
                      .includes(bOption.id)
                    const newVal = hasMatch
                      ? formValues.benchmarks.filter(
                          (gb) => gb.value != bOption.id,
                        )
                      : formValues.benchmarks.concat([
                          {
                            value: bOption.id,
                            label: bOption.name,
                          },
                        ])
                    updateFormValue('benchmarks', newVal)
                  }}
                />
              </div>
            ))}
        </div>
      </div> */}
      {/* <div className="flex flex-col sm:flex-row mt-6 sm:items-center">
        <div className="flex-1 sm:pl-6 text-xs -mt-3 sm:mt-0 font-light">
          To reach your goal, we recommend you set weekly goal of{' '}
          <span className="font-bold">X</span> to reach your end goal of{' '}
          <span className="font-bold">X</span>.
        </div>
      </div> */}
    </Fragment>
  )
}

export default EditGoalForm
