import { useContext, Fragment, useState } from 'react'
import { useQuery } from 'react-query'

import { GlobalContext } from '../context/GlobalContext'
import {
  FormSelect,
  FormCheckbox,
  FormDatePicker,
  TextInput,
  TextArea,
  Avatar,
  FileInput,
  Button,
  Icon,
} from '.'
import api from '../services/api'
import CloseIcon from '../public/close_circle.svg'

const EditProjectForm = ({ formValues, updateFormValue }) => {
  const globalContext = useContext(GlobalContext)

  const {
    isLoading,
    error,
    data: metaOptions,
  } = useQuery('project_options', () => api.getProjectMetaOptions())

  return (
    <Fragment>
      <div className="mt-6">
        <TextInput
          required
          label="Project Name"
          placeholder=""
          value={formValues.name}
          onChange={(e) => updateFormValue({ name: e.target.value })}
          classes="w-full"
        />
        <TextArea
          label="Description"
          placeholder="This is where you can put important details about your goal."
          value={formValues.description || ''}
          onChange={(e) => updateFormValue({ description: e.target.value })}
        />
        <div className="flex flex-col sm:flex-row">
          <div className="flex-1 mb-4 sm:mb-0 sm:mr-2">
            <FormDatePicker
              value={new Date(formValues.start_date)}
              onChange={(val) => updateFormValue({ start_date: val })}
              label="Start Date"
            />
          </div>
          <div className="flex-1 mb-4 sm:mb-0 sm:ml-2">
            <FormDatePicker
              value={new Date(formValues.end_date)}
              onChange={(val) => updateFormValue({ end_date: val })}
              label="End Date"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row my-10">
          <Avatar image={formValues ? formValues.image : null} type="team" />
          <div className="flex-1 flex-col flex justify-center">
            <div className="flex flex-col sm:flex-row items-center">
              <FileInput
                onUpdate={(imageString) => {
                  updateFormValue({ image: imageString })
                }}
              />
              {formValues?.image && (
                <Button
                  action={() => updateFormValue({ image: '' })}
                  text="REMOVE"
                  size="small"
                  theme="red-transparent"
                  IconLeft={() => (
                    <Icon
                      icon={(props) => <CloseIcon {...props} />}
                      size="small"
                      fill="#de4d15"
                    />
                  )}
                />
              )}
            </div>
            <div className="text-dark-50 mt-4">
              Upload a photo for your profile (JPG, JPEG, PNG only; 1 MB Maximum File Size).
            </div>
          </div>
        </div>
        <section className="mb-10">
          <h3 className="mb-3 font-bold text-sm">
            How would you describe this project?
          </h3>
          <div className="flex flex-wrap">
            {metaOptions &&
              metaOptions.goals.map((option) => (
                <FormCheckbox
                  checked={formValues.project_goals.find(
                    (g) => g.slug == option.slug,
                  )}
                  label={option.name}
                  key={option.name}
                  classes="w-1/2 px-2"
                  onChange={() => {
                    updateFormValue({
                      project_goals: formValues.project_goals.find(
                        (g) => g.slug == option.slug,
                      )
                        ? formValues.project_goals.filter(
                            (o) => o.slug != option.slug,
                          )
                        : formValues.project_goals.concat([option]),
                    })
                  }}
                />
              ))}
          </div>
          <div className="mt-5">
            <h3 className="mb-3 font-bold text-sm">
              What are your goals for the project?
            </h3>
            <div className="flex flex-wrap">
              {metaOptions?.categories.map((category) => (
                <FormCheckbox
                  key={category.slug}
                  checked={formValues.categories.find(
                    (c) => c.slug == category.slug,
                  )}
                  label={category.name}
                  classes="w-1/2 px-2"
                  onChange={() => {
                    updateFormValue({
                      categories: formValues.categories.find(
                        (c) => c.slug == category.slug,
                      )
                        ? formValues.categories.filter(
                            (c) => c.slug != category.slug,
                          )
                        : formValues.categories.concat([category]),
                    })
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}

export default EditProjectForm
