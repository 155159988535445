import Modal from 'react-modal'

const ModalComponent = ({ children, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: 'rgba(41, 50, 65, 0.5)',
        },
        content: {
          zIndex: 9999,
          background: 'transparent',
          border: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          inset: '1px',
          maxWidth: '100%',
          padding: 0,
        },
      }}>
      {children}
    </Modal>
  )
}

export default ModalComponent
