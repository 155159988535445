import { Fragment } from 'react'

const getSizeClasses = (size, isWorkspaceAvatar = false) => {
  switch (size) {
    case 'small':
      return 'w-12 h-12'
    default:
      return isWorkspaceAvatar ? 'w-24 h-24' : 'w-20 h-20'
  }
}

const Avatar = ({ type = 'user', image, size, classes }) => {
  return (
    <Fragment>
      {type === 'user' && (
        <Fragment>
          {image ? (
            <img
              className={`mx-2 ${getSizeClasses(
                size,
              )} rounded-full object-cover object-center ${classes}  border border-dark-15`}
              src={image}
            />
          ) : (
            <img
              src="/avatar_placeholder.svg"
              className={`mx-2 w-11 ${classes}`}
            />
          )}
        </Fragment>
      )}
      {type === 'team' && (
        <Fragment>
          {image ? (
            <img
              className={`mr-4 rounded-full ${getSizeClasses(
                size,
                true,
              )} object-cover object-center max-w-24 max-h-24 ${classes} border border-dark-15`}
              src={image}
            />
          ) : (
            <div
              className={`${getSizeClasses(
                size,
                true,
              )} flex items-center justify-center mr-4  bg-dark-5 rounded-full ${classes}`}>
              <img
                src="/settings_avatar_default.png"
                className={`${
                  size === 'small' ? 'w-6 h-6' : 'w-12 h-12'
                } object-cover object-center`}
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Avatar
