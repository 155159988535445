import { useContext, useEffect, Fragment } from 'react'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'

import { GlobalContext } from '../context/GlobalContext'

const WorkspaceSubscriptionGuard = ({ children, bypassLimitCheck = false }) => {
  const globalContext = useContext(GlobalContext)
  const router = useRouter()

  useEffect(() => {
    if (
      globalContext.activeWorkspace &&
      !globalContext.activeWorkspace.team_subscription
    ) {
      router.push(
        `/workspaces/${
          globalContext.activeWorkspace.id
        }/choose-plan?redirected=true&back=${encodeURIComponent(
          router.asPath,
        )}`,
      )
    } else if (
      globalContext.activeWorkspace &&
      (!!globalContext.activeWorkspace.team_subscription.payment_failed_count ||
        !!globalContext.activeWorkspace.team_subscription
          .delete_for_nonpayment_on)
    ) {
      router.push(`/workspaces/${globalContext.activeWorkspace.id}/settings`)
    } else if (globalContext.activeWorkspace?.is_over_subscription_limits) {
      // they have changed their subscription and are no
      // longer compliant with their new subscription's limits
      router.push(
        `/workspaces/${globalContext.activeWorkspace.id}/update-subscription-limits`,
      )
    }
  }, [globalContext.activeWorkspace])

  return globalContext.activeWorkspace?.team_subscription ? (
    <Fragment>{children}</Fragment>
  ) : (
    <div className="w-full h-full flex justify-center items-center px-6 py-20">
      <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
    </div>
  )
}

export default WorkspaceSubscriptionGuard
