const ProgressBar = ({ percentComplete, fillClass = 'bg-teal-4' }) => {
  return (
    <div className="flex-1 bg-dark-15 rounded-full overflow-hidden">
      <div
        className={`${fillClass} rounded-full h-3`}
        style={{ width: `${percentComplete > 100 ? 100 : percentComplete}%` }}
      />
    </div>
  )
}

export default ProgressBar
