import { Fragment, useContext } from 'react'
import { useRouter } from 'next/router'

import { ProgressBar, TagPill } from '.'
import PencilIcon from '../public/pencil.svg'
import TrashIcon from '../public/trash.svg'
import Icon from './Icon'
import { theme } from '../tailwind.config'
import ContextualMenu from './ContextualMenu'
import { GlobalContext } from '../context/GlobalContext'
import { getCategoryPillInfo } from '../utils'
import TargetIcon from '../public/target.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive } from '@fortawesome/free-solid-svg-icons'

const DetailHeader = ({
  data,
  isProject = false,
  onDelete,
  onEdit,
  disableMenu = false,
  onArchive,
}) => {
  const router = useRouter()
  const globalContext = useContext(GlobalContext)
  const getMenuItems = ({ isProject, onEdit, onDelete }) => {
    const menuItems = [
      {
        text: `Edit ${isProject ? 'Project' : 'Goal'}`,
        action: onEdit,
        Icon: () => <Icon icon={PencilIcon} fill={theme.colors.navy[5]} />,
      },
      {
        text: `Delete ${isProject ? 'Project' : 'Goal'}`,
        action: onDelete,
        Icon: () => <Icon icon={TrashIcon} fill={theme.colors.red[2]} />,
        textClass: 'text-red-3',
      },
    ]

    if (onArchive) {
      menuItems.unshift({
        text: `${data.is_archived ? 'Unarchive' : 'Archive'} ${
          isProject ? 'Project' : 'Goal'
        }`,
        action: onArchive,
        Icon: () => (
          <FontAwesomeIcon icon={faArchive} className="w-3.5 text-navy-4" />
        ),
        textClass: 'text-navy-4',
      })
    }

    if (isProject) {
      menuItems.unshift({
        text: `Add a Goal`,
        action: () =>
          router.push(
            `/workspaces/${globalContext.activeWorkspace.id}/goals/new?projectId=${router.query.projectId}`,
          ),
        Icon: () => <Icon icon={TargetIcon} fill={theme.colors.navy[5]} />,
        textClass: 'text-navy-4',
      })
    }

    return menuItems
  }

  return !data ? null : (
    <div className="bg-white rounded-xl p-4 sm:pr-10 relative hidden-pdf">
      {!disableMenu && (
        <ContextualMenu
          classes="top-3 right-0"
          items={getMenuItems({
            isProject,
            onEdit,
            onDelete,
          })}
        />
      )}
      <div className="flex flex-row sm:items-center">
        {!!data.image ? (
          <img
            className="w-24 h-24 rounded-full mr-4  object-cover"
            src={data.image}
          />
        ) : (
          <Fragment>
            {isProject ? (
              <div className="flex items-center justify-center w-24 h-24 bg-dark-5 rounded-lg mr-4">
                <img
                  className="w-16 h-16 object-contain"
                  src={data.image ? data.image : '/settings_avatar_default.png'}
                />
              </div>
            ) : (
              <div className="flex sm:items-center justify-start rounded-lg mr-4">
                <img
                  className="w-16 h-16 object-contain rounded-lg"
                  src={
                    data.data_source?.image
                      ? data.data_source?.image
                      : '/settings_avatar_default.png'
                  }
                />
              </div>
            )}
          </Fragment>
        )}
        <div className="flex flex-col sm:flex-row flex-1">
          <div className="flex-1 sm:pr-4">
            <h1 className="font-serif text-2xl">{data.name}</h1>
            {!!data.description && (
              <p className="font-light mt-3 text-sm">{data.description}</p>
            )}
            {!!data.is_archived && (
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faArchive}
                  className="w-3.5 text-dark-50"
                />
                <span className="text-sm uppercase tracking-wide text-dark-50 ml-2">
                  ARCHIVED
                </span>
              </div>
            )}
          </div>
          {data.progress !== null && (
            <div className="flex-1 mt-4 sm:mt-0">
              <div className="flex justify-between items-center mb-1">
                <h2 className="text-dark-50 uppercase mb-2 text-xxs">
                  Progress
                </h2>
                <div className="text-navy-4 font-bold">
                  {data.progress}%
                </div>
              </div>
              <ProgressBar
                percentComplete={
                  data.progress 
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center border-t sm:ml-20 border-dark-15 mt-4 sm:mt-3">
        <div className="flex-1 flex w-full flex-wrap pt-5 pb-1 items-center">
          <TagPill>
            <span className="text-dark-50 mr-1 text-xxs mt-0.5 font-medium">
              START:
            </span>
            <span className="text-xxs mt-0.5 font-medium">
              {data.start_date}
            </span>
          </TagPill>
          <TagPill classes="ml-2 bg-dark-15">
            <span className="text-dark-50 mr-1 text-xxs mt-0.5 font-medium">
              END:
            </span>
            <span className="text-xxs mt-0.5 font-medium">{data.end_date}</span>
          </TagPill>
        </div>
        <div className="flex-1 flex w-full flex-wrap pt-5 pb-1 items-center sm:justify-end">
          {!!data.categories &&
            data.categories.map((category) => {
              const categoryInfo = getCategoryPillInfo('project', category)
              return (
                <TagPill
                  classes={categoryInfo.backgroundClass}
                  key={category.id}>
                  <Icon
                    icon={categoryInfo.icon}
                    fill={theme.colors.navy[5]}
                    classes="mr-2"
                  />
                  <span className="uppercase">{category.name}</span>
                </TagPill>
              )
            })}
          {data.category && (
            <TagPill
              classes={
                getCategoryPillInfo('goal', data.category).backgroundClass
              }>
              <Icon
                icon={getCategoryPillInfo('goal', data.category).icon}
                fill={theme.colors.navy[5]}
                classes="mr-2"
              />
              <span className="uppercase">{data.category.name}</span>
            </TagPill>
          )}
        </div>
      </div>
    </div>
  )
}

export default DetailHeader
