import { useState, Fragment, useEffect } from 'react'
import { DateRangePicker } from 'react-date-range'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import { theme } from '../tailwind.config'
import { Modal, CardContainer, CardBody, CardHeader, Icon, Button } from '.'
import CloseIcon from '../public/close_circle.svg'
import ArrowRightIcon from '../public/arrow_right.svg'
import { tzDate } from '../utils'

const DRPicker = ({ startDate, endDate, updateFilters, classes = '' }) => {
  const [localStartDate, setLocalStartDate] = useState(startDate)
  const [localEndDate, setLocalEndDate] = useState(endDate)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      const beforeAfterQuickSelects = document.querySelectorAll(
        '.rdrInputRange span',
      )
      Array.from(beforeAfterQuickSelects).forEach((quickSelect) => {
        if (quickSelect.outerText == 'days up to today') {
          quickSelect.outerText = 'days prior to today'
        } else if (quickSelect.outerText == 'days starting today') {
          quickSelect.outerText = 'days after today'
        }
      })
    }, 100)
  }, [isModalOpen])

  const saveSelection = () => {
    updateFilters({
      start_date: localStartDate,
      end_date: localEndDate,
    })
    setIsModalOpen(false)
  }

  return (
    <Fragment>
      <button
        onClick={() => setIsModalOpen(!isModalOpen)}
        className={`flex items-center ${classes}`}>
        <FontAwesomeIcon icon={faCalendar} className="h-3 w-3 text-dark-50" />
        <span className="ml-2" style={{ color: 'rgba(51,51,51)' }}>
          {format(new tzDate(startDate), 'MMM d, y')} -{' '}
          {format(new tzDate(endDate), 'MMM d, y')}
        </span>
      </button>
      <Modal isOpen={isModalOpen}>
        <CardContainer
          classes="relative max-w-full max-h-full overflow-auto mx-5"
          hasHeader>
          <CardHeader
            title="Choose a Date Range"
            ButtonComponent={() => (
              <button onClick={() => setIsModalOpen(false)}>
                <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
              </button>
            )}
          />
          <CardBody disablePadding>
            <DateRangePicker
              onChange={(item) => {
                setLocalStartDate(
                  format(item.selection.startDate, 'yyyy-MM-dd'),
                )
                setLocalEndDate(format(item.selection.endDate, 'yyyy-MM-dd'))
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              color={theme.colors.teal[4]}
              rangeColors={[theme.colors.teal[4]]}
              ranges={[
                {
                  startDate: new tzDate(localStartDate),
                  endDate:  new tzDate(localEndDate),
                  key: 'selection',
                },
              ]}
              direction="horizontal"
            />
            <div className="flex p-4 justify-end border-t border-dark-15">
              <Button
                size="small"
                theme="yellow"
                IconRight={() => <Icon icon={ArrowRightIcon} />}
                action={saveSelection}
                text="Save"
              />
            </div>
          </CardBody>
        </CardContainer>
      </Modal>
    </Fragment>
  )
}

export default DRPicker
