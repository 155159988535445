import Loader from 'react-loader-spinner'

const AppLoading = () => {
  return (
    <div className="h-screen w-screen bg-white flex justify-center items-center">
      <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
    </div>
  )
}

export default AppLoading
