const Carousel = ({ content, activeIndex, classes }) => {
  return (
    <section
      className={`transition-all duration-1000 relative overflow-hidden ${classes}`}>
      {content.map((item, index) => (
        <div
          key={`dash-carousel-${index}`}
          className={`transition-opacity duration-500 ${
            activeIndex === index
              ? 'opacity-1 relative'
              : 'opacity-0 absolute left-full'
          }`}>
          <h2 className="text-center mb-3">{item.title}</h2>
          <p className="text-center">{item.description}</p>
        </div>
      ))}
    </section>
  )
}

export default Carousel
