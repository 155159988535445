import { useEffect, useState, Fragment } from 'react'
import Link from 'next/link'
import subDays from 'date-fns/subDays'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

import api from '../services/api'
import { getStatusTextClass, tzDate } from '../utils'
import { LineChart, CardContainer, FormSelect, DateRangePicker } from '.'

const CategoriesReport = () => {
  const router = useRouter()
  const [projectSort, setProjectSort] = useState('title-asc')
  const [allCategories, setAllCategories] = useState([])
  const [filters, setFilters] = useState({
    end_date: router.query?.end || format(new Date(), 'yyyy-MM-dd'),
    start_date:
      router.query?.start || format(subDays(new Date(), 6), 'yyyy-MM-dd'),
    categories: router.query?.categories || [],
  })

  const { isLoading, data: categories } = useQuery(
    ['categories-report', { ...filters }],
    () =>
      api.getCategoryReportData({
        workspaceId: router.query.workspaceId,
        ...filters,
      }),
    {
      enabled: !!router.query.workspaceId,
      onSuccess: (response) => {
        const categories = []
        response.forEach((item) => {
          if (!categories.find((c) => c.id === item.category.id)) {
            categories.push(item.category)
          }
        })
        setAllCategories(categories)
      },
    },
  )

  const updateUrl = (newFilters) => {
    router.replace(
      `/workspaces/${router.query.workspaceId}/reports?type=categories&start=${newFilters.start_date}&end=${newFilters.end_date}&categories=${newFilters.categories}`,
    )
  }

  const updateFilters = (updatedFilters) => {
    const newFilters = {
      ...filters,
      ...updatedFilters,
    }
    updateUrl(newFilters)
    setFilters(newFilters)
  }

  return (
    <div>
      <div>
        {isLoading || !categories ? (
          <div className="">
            <div className="px-3 py-56 w-full flex justify-center items-center">
              <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="flex px-3">
              <FormSelect
                options={[{ label: 'All categories', value: '' }].concat(
                  allCategories.map((t) => ({
                    label: t.name,
                    value: t.id,
                  })),
                )}
                classes="w-full md:w-auto min-w-1/4 max-w-full mt-3 md:mt-0 md:mr-3"
                label="Categories"
                onChange={(option) => {
                  const isClear = !option || option.find((o) => o.value == '')
                  setFilters({
                    ...filters,
                    categories:
                      option && !isClear ? option.map((o) => o.value) : [],
                  })
                }}
                placeholder="Filter by category"
                isMulti
                value={allCategories
                  .filter((p) => filters.categories.includes(p.id))
                  .map((t) => ({
                    label: t.name,
                    value: t.id,
                  }))}
              />
              <div className="w-50 max-w-full mb-3">
                <label className="text-sm">Dates</label>
                <div
                  className="mt-1 border bg-white flex flex-col justify-center items-start"
                  style={{
                    borderRadius: 4,
                    height: 42,
                    borderColor: 'rgba(41, 50, 65, 0.15)',
                    marginTop: 5,
                  }}>
                  <DateRangePicker
                    startDate={filters.start_date}
                    endDate={filters.end_date}
                    updateFilters={updateFilters}
                    classes="text-sm flex-1 px-3"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row flex-wrap sm:-ml-3 w-full">
              {categories
                .filter((category) => {
                  // TEMPORARY ? don't include CSVs since they don't really have filters or categories (yet)
                  if (category.category.data_source[0]?.name === 'CSV') {
                    return
                  }
                  if (filters.categories.length === 0) {
                    return true
                  }
                  return filters.categories.includes(category.category.id)
                })
                .map((category, index) => {
                  return (
                    <Link
                      key={`${category.category.id}-${index}`}
                      href={`/workspaces/${
                        router.query.workspaceId
                      }/categories/${category.category.id}${
                        category.goal_ga_view
                          ? `?goal_ga_view=${category.goal_ga_view.id}`
                          : ''
                      }`}>
                      <a className="flex w-full sm:w-1/2 md:w-1/3 pl-6 pb-6">
                        <CardContainer hasHeader classes="flex flex-col w-full">
                          <div className="flex items-center p-6">
                            <div className="flex items-center flex-1">
                              {category.data_source?.name === 'CSV' ? (
                                <FontAwesomeIcon
                                  icon={faFileCsv}
                                  className="h-10 mr-4 text-teal-4"
                                />
                              ) : (
                                <img
                                  className="w-10 h-10 rounded-lg mr-4  object-contain"
                                  src={category.category?.data_source[0].image}
                                />
                              )}
                              <div>
                                <div className="font-bold text-md">
                                  {category.category.name}
                                </div>
                                {category.goal_ga_view?.property_name && (
                                  <div className="text-dark-50 uppercase text-xs font-bold">
                                    {`${category.goal_ga_view.property_name}${
                                      category.goal_ga_view?.view_name
                                        ? ` - ${category.goal_ga_view?.view_name}`
                                        : ''
                                    }`}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <span className="text-dark-50 text-xs"></span>
                            </div>
                          </div>
                          <div className="flex justify-end  items-center px-6 text-sm">
                          <span className="text-xxs text-dark-50 uppercase mr-1">
                          {category.category.average ? 'Rolling Average' : 'Current Value'}
                          </span>

                          <span className="font-bold text-smfont-navy-5">
                          {category.category.end_display|| '-'}
                          </span>
                          </div>
                          <div className="flex items-center flex-col justify-center flex-1 p-5 text-center">
                            {category.category.daily_data?.length > 0 ? (
                              <LineChart
                                disableAxes
                                disableLabel
                                lines={category.category.daily_data.map((d) => ({
                                  x: format(new tzDate(d.date), 'MMM d'),
                                  y: parseFloat(d.cumulative_data, 10),
                                }))}
                                isCardChart
                              />
                            ) : (
                              <span className="font-serif text-sm py-12">
                                {category.data_collection_started
                                  ? 'No data for this period.'
                                  : 'Data updates occur at midnight, please return tomorrow for your first report on data performance.'}
                              </span>
                            )}
                          </div>
                          <div className="border-t border-dark-15 px-6 py-3 flex">
                            {!!category.projects && (
                              <span className="text-xs flex items-center text-dark-50 mr-3">
                                <img
                                  src="/target.svg"
                                  className="h-4 opacity-50 mr-2"
                                />
                                {`${category.projects} ${
                                  category.projects > 1 ? 'PROJECTS' : 'PROJECT'
                                }`}
                              </span>
                            )}
                            {!!category.goals && (
                              <span className="text-xs flex items-center text-dark-50">
                                <img
                                  src="/target.svg"
                                  className="h-4 opacity-50 mr-2"
                                />
                                {`${category.goals} ${
                                  category.goals > 1 ? 'GOALS' : 'GOAL'
                                }`}
                              </span>
                            )}
                          </div>
                        </CardContainer>
                      </a>
                    </Link>
                  )
                })}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default CategoriesReport
