import { useContext, Fragment } from 'react'

import { Button } from '../components'
import { GlobalContext } from '../context/GlobalContext'

const OnboardHeader = ({ isLoginScreen }) => {
  const globalContext = useContext(GlobalContext)

  return (
    <header className="md:absolute top-0 left-0 w-full p-4 z-40 flex justify-between items-center">
      <img src="/sunsett-logo-onboard.png" className="w-10 md:w-16 md:ml-5" />

      <div className="flex items-center justify-start md:self-start">
        {!globalContext.user?.id ? (
          <Fragment>
            <div className="text-dark-50 mr-4 text-sm">
              {isLoginScreen ? 'Need to register?' : 'Already have an account?'}
            </div>
            <Button
              text={isLoginScreen ? 'Sign Up' : 'Sign In'}
              size="small"
              href={isLoginScreen ? '/onboard' : '/'}
              theme="yellow"
              IconRight={() => <img src="/arrow_right.svg" />}
            />
          </Fragment>
        ) : (
          <Button text="Logout" size="small" action={globalContext.logout} />
        )}
      </div>
    </header>
  )
}

export default OnboardHeader
