import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import { differenceInWeeks, differenceInDays } from 'date-fns'
const { DateTime } = require("luxon");

export const truncateString = (string, length = 200) => {
  return string.length > length ? `${string.substring(0, length)}...` : string
}

export const getRandomId = (length = 15) => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const getNotificationDateText = (date) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const notificationDate = new Date(date)
  if (isBefore(notificationDate, today)) {
    return format(notificationDate, 'h:mmb')
  } else {
    return format(notificationDate, 'MMMM d, yyyy')
  }
  return date
}

export const getNotificationLink = (notification) => {
  switch (notification.type) {
    case 'reconnect':
      return `/workspaces/${notification.team_id}/data`
    case 'account_welcome':
    case 'goal_met':
    case 'goal_almost_met':
      return `/workspaces/${notification.team_id}/goals/${notification.content_id}`
    case 'project_completed':
      return `/workspaces/${notification.team_id}/projects/${notification.content_id}`
    case 'invitation_accepted':
      return `/workspaces/${notification.team_id}/dashboard`
    case 'payment_failure':
    case 'workspace_canceled':
    case 'invitation_still_pending':
      return `/workspaces/${notification.team_id}/settings`
    case 'invitation':
      return `/invitation/${notification.content_id}/respond`
    default:
      return `/invitation/${notification.content_id}/respond`
  }
}

export const getRelativeWeeksText = (date) => {
  const diff = differenceInWeeks(new Date(date), new Date())
  const diffInDays = differenceInDays(new Date(date), new Date())

  if (diffInDays < 0) {
    return 'ENDED'
  } else if (diff === 0) {
    return '< 1 WEEK LEFT'
  } else if (diff === 1) {
    return '1 WEEK LEFT'
  } else {
    return `${diff} WEEKS LEFT`
  }
}

export const isWorkspaceAdmin = (workspaceUser) => {
  return workspaceUser?.role?.name === 'ADMIN'
}

export const statusColors = {
  ontrack: '#ffcd38',
  behind: '#db9429',
  ahead: '#ffe085',
}

export const statusLabels = {
  ontrack: 'On Track',
  behind: 'Behind',
  ahead: 'Ahead',
}

export const getYAxisValusFromMax = (max) => {
  let values =  Array.from(Array(max+1).keys());
  if (values.length < 8) {
    return values
  }
  // find next number divisible by 4
  max = 4 * Math.ceil(max / 4)
  const increment = max / 4
  return [0, increment, increment * 2, increment * 3, max];
}

export const tzDate = (date) => {
  return DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: "utc" }).setZone("local", { keepLocalTime: true }).toJSDate();
}

export const dateAxisFormat = (tick, data) => {
    if (data.length < 7) {
      return tick;
    }

    const increment = Math.ceil(data.length / 8)
    let index = null
    data.forEach((d, i) => {
      if (d.x == tick) {
        index = i
      }
    })
    return index != null && index % increment === 0 ?  tick : ''
}

export const getCategoryPillInfo = (type = 'project', category) => {
  if (type === 'project') {
    switch (category.slug) {
      case 'understand-audiences':
        return {
          backgroundClass: 'bg-brown-1',
          icon: () => <img src="/talk-bubbles.svg" className="h-4" />,
        }
      case 'identify-opportunities':
        return {
          backgroundClass: 'bg-orange-1',
          icon: () => <img src="/lightning-bolt.svg" className="h-4" />,
        }
      case 'manage-time':
        return {
          backgroundClass: 'bg-teal-1',
          icon: () => <img src="/clock.svg" className="h-4" />,
        }
      case 'secure-funding':
        return {
          backgroundClass: 'bg-yellow-1',
          icon: () => <img src="/dollar_sign.svg" className="h-4" />,
        }
      case 'detect-gaps':
        return {
          backgroundClass: 'bg-green-1',
          icon: () => <img src="/bar-graph-icon.svg" className="h-4" />,
        }
      case 'visualize-progress':
        return {
          backgroundClass: 'bg-navy-1',
          icon: () => <img src="/line-graph.svg" className="h-4" />,
        }
      case 'generate-leads':
        return {
          backgroundClass: 'bg-red-1',
          icon: () => <img src="/people-icon.svg" className="h-4" />,
        }
      case 'increase-followers':
      default:
        return {
          backgroundClass: 'bg-dark-15',
          icon: () => <img src="/people-icon.svg" className="h-4" />,
        }
    }
  } else {
    switch (category.name) {
      case 'Ticket Sales ($)':
      case 'Ticket Sales (count)':
        return {
          backgroundClass: 'bg-orange-1',
          icon: () => <img src="/dollar_sign.svg" className="h-4" />,
        }
      case 'Product Sales ($)':
      case 'Product Sales (count)':
        return {
          backgroundClass: 'bg-teal-1',
          icon: () => <img src="/dollar_sign.svg" className="h-4" />,
        }
      case 'Customers':
        return {
          backgroundClass: 'bg-brown-1',
          icon: () => <img src="/people-icon.svg" className="h-4" />,
        }
      case 'Product Shipments':
        return {
          backgroundClass: 'bg-yellow-1',
          icon: () => <img src="/distribution-icon.svg" className="h-4" />,
        }
      case 'Average Time On Site':
        return {
          backgroundClass: 'bg-green-1',
          icon: () => <img src="/clock.svg" className="h-4" />,
        }
      case 'Bounce/Engagement Rate':
        return {
          backgroundClass: 'bg-navy-1',
          icon: () => <img src="/talk-bubbles.svg" className="h-4" />,
        }
      case 'Pages Per Visit':
        return {
          backgroundClass: 'bg-red-1',
          icon: () => <img src="/line-graph.svg" className="h-4" />,
        }
      case 'Sessions':
        return {
          backgroundClass: 'bg-teal-2',
          icon: () => <img src="/line-graph.svg" className="h-4" />,
        }
      case 'Data Point':
      default:
        return {
          backgroundClass: 'bg-dark-15',
          icon: () => <img src="/line-graph.svg" className="h-4" />,
        }
    }
  }
}

export const getStatusTextClass = (diff) => {
  if (!diff) {
    return 'text-navy-4'
  }
  if (diff == 0) {
    return 'text-navy-4'
  } else {
    return diff > 0 ? 'text-green-4' : 'text-red-4'
  }
}