import { GoalCard } from '.'

const getBorderClass = (index, total) => {
  switch (index) {
    case 0:
      return `sm:border-r ${total > 2 ? 'border-b' : ''}`
    case 1:
      return total > 2 ? 'border-b' : ''
    case 2:
      return 'sm:border-r border-b sm:border-b-0'
    case 3:
      ''
  }
}

const GoalsDashboard = ({ goals }) => {
  return (
    <div className="flex flex-1 flex-wrap">
      {goals.map((goal, index) => (
        <div className="sm:w-1/2 w-full flex" key={goal.id}>
          <GoalCard
            goal={goal}
            classes={`bg-white w-full border-dark-15 hover:bg-dark-5 transition-all duration-500 cursor-pointer ${getBorderClass(
              index,
              goals.length,
            )}`}
          />
        </div>
      ))}
    </div>
  )
}

export default GoalsDashboard
