import React, {
    createContext,
    useMemo,
    useState
  } from 'react'
  import html2canvas from 'html2canvas';
  import { jsPDF } from 'jspdf';
  import { ReportPrintHeader } from '../components'
  import api from '../services/api'
  import { useRouter } from 'next/router'
  import { format } from 'date-fns'
  import { tzDate } from '../utils'

  export const ReportContext = createContext({})
  
  const ReportProvider = (props) => {
    const { children } = props
    const [title, setTitle] = useState()
    const [data, setData] = useState()
    const [filters, setFilters] = useState()
    const router = useRouter()

    const saveReport = (filters, name, type, goalId) => {
      return api.createSavedReport(
        router.query.workspaceId,
        name, 
        type,
        filters.start_date,
        filters.end_date,
        filters.projects ? filters.projects : [],
        filters.projectCategory ? [filters.projectCategory] : [],
        filters.goals ? filters.goals : (goalId ? [goalId] : []),
        filters.goalCategory ? [filters.goalCategory] : []);
    }
    const exportPdf = (pageCount, title, data, filters) => {
        setTitle(title);
        setData(data);
        setFilters(filters);
      const height = 974;
      const width = 1237;
      setTimeout(()=>{
        let promises = [];
        let scrollY = 0;
        for (let i = 0; i < pageCount; i++) {
          promises.push(html2canvas(document.querySelector('body'), {
            windowWidth: width,
            windowHeight: height,
            width: width,
            height: height,
            scrollY: scrollY,
            scale: 2,
            ignoreElements: (element)=>{
              if( element.classList.contains('hidden-pdf') ) {
                return true;
              }
            },
            onclone: (document)=>{
              document.querySelector('.bg-pdf-white').style.backgroundColor = '#fff';
              document.querySelector('.flex-pdf').style.display = 'flex';
              document.querySelector('.block-pdf').style.display = 'block';
              const page2 = document.querySelector('.page-2-pdf')
              if (page2){
                page2.style.marginTop = '450px';
                page2.style.width= '1018px';
              }

              const victoryPieContainer = document.querySelector('.sunsett-pie-chart .VictoryContainer');
              if (victoryPieContainer){
                victoryPieContainer.style.marginLeft = '-145px !important';
                victoryPieContainer.style.marginTop = '-40px !important';
                victoryPieContainer.style.marginBottom = '-60px !important';
                document.querySelector('.sunsett-pie-chart .VictoryContainer > svg').style = '';
              }
              const lineChart = document.querySelector('.sunsett-line-chart .VictoryContainer > svg');
              if (lineChart){
                lineChart.style.width = '660px';
                lineChart.style.height = 'auto';
              }
              const barChart = document.querySelector('.sunsett-bar-chart .VictoryContainer > svg');
              if (barChart){
                barChart.style.width = '660px';
                barChart.style.height = 'auto';
              }
              const tabWrap  = document.querySelector('.tab-wrap');
              if (tabWrap){
                tabWrap.style.paddingTop = '0px';
              }
            }
          }));
          scrollY = scrollY - height;
        }
        const pdf = new jsPDF( {orientation: "landscape"});
        let firstPage = true;
        Promise.all(promises).then((pages) => {
          pages.forEach((page)=>{
            if (!firstPage){
              pdf.addPage();
            }
            pdf.addImage(page, 'jpg', 0,0, 297, 210, '', 'NONE');
            firstPage = false;
          });
          pdf.save(`report.pdf`);
        });
      },0)
    };

    const getMeta = (filters,title, data) => {
      if (!filters){
        return [];
      }
      let meta = [];
      meta.title = title;
      meta.dates = format(new tzDate(filters.start_date), 'MMM d, y') + ' - ' + format(new tzDate(filters.end_date), 'MMM d, y');
      let category = data?.project_categories?.find((c) => {
        return c.id == filters.projectCategory
      });

      if (!category){
        category = data?.goal_categories?.find((c) => {
          return c.id == filters.goalCategory
        });
      }
      if (category){
        meta.category = category.name;
      }

      let projects = data?.all_projects?.filter((p) => {
        return (filters.projects.indexOf(String(p.id)) > -1);
      });
      projects = (projects=== undefined ) ? null : projects;
      
      if (projects && projects.length > 0 ){
        meta.projects = projects.map(e => e.name).join(', ');
      }

      let goals = data?.all_goals?.filter((p) => {
        return (filters.goals.indexOf(String(p.id)) > -1);
      });

      goals = (goals=== undefined ) ? null : goals;
      if (goals && goals.length > 0 ){
          meta.goals = goals.map(e => e.name).join(', ');
      }
      return meta;      
    }
  
    const value = useMemo(() => {
      return {
        getMeta,
        exportPdf,
        saveReport,
        setFilters
      }
    }, [])
  
    return <ReportContext.Provider value={value} {...props}>
      <ReportPrintHeader meta={getMeta(filters, title, data)}/>
      {children}
      <div className="hidden text-center block-pdf">© {format(new Date(), "yyyy")} Monicat Data, LLC. | sunsett.io</div>
  </ReportContext.Provider>
  }
  
  export default ReportProvider
  