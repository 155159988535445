import { Fragment } from 'react'
import { CardContainer, Button } from '.'

import { plansContent } from '../utils/content'

const getTopBorderClass = (index) => {
  switch (index) {
    case 0:
      return 'bg-navy-5'
    case 1:
      return 'bg-yellow-3'
    case 2:
      return 'bg-orange-3'
    case 3:
    default:
      return 'bg-teal-4'
  }
}

const getLimitCopy = (limit) => {
  const isUnlimited = limit.limit < 0
  switch (limit.name) {
    case 'max_users':
      let userLimit = limit.limit;
      userLimit--;
      if (userLimit == 0){
        return `No additional collaborators`;
      }
      return isUnlimited
        ? `Unlimited additional collaborators`
        : `Up to ${userLimit} additional collaborator${userLimit === 1 ? '' : 's'}`
    case 'max_data_connections':
      return isUnlimited
        ? `Unlimited data connections`
        : `Up to ${limit.limit} data connection${limit.limit === 1 ? '' : 's'}`
    case 'max_active_goals':
      return isUnlimited
        ? `Unlimited active goals`
        : `Up to ${limit.limit} active goal${limit.limit === 1 ? '' : 's'}`
    case 'max_active_projects':
      return isUnlimited
        ? `Unlimited active projects`
        : `Up to ${limit.limit} active project${limit.limit === 1 ? '' : 's'}`
    case 'max_saved_reports':
      return isUnlimited
        ? `Unlimited saved reports`
        :  limit.limit ? `Up to ${limit.limit} saved report${limit.limit === 1 ? '' : 's'}` : 'No saved reports.'
  }
}

const PlanCard = ({
  plan,
  index,
  currentSubscription,
  handlePlanSelection,
  showAnnualPricing = false,
}) => {
  const planContent = plansContent.find((p) => p.name == plan.name)
  return (
    <CardContainer
      classes={`flex-1 sm:ml-5 mb-5 sm:mb-0 relative overflow-hidden px-0 flex flex-col py-0`}>
      <div
        className={`absolute top-0 left-0 w-full h-2 ${getTopBorderClass(
          index,
        )}`}
      />
      <div className="px-3 pb-2 pt-6 text-center border-b border-dark-15">
        <h2 className="text-lg">{plan.name}</h2>
        <div className="font-serif text-3xl font-thin">
          {!showAnnualPricing ? (
            <Fragment>
              <span className="text-5xl">${plan.price}</span>
              <span>/mo</span>
            </Fragment>
          ) : (
            <Fragment>
              <span className="text-3xl">${plan.annual_price.toFixed(2)}</span>
              <span className="text-xl">/ year</span>
            </Fragment>
          )}
        </div>
      </div>
      <div className="py-6 px-5 border-b border-dark-15 flex-1 flex flex-col">
        <div class="flex-1">
        <h3 className="font-bold mb-2">Workspace Limits</h3>
        <p className="font-light my-1">
            1 admin account
        </p>
        {plan.limits.map((limit, index) => (
          <p className="font-light my-1" key={`plan-${plan.id}-limit-${index}`}>
            {getLimitCopy(limit)}
          </p>
        ))}
        </div>
        <div>
        <h3 className="font-bold mb-2 mt-6">Features</h3>
        <p key={`plan-${plan.id}-feature-${index}`} className="font-light my-1">
          {planContent?.features.join(', ')}
        </p>
        </div>
      </div>
      <div className="pt-6 pb-6 flex items-center justify-center w-full">
        {currentSubscription?.subscription.id === plan.id ? (
          <div className="py-3 text-dark-50">Your Plan</div>
        ) : (
          <Button
            action={() => handlePlanSelection(plan)}
            text={
              currentSubscription?.subscription
                ? 'Switch to This Plan'
                : 'Get Started'
            }
          />
        )}
      </div>
    </CardContainer>
  )
}

export default PlanCard
