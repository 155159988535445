import format from 'date-fns/format'
import {
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryBar,
} from 'victory'
import { dateAxisFormat, tzDate} from '../utils'

import getTheme from '../services/victory-theme'

const BarChart = ({ data, goalValue, annotationLines = [] }) => {
  return (
    <div className="sunsett-bar-chart">

    <VictoryChart
      domainPadding={{ y: 20, x:23 }}
      theme={getTheme()}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          voronoiBlacklist={['GoalAnnotation']}
          labels={({ datum }) =>
            `${datum.x}: ${datum.y}`
          }
          labelComponent={
            <VictoryLabel
              dy={-7}
              style={{
                backgroundColor: 'white',
                fontSize: 8,
                border: '1px solid rgba(41, 50, 65, 0.15)',
              }}
              backgroundStyle={{
                fill: 'white',
                stroke: 'rgba(41, 50, 65, 0.15)',
                strokeWidth: 1,
              }}
              backgroundPadding={10}
            />
          }
        />
      }>
      <VictoryAxis tickFormat={(t) => dateAxisFormat(t, data)} /> 
      <VictoryAxis dependentAxis />
      <VictoryBar
        alignment="middle"
        labelComponent={<VictoryTooltip />}
        data={data}
      />

      {goalValue != null && (
        <VictoryLine
          name="GoalAnnotation"
          style={{
            data: {
              stroke: '#0b9588',
              strokeWidth: 1,
              strokeDasharray: '3,3',
            },
            labels: {
              angle: 0,
              fill: '#0b9588',
              fontSize: 8,
            },
            pointerEvents: 'none',
            stroke: 'gray 5,5',
          }}
          labels={[`GOAL: ${goalValue}`]}
          labelComponent={
            <VictoryLabel
              angle={0}
              textAnchor="end"
              transform="translate(50 6)"
            />
          }
          y={() => goalValue}
        />
      )}
    </VictoryChart>
    </div>
  )
}

export default BarChart
