module.exports = {
  purge: ['./pages/**/*.js', './components/**/*.js'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      xs: '420px',
      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
    },
    fontSize: {
      why: '.2rem',
    },
    minWidth: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
    },
    fontFamily: {
      serif: 'p22-mackinac-pro, serif',
      sans: 'GTWalsheim, sans-serif',
    },
    fontSize: {
      xxs: '0.625rem',
      xs: '.75rem',
      sm: '.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '2rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
    },
    extend: {
      width: {
        124: '31rem',
        186: '46.5rem',
      },
      zIndex: {
        100: '100',
      },
    },
    colors: {
      transparent: 'transparent',
      white: {
        DEFAULT: '#ffffff',
      },
      teal: {
        1: '#d0eae4',
        2: '#9be4d4',
        3: '#65c0ad',
        4: '#0b9588',
        5: '#076158',
      },
      navy: {
        1: '#c4d3ec',
        2: '#89a7d9',
        3: '#3d5b81',
        4: '#2e3d56',
        5: '#293241',
      },
      green: {
        1: '#cdead4',
        2: '#91dca1',
        3: '#5bba70',
        4: '#458e55',
        5: '#2c6237',
      },
      yellow: {
        1: '#fff1cc',
        2: '#ffe085',
        3: '#ffcd38',
        4: '#db9429',
        5: '#a75e26',
      },
      orange: {
        1: '#f7d9c7',
        2: '#ff9f63',
        3: '#e66d22',
        4: '#b3551b',
        5: '#66310f',
      },
      red: {
        1: '#fabfb3',
        2: '#fa8050',
        3: '#de4d15',
        4: '#a73305',
        5: '#611e03',
      },
      dark: {
        2: 'rgba(41, 50, 65, 0.02)',
        5: 'rgba(41, 50, 65, 0.05)',
        15: 'rgba(41, 50, 65, 0.15)',
        50: 'rgba(41, 50, 65, 0.5)',
        input: 'hsl(0,0%,95%)',
      },
      'greyish-brown': {
        50: 'rgba(74, 74, 74, 0.5)',
        DEFAULT: '#4a4a4a',
      },
      brown: {
        1: '#dacdc4',
        2: '#bfa491',
        3: '#99775e',
        4: '#523d2e',
        5: '#33251b',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
}
