import { useToasts } from 'react-toast-notifications'
import Loader from 'react-loader-spinner'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'

import { plansContent } from '../utils/content'
import api from '../services/api'
import { Button } from '.'

const getLimitCopy = (limit) => {
  const isUnlimited = limit.limit < 0
  switch (limit.name) {
    case 'max_users':
      return isUnlimited
        ? `Unlimited user accounts`
        : `Up to ${limit.limit} user account${limit.limit === 1 ? '' : 's'}`
    case 'max_data_connections':
      return isUnlimited
        ? `Unlimited data connections`
        : `Up to ${limit.limit} data connection${limit.limit === 1 ? '' : 's'}`
    case 'max_active_goals':
      return isUnlimited
        ? `Unlimited active goals`
        : `Up to ${limit.limit} active goal${limit.limit === 1 ? '' : 's'}`
    case 'max_active_projects':
      return isUnlimited
        ? `Unlimited active projects`
        : `Up to ${limit.limit} active project${limit.limit === 1 ? '' : 's'}`
    case 'max_saved_reports':
      return isUnlimited
        ? `Unlimited saved reports`
        : limit.limit ? `Up to ${limit.limit} saved report${limit.limit === 1 ? '' : 's'}` : 'No saved reports.'
  }
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY)

const BillingDetails = ({ workspace }) => {
  const router = useRouter()
  const { addToast } = useToasts()

  const {
    isLoading,
    error,
    data: subscriptionLevels,
  } = useQuery('subscription_levels', api.getSubscriptionLevels)

  const handleManageAccountClick = async () => {
    const portalSession = await api.getStripePortalSession({
      workspaceId: router.query?.workspaceId,
      back:
        router.query?.back ||
        `/workspaces/${router.query?.workspaceId}/dashboard`,
    })

    if (portalSession) {
      document.location.href = portalSession.url
    } else {
      addToast(
        'Sorry, there was an error connecting to the payment system. Please try again later',
        {
          appearance: 'error',
        },
      )
    }
  }

  return (
    <Elements stripe={stripePromise}>
      {isLoading ? (
        <div className="flex px-3 py-10 justify-center items-center">
          <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
        </div>
      ) : (
        <div>
          <h4 className="text-xs text-dark-50">PLAN DETAILS</h4>
          <h3 className="text-2xl text-navy-5 my-3">
            {workspace.team_subscription.subscription.name}
          </h3>
          <p
            className="font-light"
            dangerouslySetInnerHTML={{
              __html: workspace.team_subscription.subscription.description,
            }}
          />
          {/*subscriptionLevels
            .find(
              (p) => p.name == workspace.team_subscription.subscription.name,
            )
            ?.limits.map((limit, index) => (
              <p
                className="font-light my-1"
                key={`plan-${workspace.team_subscription.subscription.id}-limit-${index}`}>
                {getLimitCopy(limit)}
              </p>
            )) */}

          <Button
            action={handleManageAccountClick}
            text="Manage Subscription"
            theme="light-teal"
            size="small"
            classes="mt-5 uppercase text-sm"
          />
        </div>
      )}
    </Elements>
  )
}

export default BillingDetails
