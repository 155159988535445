export default function getTheme() {
  const { assign } = Object
  const colors = [
    '#252525',
    '#525252',
    '#737373',
    '#969696',
    '#bdbdbd',
    '#d9d9d9',
    '#f0f0f0',
  ]
  const charcoal = '#252525'
  const grey = '#969696'

  // Typography
  const sansSerif = "'Gill Sans', 'Seravek', 'Trebuchet MS', sans-serif"
  const letterSpacing = 'normal'
  const fontSize = 8

  // Layout
  const baseProps = {
    width: 450,
    height: 300,
    padding: 35,
    colorScale: colors,
  }

  // Labels
  const baseLabelStyles = {
    fontFamily: sansSerif,
    fontSize,
    letterSpacing,
    padding: 10,
    fill: charcoal,
    stroke: 'transparent',
  }

  const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)

  // Strokes
  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'

  // Put it all together...
  const theme = {
    area: assign(
      {
        style: {
          data: {
            fill: charcoal,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    axis: assign(
      {
        style: {
          axis: {
            fill: 'transparent',
            stroke: 'rgba(74, 74, 74, 0.15)',
            strokeWidth: 1,
            strokeLinecap,
            strokeLinejoin,
          },
          axisLabel: assign({}, centeredLabelStyles, {
            padding: 25,
          }),
          grid: {
            fill: 'none',
            stroke: 'rgba(74, 74, 74, 0.15)',
            pointerEvents: 'painted',
          },
          ticks: {
            fill: 'transparent',
            size: 1,
            stroke: 'transparent',
          },
          tickLabels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    bar: assign(
      {
        style: {
          data: {
            fill: '#ffcd38',
            padding: 8,
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    boxplot: assign(
      {
        style: {
          max: { padding: 8, stroke: charcoal, strokeWidth: 1 },
          maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
          median: { padding: 8, stroke: charcoal, strokeWidth: 1 },
          medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
          min: { padding: 8, stroke: charcoal, strokeWidth: 1 },
          minLabels: assign({}, baseLabelStyles, { padding: 3 }),
          q1: { padding: 8, fill: grey },
          q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
          q3: { padding: 8, fill: grey },
          q3Labels: assign({}, baseLabelStyles, { padding: 3 }),
        },
        boxWidth: 20,
      },
      baseProps,
    ),
    candlestick: assign(
      {
        style: {
          data: {
            stroke: charcoal,
            strokeWidth: 1,
          },
          labels: assign({}, baseLabelStyles, { padding: 5 }),
        },
        candleColors: {
          positive: '#ffffff',
          negative: charcoal,
        },
      },
      baseProps,
    ),
    chart: baseProps,
    errorbar: assign(
      {
        borderWidth: 8,
        style: {
          data: {
            fill: 'transparent',
            stroke: charcoal,
            strokeWidth: 2,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    group: assign(
      {
        colorScale: colors,
      },
      baseProps,
    ),
    histogram: assign(
      {
        style: {
          data: {
            fill: '#ffcd38',
            margin: 10,
            // stroke: 'transparent',
            // strokeWidth: 3,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    legend: {
      colorScale: colors,
      gutter: 10,
      orientation: 'vertical',
      titleOrientation: 'top',
      style: {
        data: {
          type: 'circle',
        },
        labels: baseLabelStyles,
        title: assign({}, baseLabelStyles, { padding: 5 }),
      },
    },
    line: assign(
      {
        style: {
          data: {
            fill: 'transparent',
            stroke: '#ffcd38',
            strokeWidth: 3,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    pie: {
      style: {
        data: {
          padding: 10,
          stroke: 'transparent',
          strokeWidth: 1,
        },
        labels: assign({}, baseLabelStyles, { padding: 20, fontSize: 16 }),
      },
      colorScale: colors,
      width: 400,
      height: 400,
      padding: 50,
    },
    scatter: assign(
      {
        style: {
          data: {
            fill: charcoal,
            stroke: 'transparent',
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps,
    ),
    stack: assign(
      {
        colorScale: colors,
      },
      baseProps,
    ),
    tooltip: {
      style: assign({}, baseLabelStyles, {
        padding: 100,
        pointerEvents: 'none',
      }),
      flyoutStyle: {
        pointerEvents: 'none',
        fill: 'white',
        stroke: 'rgba(41, 50, 65, 0.15)',
        strokeWidth: 1,
      },
      flyoutPadding: 10,
      cornerRadius: 5,
      pointerLength: 10,
    },
    voronoi: assign(
      {
        style: {
          data: {
            fill: 'transparent',
            stroke: 'transparent',
            strokeWidth: 0,
          },
          labels: assign({}, baseLabelStyles, {
            padding: 5,
            pointerEvents: 'none',
          }),
          flyout: {
            stroke: charcoal,
            strokeWidth: 1,
            fill: '#f0f0f0',
            pointerEvents: 'none',
          },
        },
      },
      baseProps,
    ),
  }

  return theme
}
