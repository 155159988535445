import { Icon, Button, CardContainer, Modal } from '.'
import CloseIcon from '../public/close_circle.svg'

const DataConfirmationModal = ({ data: { isOpen, dataSource }, close }) => {
  return (
    <Modal isOpen={isOpen}>
      <CardContainer classes="relative w-186 max-w-full max-h-full overflow-auto mx-5">
        <div className="flex justify-end mb-5">
          <button onClick={close}>
            <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
          </button>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-48 w-48 relative">
            <img src="/data_source_bg_shape.svg" className="w-48 absolute" />
            <img
              src={dataSource?.image}
              className="h-20 w-20 absolute top-14 left-16 rounded-lg"
            />
          </div>
          <h2 className="text-2xl font-serif mb-4 text-navy-5">
            You connected your {dataSource?.name} account!
          </h2>
          <p className="font-light text-navy-5 mb-4">
            You're all set to start creating goals with this data source.
          </p>
          <Button text="Close" action={close} />
        </div>
      </CardContainer>
    </Modal>
  )
}

export default DataConfirmationModal
