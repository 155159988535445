import { Fragment } from 'react'

import { TextInput } from '.'

const InviteWorkspaceMemberForm = ({ formValues, updateFormValue }) => {
  return (
    <Fragment>
      {formValues.invitations.map((invitation, index) => (
        <div key={`invitation-${index}`} className="mb-4">
          <TextInput
            label="Coworker's Name"
            placeholder="Julie Appleseed"
            type="text"
            value={invitation.name}
            onChange={(e) => {
              updateFormValue(
                'invitations',
                formValues.invitations.map((inv, i) => {
                  return i === index
                    ? {
                        ...formValues.invitations[i],
                        name: e.target.value,
                      }
                    : inv
                }),
              )
            }}
          />
          <TextInput
            label="Coworker's Email"
            placeholder="julie@example.com"
            type="email"
            value={invitation.email}
            onChange={(e) => {
              updateFormValue(
                'invitations',
                formValues.invitations.map((inv, i) => {
                  return i === index
                    ? {
                        ...formValues.invitations[i],
                        email: e.target.value,
                      }
                    : inv
                }),
              )
            }}
          />
        </div>
      ))}
      {/* <div className="-mt-2 mb-4">
        <a
          href="#"
          className="text-teal-4 text-sm underline"
          onClick={(e) => {
            e.preventDefault()
            updateFormValue(
              'invitations',
              formValues.invitations.concat([
                {
                  name: '',
                  email: '',
                },
              ]),
            )
          }}>
          + Add Another Coworker
        </a>
      </div> */}
    </Fragment>
  )
}

export default InviteWorkspaceMemberForm
