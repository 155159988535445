import {
  VictoryChart,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryTooltip,
  VictoryContainer,
  VictoryLabel,
  VictoryScatter,
} from 'victory'
import { format, max } from 'date-fns'

import getTheme from '../services/victory-theme'
import { statusColors, getYAxisValusFromMax, dateAxisFormat} from '../utils'
import { FlyoutTooltip, GoalChartLine } from '.'

const hiddenAxisStyles = {
  axis: { stroke: 'transparent' },
  ticks: { stroke: 'transparent' },
  tickLabels: { fill: 'transparent' },
  grid: {
    fill: 'none',
    stroke: 'transparent',
    pointerEvents: 'painted',
  },
}

const LineChart = ({
  lines,
  disableLabel = false,
  disableAxes,
  integerTickValuesOnly,
  goalValue,
  annotationLines = [],
  height,
  isCardChart = false,
}) => {
  
  let marginMultiplier = .15;
  let dataValues = Object.keys(lines).map((key)=>{return lines[key].y});
  if(goalValue){
    dataValues.push(goalValue);
  }
  let maxDataValue = Math.max(...dataValues);
  let minDataValue = Math.min(...dataValues);
  let margin = (maxDataValue-minDataValue) * marginMultiplier;
  let minY = minDataValue - margin;
  let maxY = maxDataValue + margin;
  let differencePercentage = (maxY - minY) / maxY;

  const getMaxCount = (lines) => {
    let maxCount = 0;
    Object.keys(lines).forEach((status, i) => {
      lines[status].forEach((d) => {
        if (d.count > maxCount){
          maxCount = d.count
        };
      });
    });
    return maxCount;
  }

  const yAxisValues = () => {
    if (integerTickValuesOnly){
      const maxCount = getMaxCount(lines);
      return getYAxisValusFromMax(maxCount);
    } 
    return null;
  }

  return (
    <div className="sunsett-line-chart">
      <VictoryChart
        domain={{y: [minY,maxY]}}
        height={height ? height : getTheme().height}
        singleQuadrantDomainPadding={false}
        theme={getTheme()}
        containerComponent={
          disableLabel ? (
            <VictoryContainer />
          ) : (
            <VictoryVoronoiContainer
              voronoiBlacklist={['GoalAnnotation']}
              voronoiDimension="x"
              labels={() => ' '}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  orientation="top"
                  flyoutComponent={<FlyoutTooltip />}
                  labels={() => ''}
                />
              }
            />
          )
        }>


        <VictoryAxis
          style={disableAxes ? hiddenAxisStyles : null}
          tickFormat={(t) => dateAxisFormat(t, lines)}
          offsetY={differencePercentage > 1 ? 21 * differencePercentage : 0}
          fixLabelOverlap={true}
        />
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          style={disableAxes ? hiddenAxisStyles : null}
          tickValues={yAxisValues()}
        />
        {!Array.isArray(lines) ? null : lines.length > 1 ? (
          <VictoryLine
            key={status}
            interpolation="natural"
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{
              data: {
                stroke: statusColors['ontrack'],
                strokeWidth: isCardChart ? 6 : 3,
              },
            }}
            data={lines}
          />
        ) : (
          <VictoryScatter
            key={status}
            interpolation="natural"
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{
              data: {
                stroke: statusColors['ontrack'],
                fill: statusColors['ontrack'],
              },
            }}
            data={lines}
          />
        )}
        {goalValue != null && (
          <VictoryLine
            name="GoalAnnotation"
            style={{
              data: {
                stroke: '#0b9588',
                strokeWidth: 1,
                strokeDasharray: '3,3',
              },
              labels: {
                angle: 0,
                fill: '#0b9588',
                fontSize: 8,
              },
              pointerEvents: 'none',
              stroke: 'gray 5,5',
            }}
            labels={[isCardChart ? '' : `GOAL: ${goalValue}`]}
            labelComponent={
              <VictoryLabel
                angle={0}
                textAnchor="end"
                transform="translate(50 6)"
              />
            }
            y={() => goalValue}
          />
        )}
        {annotationLines.map((annotationLine, i) => {
          return (
            <VictoryLine
              name="GoalAnnotation"
              key={`annotationline-${i}`}
              style={{
                data: {
                  stroke: annotationLine.strokeColor,
                  strokeWidth: 2,
                  strokeDasharray: '3,3',
                },
                labels: {
                  angle: -90,
                  fill: annotationLine.strokeColor,
                  fontSize: 8,
                },
              }}
              labels={[annotationLine.label]}
              labelComponent={<VictoryLabel y={100} />}
              x={() => annotationLine.x}
            />
          )
        })}
      </VictoryChart>
    </div>
  )
}

export default LineChart
