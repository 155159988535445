import { Fragment, useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import addDays from 'date-fns/addDays'
import isBefore from 'date-fns/isBefore'

import { GlobalContext } from '../context/GlobalContext'
import { getStartedSteps } from '../utils/content'
import ArrowRightIcon from '../public/arrow_right.svg'
import CloseIcon from '../public/close_circle.svg'
import Icon from './Icon'
import Button from './Button'

const DashboardPrompt = ({ onClose = () => null }) => {
  const globalContext = useContext(GlobalContext)
  const [isVisible, setIsVisible] = useState(false)
  const [showOnboardContent, setShowOnboardContent] = useState(true)
  const [randomProject, setRandomProject] = useState()

  const close = () => {
    localStorage.setItem(
      `sunsett_dashboard_prompt_dismissed_${globalContext.activeWorkspace.id}`,
      new Date(),
    )
    setIsVisible(false)
    onClose()
  }

  useEffect(() => {
    const dismissalDate = localStorage.getItem(
      `sunsett_dashboard_prompt_dismissed_${globalContext.activeWorkspace.id}`,
    )
    if (dismissalDate) {
      const now = new Date()
      const d = new Date(dismissalDate)
      if (isBefore(now, addDays(d, 7))) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
        localStorage.removeItem(
          `sunsett_dashboard_prompt_dismissed_${globalContext.activeWorkspace.id}`,
        )
      }
    } else {
      setIsVisible(true)
    }

    const workspaceHasDataAndGoals =
      globalContext.activeWorkspace?.goals.length &&
      globalContext.activeWorkspace?.data_sources.length
    setShowOnboardContent(!workspaceHasDataAndGoals)
    if (workspaceHasDataAndGoals) {
      setShowOnboardContent(false)
      if (globalContext.activeWorkspace?.projects?.length) {
        const randomProj =
          globalContext.activeWorkspace.projects[
            Math.floor(
              Math.random() * globalContext.activeWorkspace.projects.length,
            )
          ]
        setRandomProject(randomProj)
      } else {
        setIsVisible(false)
      }
    }
  }, [])

  return (
    <div
      style={{
        backgroundImage: `url(${
          showOnboardContent
            ? '/dashboard_prompt_bg.svg'
            : '/secondary_prompt_bg.svg'
        })`,
      }}
      className={`rounded-xl bg-cover bg-no-repeat relative transition-all duration-300 bg-center ${
        showOnboardContent ? 'lg:bg-right-top' : 'lg:bg-left-top'
      } ${
        isVisible
          ? 'opacity-1 max-h-full py-8 md:py-12 mb-6'
          : 'opacity-0 max-h-0 pointer-events-none'
      }`}>
      <button className="absolute top-3 right-3" onClick={close}>
        <Icon icon={CloseIcon} fill="black" />
      </button>
      {globalContext.activeWorkspace?.id && (
        <div
          className={`text-white px-5 flex ${
            showOnboardContent
              ? 'flex-col sm:px-8'
              : 'flex-col lg:flex-row justify-between items-start lg:items-center lg:pl-40 sm:px-6'
          }`}>
          <div>
            <h1 className="text-2xl font-serif">
              Hi {globalContext.user?.first_name || 'there'}!
            </h1>
            <p className="mt-4 lg:w-124 w-full lg:max-w-full text-3xl font-serif">
              {showOnboardContent
                ? 'Welcome to Sunsett!'
                : `Have you checked on ${randomProject?.name} lately?`}
            </p>
            {showOnboardContent ? <p className="text-2xl font-serif mt-8">Get Started</p> : null }
          </div>
          {showOnboardContent ? (
            <Fragment>
              <div className="flex flex-col md:flex-row lg:justify-between mt-8 lg:px-12">
                {getStartedSteps.map((item, index) => {
                  const isCompleted =
                    index === 0 ||
                    globalContext.activeWorkspace[item.workspaceKey].length
                  return (
                    <Link
                      key={`get-started-${index}`}
                      href={`/workspaces/${globalContext.activeWorkspace.id}${item.route}`}>
                      <button
                        disabled={isCompleted}
                        className={`flex mt-4 md:flex-1 lg:flex-initial md:mt-0 md:pr-5 ${
                          isCompleted
                            ? 'opacity-50 cursor-default'
                            : 'hover:opacity-80'
                        }`}>
                        <img
                          src={
                            isCompleted
                              ? '/wobbly_check_circle.svg'
                              : '/wobbly_circle.svg'
                          }
                          className="self-start"
                        />
                        <div className="ml-3">
                          <h3 className="flex items-center text-left">
                            <span>{item.title}</span>
                            <Icon
                              icon={ArrowRightIcon}
                              fill="white"
                              classes="ml-2"
                            />
                          </h3>
                          <p className="text-left">{item.description}</p>
                        </div>
                      </button>
                    </Link>
                  )
                })}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {isVisible && (
                <Button
                  text="See Project"
                  href={`/workspaces/${randomProject?.team_id}/projects/${randomProject?.id}`}
                  theme="yellow"
                  classes="mt-5 lg:mt-0"
                />
              )}
            </Fragment>
          )}
        </div>
      )}
    </div>
  )
}

export default DashboardPrompt
