import { useState, useEffect, useContext } from 'react'
import { useMutation } from 'react-query'
import Loader from 'react-loader-spinner'
import { useToasts } from 'react-toast-notifications'

import { TextInput, Button, Alert } from '.'
import api from '../services/api'
import { GlobalContext } from '../context/GlobalContext'

const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [errors, setErrors] = useState([])
  const globalContext = useContext(GlobalContext)
  const { addToast } = useToasts()

  useEffect(() => {
    setIsValid(
      !!currentPassword &&
        currentPassword.length >= 8 &&
        !!newPassword &&
        newPassword.length >= 8 &&
        !!newPasswordConfirmation &&
        newPasswordConfirmation.length >= 8 &&
        newPassword === newPasswordConfirmation,
    )
  }, [currentPassword, newPassword, newPasswordConfirmation])

  const reset = (e) => {
    if (e) e.preventDefault()
    setCurrentPassword('')
    setNewPassword('')
    setNewPasswordConfirmation('')
  }

  const mutation = useMutation(
    (e) =>
      api.updatePassword({
        currentPassword,
        newPassword,
        newPasswordConfirmation,
      }),
    {
      onSuccess: () => {
        reset()
        setIsWorking(false)
        addToast('Account updated successfully!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        })
      },
      onError: (e) => {
        setIsWorking(false)
        if (e.response.data.errors) {
          setErrors(Object.values(e.response.data.errors))
        } else if (e.response.data.message) {
          setErrors([e.response.data.message])
        }
      },
    },
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsWorking(true)
    setErrors([])
    mutation.mutate()
  }

  return (
    <form className="p-6">
      <TextInput
        type="password"
        onChange={(e) => setCurrentPassword(e.target.value)}
        label="Current Password"
        value={currentPassword}
      />
      <TextInput
        type="password"
        onChange={(e) => setNewPassword(e.target.value)}
        label="New Password"
        placeholder="8+ characters"
        value={newPassword}
      />
      <TextInput
        type="password"
        onChange={(e) => setNewPasswordConfirmation(e.target.value)}
        label="Confirm New Password"
        placeholder="8+ characters"
        value={newPasswordConfirmation}
      />
      <div>
        {!!errors.length && <Alert type="danger" text={errors.join(' ')} />}
      </div>
      <div className="flex justify-center sm:justify-end">
        <Button
          disabled={!isValid}
          action={reset}
          text="Discard"
          theme="ghost"
          classes="mr-3"
        />
        <Button disabled={!isValid} action={handleSubmit} text="Save" />
      </div>
      {isWorking && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50 rounded-xl">
          <Loader type="TailSpin" height="72" width="72" color="white" />
        </div>
      )}
    </form>
  )
}

export default UpdatePassword
