const Toggle = ({ isActive, onToggle, activeText, inactiveText }) => {
  return (
    <div className="flex items-center mb-5" onClick={onToggle}>
      <span
        className={`uppercase text-sm ${
          !isActive ? 'text-teal-4 font-bold' : 'text-dark-50'
        }`}>
        {inactiveText}
      </span>
      <div
        className={`border-navy-4 border-2 rounded-full w-16 h-8 relative mx-2`}>
        <div
          className={`rounded-full w-6 h-6 bg-navy-4 absolute top-0.5 transition-all ${
            isActive ? 'left-8' : 'left-1'
          }`}
        />
      </div>
      <span
        className={`uppercase text-sm ${
          isActive ? 'text-teal-4 font-bold' : 'text-dark-50'
        }`}>
        {activeText}
      </span>
    </div>
  )
}

export default Toggle
