import styled from 'styled-components'

const Icon = ({ icon, fill = 'black', size = 'normal', classes }) => {
  return (
    <IconWrapper fill={fill} size={size} className={classes}>
      {icon()}
    </IconWrapper>
  )
}

const IconWrapper = styled.div`
  fill: ${(props) => props.fill} !important;

  * {
    fill: ${(props) => props.fill} !important;
  }

  svg {
    transform: ${(props) =>
      props.size === 'small'
        ? 'scale(0.6)'
        : props.size === 'large'
        ? 'scale(1.5)'
        : 'none'};
  }
`

export default Icon
