import { useContext } from 'react'
import Modal from 'react-modal'
import Loader from 'react-loader-spinner'

import { Icon } from '.'
import CloseIcon from '../public/close_circle.svg'
import Button from './Button'
import CardContainer from './CardContainer'
import { GlobalContext } from '../context/GlobalContext'

Modal.setAppElement('#__next')

const ConfirmModal = () => {
  const globalContext = useContext(GlobalContext)

  const {
    isOpen,
    onConfirm,
    title,
    description,
    closeModal,
    isWorking,
  } = globalContext.confirmModal
  return (
    <Modal
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: 'rgba(41, 50, 65, 0.5)',
        },
        content: {
          zIndex: 9999,
          background: 'transparent',
          border: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          inset: '1px',
          maxWidth: '100%',
        },
      }}
      isOpen={isOpen}>
      <CardContainer classes="relative">
        <button className="absolute top-4 right-4" onClick={closeModal}>
          <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
        </button>
        <div className="md:m-10">
          <h1 className="text-center mb-3 font-serif text-2xl">{title}</h1>
          <p className="text-center mb-3">{description}</p>
          <div className="flex justify-center mt-8">
            <Button
              action={closeModal}
              text="Cancel"
              theme="ghost"
              classes="mr-3"
            />
            <Button action={onConfirm} text="Confirm" />
          </div>
        </div>
        {isWorking && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-15 rounded-xl">
            <Loader type="TailSpin" height="72" width="72" color="white" />
          </div>
        )}
      </CardContainer>
    </Modal>
  )
}

export default ConfirmModal
