import { useState } from 'react'

import api from '../services/api'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { ContextualMenu, Icon} from '.'
import PencilIcon from '../public/pencil.svg'
import TrashIcon from '../public/trash.svg'
import { theme } from '../tailwind.config'
import { tzDate } from '../utils'


const SavedReport = () => {
    const router = useRouter()
    const [reportSort, setReportSort] = useState('title-asc')

    const { isLoading, error, data, refetch } = useQuery(
        [
            'saved-reports',
            {
            teamId: router.query.workspaceId,
            },
        ],
        () =>
            api.getSavedReports({
            teamId: router.query.workspaceId,
            }),
        {
            enabled: !!router.query.workspaceId,
        },
        )
    return (
        isLoading || !data ? (
            <div className="px-3 py-56 w-full flex justify-center items-center">
              <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
            </div>
          ) : (
            <div className="bg-white rounded-xl border border-dark-15 max-w-full mt-5 overflow-hidden p-0">
                 <div className="p-6 flex">
                  <div className="flex-1 md:px-6">
                    <button
                      className="text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setReportSort(
                          reportSort === 'title-asc' ? 'title-desc' : 'title-asc',
                        )
                      }>
                      Title
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            reportSort === 'title-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            reportSort === 'title-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="flex-1 md:px-6">

                  <button
                      className="text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setReportSort(
                          reportSort === 'date-asc'
                            ? 'date-desc'
                            : 'date-asc',
                        )
                      }>
                      date created
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            reportSort === 'date-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            reportSort === 'date-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="flex-1 md:px-6">
                  <button
                      className="text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setReportSort(
                          reportSort === 'type-asc'
                            ? 'type-desc'
                            : 'type-asc',
                        )
                      }>
                      report type
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            reportSort === 'type-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            reportSort === 'type-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="flex-1 md:px-6 text-xs text-dark-50 uppercase flex">
                      filters
                  
                  </div>
                  <div className="flex-1 md:px-6"></div>

                  <div className="flex-1 md:px-6"></div>
                  <div className="flex-1 md:px-6"></div>
                </div>
                <div className="p-0">
                    {data.sort((a, b) => {
                          switch (reportSort) {
                            case 'title-asc':
                              return a.name < b.name
                                ? -1
                                : a.name > b.name
                                ? 1
                                : 0
                            case 'date-asc':
                              return a.created_at < b.created_at
                              ? -1
                              : a.created_at > b.created_at
                              ? 1
                              : 0
                            case 'date-desc':
                                return b.created_at < a.created_at
                                ? -1
                                : b.created_at > a.created_at
                                ? 1
                                : 0
                            case 'type-asc':
                                return a.type < b.type
                                ? -1
                                : a.type > b.type
                                ? 1
                                : 0
                            case 'type-desc':
                                return b.type < a.type
                                ? -1
                                : b.type > a.type
                                ? 1
                                : 0
                            case 'title-desc':
                            default:
                              return b.name < a.name
                                ? -1
                                : b.name > a.name
                                ? 1
                                : 0
                          }
                        }).map((report, i) => {
                          let filters = [];
                          let qs = `?start=${report.start_date}&end=${report.end_date}&type=${report.type.toLowerCase()}s`;
                          if (report.projects?.length > 0){
                            filters.push({label: 'Projects', value: report.projects.length > 1 ? 'Multiple' : report.projects[0].name});
                            qs+=`&projects=${report.projects.map((project)=>project.id).join(',')}`
                          }
                          if (report.goals?.length > 0){
                            filters.push({label: 'Goals', value: report.goals.length > 1 ? 'Multiple' : report.goals[0].name});
                            qs+=`&goals=${report.goals.map((goal)=>goal.id).join(',')}`
                          }
                          if (report.project_categories?.length > 0){
                            filters.push({label: 'Categories', value: report.project_categories.length > 1 ? 'Multiple' : report.project_categories[0].name});
                            qs+=`&category=${report.project_categories.map((option)=>option.id).join(',')}`
                          }
                          if (report.goal_category_options?.length > 0){
                            filters.push({label: 'Categories', value: report.goal_category_options.length > 1 ? 'Multiple' : report.goal_category_options[0].name});
                          }
                          let filterFiller = [];
                          for (let i = filters.length; i < 3; i++) {
                            filterFiller.push(<div className="flex-1 md:px-6"/>);
                          }

                        const link = `/workspaces/${report.team_id}/${report.type === 'Category' ? 'categories/' + report?.goal_category_options[0].id: (report.type === 'Single Goal' ? 'goals/' + report.goals[0].id : 'reports')}/${qs}`;
                        
                        return <button className="flex flex-col bg-white relative border-t border-dark-15 w-full" onClick={()=>window.open(link)}>
                            <div className="p-5 flex items-center justify-between w-full flex-wrap md:flex-nowrap text-left text-s">
                                <div className="flex-1 md:px-6">
                                    {report.name}
                                </div>
                                <div className="flex-1 md:px-6 font-bold">
                                    {format(
                                    new Date(report.created_at),
                                    'MM/dd/yyyy',
                                  )}
                                </div>
                                <div className="flex-1 md:px-6 font-bold">
                                    {report.type}
                                </div>
                                {filters.map((filter)=>{
                                 return <div className="flex-1 md:px-6">
                                      <div class="flex flex-col items-start">
                                        <p class="font-bold text-left">{filter.value}</p>
                                        <h4 class="text-dark-50 text-xs uppercase">{filter.label}</h4>
                                      </div>
                                  </div>
                                })}
                                {filterFiller}
                                
                                <div className="flex-1 md:px-6">
                                    <div class="flex flex-col items-start"><p class="font-bold text-left">{format(
                                    new tzDate(report.start_date),
                                    'MM/dd/yyyy',
                                  )} - {format(
                                    new tzDate(report.end_date),
                                    'MM/dd/yyyy',
                                  )}</p><h4 class="text-dark-50 text-xs uppercase">Timeframe</h4></div>
                                </div>
                            </div>
                            <ContextualMenu
                              classes="top-3 right-0"
                              items={[
                                {
                                  text: `Download Saved Report`,
                                  action: (args) => {
                                    window.open(link + `&download=1`)
                                  },
                                  Icon: () => (
                                    <Icon icon={PencilIcon} fill={theme.colors.navy[5]} />
                                  ),
                                },
                                {
                                  text: `Delete Saved Report`,
                                  action: ()=> {
                                    api.deleteSavedReport(report.id).then(()=>{
                                      refetch();
                                    })
                                  },
                                  Icon: () => <Icon icon={TrashIcon} fill={theme.colors.red[2]} />,
                                  textClass: 'text-red-3',
                                },
                              ]}
                            />
                            </button>
                    })}
                </div>
            </div>
          ))
}

export default SavedReport