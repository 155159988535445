import { useState } from 'react'
import Link from 'next/link'

import { ButtonWrapper } from '.'

const ContextualMenu = ({ items, classes, label, relative }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`${!relative ? 'absolute' : ''} ${classes}`}>
      <a
        href="#"
        className={`flex flex-col justify-center items-center cursor-pointer ${!relative ? 'h-10 w-10' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}>
        { label ? label : <div>
          <div className="mb-1 bg-dark-50 rounded-full h-1 w-1" />
        <div className="mb-1 bg-dark-50 rounded-full h-1 w-1" />
        <div className="mb-1 bg-dark-50 rounded-full h-1 w-1" />
        </div>
          }
      </a>
      <div
        className={`fixed top-0 left-0  ${
          isOpen ? 'h-screen w-screen' : 'pointer-events-none'
        }`}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      />
      <div
        className={`absolute max-w-full sm:max-w-none bg-white rounded-md transform transition-all duration-300 shadow-lg w-56 flex flex-col overflow-hidden ${
          relative
            ? 'right-0'
            : 'right-full top-0'
        } ${
          isOpen
            ? '-translate-x-1'
            : 'translate-x-0 pointer-events-none opacity-0'
        }`}>
        {items.map((item, index) => (
          <ButtonWrapper href={item.href} key={`${item.href}-${index}`}>
            <a
              className="px-3 py-2 flex-1 flex justify-start hover:bg-dark-5 transition-all items-center w-full cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (item.action) {
                  item.action(e)
                  setIsOpen(false)
                }
              }}>
              {item.Icon && <item.Icon />}
              <span
                className={`ml-2 text-sm ${
                  item.textClass ? item.textClass : 'text-navy-5'
                }`}>
                {item.text}
              </span>
            </a>
          </ButtonWrapper>
        ))}
      </div>
    </div>
  )
}

export default ContextualMenu
