import Cookies from 'js-cookie'
import React, {
  createContext,
  useMemo,
  useState,
  useReducer,
  useEffect,
} from 'react'
import { useToasts } from 'react-toast-notifications'

import { AppLoading, ConfirmModal } from '../components'
import api from '../services/api'

export const GlobalContext = createContext({})

const GlobalProvider = (props) => {
  const { children } = props
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [activeWorkspace, setActiveWorkspace] = useState()
  const [activeWorkspaceUser, setActiveWorkspaceUser] = useState()
  const { addToast } = useToasts()
  const [confirmModal, setConfirmModal] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'update':
          return { ...state, ...action.payload }
        case 'reset':
          return {
            isOpen: false,
            onConfirm: null,
            title: '',
            description: '',
            closeModal: null,
            isWorking: false,
          }
      }
    },
    {
      isOpen: false,
      onConfirm: null,
      title: '',
      description: '',
      closeModal: null,
      isWorking: false,
    },
  )

  useEffect(() => {
    try {
      const user = localStorage.getItem('sunsett_user')
      if (user) {
        const u = JSON.parse(user)
        setUser(u)
        refreshUser(u.id)
      }
      setIsLoading(false)
    } catch (e) {
      console.log('b', e)
      logout()
    }
  }, [])

  useEffect(() => {
    if (activeWorkspace) {
      setActiveWorkspaceUser(
        user?.team_users?.find((workspaceUser) => {
          return workspaceUser.team_id === activeWorkspace.id
        }),
      )
    } else {
      setActiveWorkspaceUser(null)
    }
  }, [activeWorkspace])

  const updateUser = (userObj) => {
    setUser(userObj.user)
    localStorage.setItem('sunsett_user', JSON.stringify(userObj.user), {
      sameSite: 'secure',
    })
    if (userObj.access_token) {
      localStorage.setItem('sunsett_token', userObj.access_token, {
        sameSite: 'secure',
      })
    }
  }

  const refreshUser = (id) => {
    api
      .getUser(id || user.id)
      .then((response) => {
        if (response?.id) {
          setUser(response)
          localStorage.setItem('sunsett_user', JSON.stringify(response), {
            sameSite: 'secure',
          })
        } else {
          console.log('d')
        }
      })
      .catch((e) => {
        console.log('c', e)
        if (
          !(
            e.response?.status == '403' &&
            e.response?.data?.message == 'Your email address is not verified.'
          )
        )
          logout()
      })
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem('sunsett_user')
    localStorage.removeItem('sunsett_token')
    Cookies.remove('XSRF-TOKEN')
    window.location.href = '/'
  }

  const handleFetchError = (e) => {
    let text = ''
    if (e.response && e.response.data) {
      if (e.response.data.errors) {
        text = Object.values(e.response.data.errors).join(' ')
      } else if (e.response.data.message) {
        text = e.response.data.message
      } else {
        text = 'Sorry, there was an error! Try again in a few minutes.'
      }
    } else {
      text = 'Network error or server did not respond.'
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 5000,
    })
  }

  const track = (event, data) => {
    api.trackEvent(event, data);
  };

  const value = useMemo(() => {
    return {
      user,
      updateUser,
      logout,
      handleFetchError,
      activeWorkspace,
      setActiveWorkspace,
      refreshUser,
      confirmModal,
      setConfirmModal,
      activeWorkspaceUser,
      track
    }
  }, [user, activeWorkspace, activeWorkspaceUser, confirmModal])

  return isLoading ? (
    <AppLoading />
  ) : (
    <GlobalContext.Provider value={value} {...props}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
