import Select from 'react-select'
import Link from 'next/link'

import { theme } from '../tailwind.config'

const FormSelect = ({
  options,
  onChange,
  classes,
  label,
  value,
  disabled = false,
  isSearchable = false,
  placeholder = 'Select...',
  isMulti = false,
  required = false,
  labelLink,
}) => {
  return (
    <div className={`z-40 mb-5 text-sm ${classes || ''}`}>
      <label>
        {label}
        {labelLink && (
          <Link href={labelLink.route}>
            <a className="ml-2 text-teal-4 underline">{labelLink.text}</a>
          </Link>
        )}
        {required && <span className="text-red-3">*</span>}
      </label>
      <div className="mt-2 relative z-40">
        <Select
          isDisabled={disabled}
          isMulti={isMulti}
          options={options}
          onChange={onChange}
          value={value}
          isSearchable={isSearchable}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          styles={{
            control: (provided) => ({
              ...provided,
              border: `1px solid ${theme.colors.dark[15]}`,
              padding: `2px 0`,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
    </div>
  )
}

export default FormSelect
