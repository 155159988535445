import { Fragment } from 'react'
import Link from 'next/link'

const ButtonWrapper = ({ href, children }) => {
  return href ? (
    <Link href={href}>{children}</Link>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default ButtonWrapper
