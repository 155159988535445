import ReactPaginate from 'react-paginate'

const Pagination = ({ lastPage, pageNumber, handlePageChange }) => {
  return (
    <ReactPaginate
      previousLabel={'previous'}
      previousClassName="uppercase text-xs text-navy-5 mx-3 font-bold"
      nextLabel={'next'}
      nextClassName="uppercase text-xs text-navy-5 mx-3 font-bold"
      breakLabel={'...'}
      breakClassName={'hover:bg-dark-15 mx-3'}
      pageCount={lastPage || 1}
      forcePage={pageNumber - 1}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      onPageChange={handlePageChange}
      containerClassName={'flex my-4 justify-center items-center'}
      activeClassName={'text-white bg-teal-4'}
      pageClassName="hover:bg-dark-15 mx-1 border border-dark-15 rounded-md"
      pageLinkClassName="h-10 w-10 inline-block flex items-center justify-center"
    />
  )
}

export default Pagination
