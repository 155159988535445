import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { NotificationCard } from '.'
import { GlobalContext } from '../context/GlobalContext'
import Link from 'next/link'

import api from '../services/api'

const NotificationsDropdown = ({ isOpen, close }) => {
  const globalContext = useContext(GlobalContext)
  const [didMount, setDidMount] = useState(false)
  const router = useRouter()
  const isInvitationPage =
    router.pathname == '/invitation/[invitationId]/respond'

  useEffect(() => {
    if (!isInvitationPage) {
      globalContext.refreshUser()
    }
    if (!isOpen && didMount) {
      markNewNotificationsAsSeen()
    }
    setDidMount(true)
  }, [isOpen])

  const markNewNotificationsAsSeen = () => {
    const newlySeenNotificationIds = globalContext.user.notifications
      .filter((n) => !n.seen)
      .map((n) => n.id)
    if (newlySeenNotificationIds.length) {
      api
        .markNotificationsAsSeen(newlySeenNotificationIds)
        .then(() => globalContext.refreshUser(globalContext.user.id))
    }
  }

  return (
    <div
      className={`${
        isOpen
          ? 'lg:translate-y-8 opacity-1'
          : 'translate-y-0 opacity-0 pointer-events-none'
      } h-screen lg:h-auto absolute top-0 lg:top-full transition-all transform w-full lg:w-96 shadow-md rounded-md left-0 right-auto lg:left-auto lg:right-0 bg-white overflow-hidden`}>
      <button
        onClick={close}
        className="appearance-none bg-transparent border-0 absolute top-5 right-5 lg:hidden">
        <img src="/close_circle.svg" />
      </button>
      <div className="px-3 py-5 lg:py-3 border-dark-15 border-b flex justify-between items-center pr-16 lg:pr-3">
        <h2 className="text-navy-5 uppercase text-xs">Notifications</h2>
        <Link href="/account?active=notifications">
          <a className="text-teal-4 underline text-sm">Manage</a>
        </Link>
      </div>
      <ul className="flex flex-col border-b-2 border-dark-15">
        {globalContext.user.notifications.length === 0 && (
          <div className="p-6">
            <p className="font-bold text-teal-4">No notifications...</p>
          </div>
        )}
        {globalContext.user.notifications.slice(0, 4).map((notification) => (
          <NotificationCard
            notification={notification}
            key={notification.id}
            close={close}
          />
        ))}
      </ul>
      <div className="py-3 flex justify-center ">
        <Link href="/notifications">
          <a className="text-sm underline text-teal-4" onClick={close}>
            View all
          </a>
        </Link>
      </div>
    </div>
  )
}

export default NotificationsDropdown
