import { useState, useContext, Fragment, useEffect } from 'react'
import Loader from 'react-loader-spinner'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import CloseIcon from '../public/close_circle.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { TextInput, TextArea, FormSelect, FormCheckbox, Icon } from '.'
import { GlobalContext } from '../context/GlobalContext'
import api from '../services/api'
import FormDatePicker from './FormDatePicker'
import Button from './Button'

const GoalForm = ({
  formValues,
  updateFormValue,
  project = false,
  isFirstStep,
  autoRetrievedDates,
  isLoadingPastData,
}) => {

  const router = useRouter()
  const globalContext = useContext(GlobalContext)
  const [newFilter, setNewFilter] = useState({ id: '', value: '' })

  const {
    isLoading: optionsIsLoading,
    error: optionsError,
    data: options,
  } = useQuery(
    ['data-source-options', formValues.dataSourceId],
    () => api.getGoalMetaOptions(formValues.dataSourceId),
    {
      enabled: !!formValues.dataSourceId,
    },
  )

  const {
    isLoading: filterValueOptionsLoading,
    error: filterValuesError,
    data: filterValueOptions,
    refetch: refetchFilterValueOptions,
  } = useQuery(
    [
      'filter-value-options',
      {
        dataSourceId: formValues.dataSourceId,
        categoryId: formValues.goalCategory.id,
        filterId: newFilter.id,
      },
    ],
    () =>
      api.getFilterValueOptions(
        formValues.dataSourceId,
        formValues.goalCategory.value,
        newFilter.id,
        formValues,
        globalContext.activeWorkspace.id,
      ),
    {
      enabled: !!newFilter.id,
    },
  )

  const {
    isLoading: filtersIsLoading,
    error: filtersError,
    data: filters,
  } = useQuery(
    [
      'goal-filters',
      {
        id: `${formValues.dataSource}-${formValues.goalCategory?.value}`,
        gaViewId: formValues.gaView?.id,
        gaPropertyId: formValues.gaProperty?.id,
      },
    ],
    () =>
      api.getGoalFilterOptions(
        formValues.dataSourceId,
        formValues.goalCategory.value,
      ),
    {
      enabled: !!formValues.goalCategory?.value,
    },
  )

  const { 
    isLoading: projectsIsLoading, 
    error: projectsError, 
    data: projects 
  } = useQuery(
    ['projects', router.query?.workspaceId],
    () => api.getProjects(router.query.workspaceId),
    {
      enabled: !!router.query.workspaceId,
    },
  )

  return isFirstStep ? (
    <Fragment>
      <div className="mt-6">
        <div className="flex flex-col sm:flex-row">
          <div className="flex-1 sm:mr-3">
            <FormSelect
              disabled={optionsIsLoading}
              options={
                !!options &&
                options.categoryOptions.map((cat) => ({
                  value: cat.goal_category.id,
                  label: cat.goal_category.name,
                }))
              }
              label="What do you want to set a goal for?"
              onChange={(category) => {
                updateFormValue('goalCategory', category)
              }}
              required
              value={formValues.goalCategory}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row mb-5">
          <div className="flex-1 mb-4 sm:mb-0 sm:mr-2 z-50">
            <FormDatePicker
              value={formValues.startDate}
              onChange={(val) => updateFormValue('startDate', val)}
              label="Start Date"
              onlyFutureDates={!formValues.csv}
            />
          </div>
          <div className="flex-1 mb-4 sm:mb-0 sm:ml-2 z-50">
            <FormDatePicker
              value={formValues.endDate}
              onChange={(val) => updateFormValue('endDate', val)}
              label="End Date"
            />
          </div>
        </div>
        <div className="mt-6">
          {!formValues.csv && (
            <Fragment>
              <div className="flex flex-col md:flex-row items-end">
                <FormSelect
                  classes="w-full sm:w-auto flex-1"
                  disabled={!filters || filtersIsLoading}
                  options={filters?.map((filter) => ({
                    value: filter.filter.id,
                    label: filter.filter.name,
                  }))}
                  label="How would you like to filter the source data?"
                  onChange={(filter) => {
                    setNewFilter({
                      id: filter.value,
                      value: null,
                    })
                  }}
                  value={{
                    label: filters?.find(
                      (filter) => filter.filter.id === newFilter.id,
                    )?.filter.name,
                    value: filters?.find(
                      (filter) => filter.filter.id === newFilter.id,
                    )?.filter.id,
                  }}
                />
                {filterValueOptionsLoading && (
                  <Loader
                    type="TailSpin"
                    height="32"
                    width="32"
                    color="#0b9588"
                    style={{ marginLeft: '20', marginBottom: '20' }}
                  />
                )}

                <FormSelect
                  disabled={!filterValueOptionsLoading && !filterValueOptions}
                  options={filterValueOptions}
                  label="Filter Value"
                  isSearchable
                  onChange={(filter) => {
                    setNewFilter({ ...newFilter, value: filter })
                  }}
                  value={{
                    label: newFilter.value?.label,
                    value: newFilter.id,
                  }}
                  classes="flex-1 md:ml-5 w-full sm:w-auto"
                />
              </div>
              <div className="-mt-2">
                {formValues.goalCategoryDirection.label === 'Retain' && (
                  <p className="text-red-4 text-sm italic mb-3">
                    Warning: Adding filter(s) will recalculate your retain
                    target below!
                  </p>
                )}
                <Button
                  text="Add"
                  size="small"
                  disabled={!newFilter.id || !newFilter.value}
                  IconLeft={() => (
                    <FontAwesomeIcon
                      color="white"
                      icon={faPlus}
                      className="text-white h-3"
                    />
                  )}
                  action={() => {
                    updateFormValue(
                      'goalFilters',
                      formValues.goalFilters.concat([
                        {
                          id: newFilter.id,
                          value: newFilter.value.value,
                          label: newFilter.value.label || newFilter.value.value,
                        },
                      ]),
                    )
                    setNewFilter({})
                  }}
                />
              </div>
            </Fragment>
          )}
          {formValues.goalFilters?.map((goalFilter) => {
            return (
              <button
                key={goalFilter.id}
                className="bg-dark-15 rounded-full text-navy-4 py-1 px-4 m-2 flex items-center"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  updateFormValue(
                    'goalFilters',
                    formValues.goalFilters.filter(
                      (gf) => gf.id != goalFilter.id,
                    ),
                  )
                }}>
                <span className="mr-2">
                  {
                    filters.find((filter) => filter.filter.id === goalFilter.id)
                      .filter.name
                  }{' '}
                  = {goalFilter.label}
                </span>
                <Icon icon={(props) => <CloseIcon {...props} />} size="small" />
              </button>
            )
          })}
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center mt-5">
          <div className={`flex-1 sm:mr-3`}>
            <FormSelect
              disabled={!formValues.goalCategory?.value}
              options={
                !!options?.categoryOptions &&
                options.categoryOptions
                  ?.find(
                    (o) => o.goal_category.id === formValues.goalCategory.value,
                  )
                  ?.goal_category.goal_category_directions.map((direction) => ({
                    id: direction.id,
                    value: direction.direction.id,
                    label: direction.direction.name,
                  }))
              }
              label="My goal is to:"
              required
              onChange={(goalCategoryDirection) => {
                updateFormValue('goalCategoryDirection', goalCategoryDirection)
              }}
              value={formValues.goalCategoryDirection}
            />
          </div>
          <div className={`flex-1`}>
            {!!options?.categoryOptions
              ?.find(
                (o) => o.goal_category.id === formValues.goalCategory.value,
              )
              ?.goal_category.goal_category_directions.find(
                (dir) => dir.direction.id === formValues.goalCategoryDirection?.value,
              )?.value_types.length && (
              <Fragment>
                <label className="text-sm text-navy-4">
                  By how much?<span className="text-red-3">*</span>
                </label>
                <div className="flex flex-1 items-center">
                  <FormSelect
                    disabled={!formValues.goalCategoryDirection}
                    placeholder=""
                    options={
                      !!options?.categoryOptions &&
                      options.categoryOptions
                        ?.find(
                          (o) =>
                            o.goal_category.id ===
                            formValues.goalCategory.value,
                        )
                        ?.goal_category.goal_category_directions.find(
                          (dir) =>
                            dir.direction.id === formValues.goalCategoryDirection.value,
                        )
                        ?.value_types.map((valueType) => ({
                          value: valueType.value_type.id,
                          label: valueType.value_type.name,
                        }))
                    }
                    onChange={(goalCategoryValueType) => {
                      updateFormValue(
                        'goalCategoryValueType',
                        goalCategoryValueType,
                      )
                    }}
                    value={formValues.goalCategoryValueType}
                    classes="flex-1 mr-3"
                  />
                  <div className="flex-1 flex flex-col">
                    <TextInput
                      required
                      label=""
                      placeholder=""
                      value={formValues.goalCategoryValue}
                      onChange={(e) => {
                        updateFormValue('goalCategoryValue', e.target.value)
                      }}
                      className="flex-1"
                      type="number"
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        {isLoadingPastData ? (
          <Loader
            type="TailSpin"
            height="32"
            width="32"
            color="#0b9588"
            style={{ marginLeft: '20', marginBottom: '20' }}
          />
        ) : (
          formValues.targetValue != null && (
            <p className="text-teal-4 text-sm italic">
              TARGET: {formValues.targetValue} (This value is retrieved
              automatically for the period of {autoRetrievedDates.start} -{' '}
              {autoRetrievedDates.end})
            </p>
          )
        )}
        {/* <div className="flex">
          <div className="flex-1 text-xs -mt-3 sm:mt-0 font-light">
            To reach your goal, we recommend you set weekly goal of{' '}
            <span className="font-bold">X</span> to reach your end goal of{' '}
            <span className="font-bold">X</span>.
          </div>
        </div> */}
        {/* <div className="mt-6">
          <h2 className="font-bold text-sm">
            How would you like to set up benchmarks? (Select all that apply)
            <span className="text-red-3">*</span>
          </h2>
          <div className="flex">
            {!!options &&
              options.benchmarkOptions.map((b) => (
                <div className="mr-3 mt-1" key={b.id}>
                  <FormCheckbox
                    checked={formValues.goalBenchmarkIds.includes(b.id)}
                    label={b.name}
                    onChange={() => {
                      updateFormValue(
                        'goalBenchmarkIds',
                        formValues.goalBenchmarkIds.includes(b.id)
                          ? formValues.goalBenchmarkIds.filter(
                              (gb) => gb != b.id,
                            )
                          : formValues.goalBenchmarkIds.concat([b.id]),
                      )
                    }}
                  />
                </div>
              ))}
          </div>
        </div> */}
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <TextInput
        required
        label="Goal Name"
        placeholder=""
        value={formValues.name}
        onChange={(e) => updateFormValue('name', e.target.value)}
        classes="w-full"
      />
      <TextArea
        label="Description"
        placeholder="This is where you can put important details about your goal."
        value={formValues.description}
        onChange={(e) => updateFormValue('description', e.target.value)}
      />
      {project && project.id ? (
        <div className="mt-6">
          <TextInput
            value={project.name}
            disabled
            label="What project is this for?"
          />
        </div>
      ) : (
        <div className="mt-6">
          <FormSelect
            disabled={projectsIsLoading}
            options={
              [{ value: '', label: 'None' }].concat(
                projects.filter((p) => !p.is_archived).map((cat) => ({
                  value: cat.id,
                  label: cat.name,
                })),
              )
            }
            label="Would you like to connect this goal to a current project?"
            labelLink={{
              text: 'View Your Projects',
              route: `/workspaces/${globalContext.activeWorkspace.id}/projects`,
            }}
            onChange={(project) => {
              updateFormValue('project', project)
            }}
            value={formValues.project}
          />
        </div>
      )}
    </Fragment>
  )
}

export default GoalForm
