import { useState, useContext, Fragment } from 'react'
import Loader from 'react-loader-spinner'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

import { FormCheckbox, FormSelect, Icon } from '.'
import api from '../services/api'
import Button from './Button'
import ArrowRightIcon from '../public/arrow_right.svg'
import { isWorkspaceAdmin } from '../utils'
import { GlobalContext } from '../context/GlobalContext'

const DataSourceSelection = ({
  title,
  formValues,
  updateFormValue,
  dataSourceOptions,
  isLoading,
}) => {
  const router = useRouter()
  const globalContext = useContext(GlobalContext)
  const {
    isLoading: gaPropertiesLoading,
    error: gaPropertiesError,
    data: gaProperties,
  } = useQuery(
    ['ga_properties', router.query?.workspaceId],
    () => api.getGaProperties(router.query.workspaceId),
    {
      enabled:
        !!router.query?.workspaceId &&
        dataSourceOptions?.available?.find(
          (opt) => opt.name === 'Google Analytics',
        )?.id === formValues.dataSourceId,
    },
  )

  const {
    isLoading: csvListLoading,
    error: csvListError,
    data: csvList,
  } = useQuery(
    ['csv_list', { workspaceId: router.query?.workspaceId }],
    () => api.getCsvList(router.query.workspaceId, { all: true }),
    {
      enabled:
        !!router.query?.workspaceId &&
        dataSourceOptions?.available?.find((opt) => opt.name === 'CSV')?.id ===
          formValues.dataSourceId,
    },
  )

  return (
    <div>
      <h2 className="text-sm font-bold">{title}</h2>
      {isLoading ? (
        <div className="w-full py-12 flex justify-center items-center">
          <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
        </div>
      ) : (
        <div>
          <div className="flex flex-wrap justify-between items-center -ml-2 mt-5">
            {dataSourceOptions?.connected.filter((dataSourceOption) => {
              return (
                dataSourceOption.name != 'CSV' || dataSourceOption.fileCount > 0
              )
            }).length === 0 && (
              <div className="flex flex-col w-full items-center justify-center mt-3">
                <h4 className="font-serif text-lg">
                  No data sources to choose from
                </h4>
                <div className="mt-3">
                    <Button
                      href={`/workspaces/${router.query?.workspaceId}/data`}
                      text="Connect One"
                      size="small"
                      theme="light-teal"
                      IconRight={() => <Icon icon={ArrowRightIcon} />}
                    />
                </div>
              </div>
            )}
            {dataSourceOptions?.connected
              .filter((dataSourceOption) => {
                return (
                  (dataSourceOption.name != 'CSV' ||
                    dataSourceOption.fileCount > 0) &&
                  !dataSourceOption.pivot?.needs_reconnect
                )
              })
              .map((dataSourceOption) => {
                const isSelected =
                  dataSourceOption.id == formValues.dataSourceId
                return (
                  <div
                    className={`w-full sm:w-1/2 px-2 mb-4 `}
                    key={dataSourceOption.id}>
                    <button
                      className={`border  rounded-lg p-5 w-full flex items-center justify-between transition-all duration-300 ${
                        isSelected ? 'border-teal-4' : 'border-dark-15'
                      }`}
                      onClick={(e) => {
                        e.preventDefault()
                        updateFormValue(
                          'dataSourceId',
                          isSelected ? '' : dataSourceOption.id,
                        )
                      }}>
                      <div className="flex items-center">
                        {dataSourceOption.name === 'CSV' ? (
                          <FontAwesomeIcon
                            icon={faFileCsv}
                            className="h-14 mr-4 text-teal-4 text-5xl"
                          />
                        ) : (
                          <img
                            className="w-14 h-14 rounded-full mr-4 object-contain"
                            src={dataSourceOption.image}
                          />
                        )}
                        <span className="font-light">
                          {dataSourceOption.name}
                        </span>
                      </div>
                      <FormCheckbox
                        checked={isSelected}
                        onChange={() => null}
                        radioStyle
                      />
                    </button>
                  </div>
                )
              })}
          </div>
          {dataSourceOptions?.available?.find((opt) => opt.name === 'CSV')
            ?.id === formValues.dataSourceId && (
            <div className="py-4">
              {gaPropertiesLoading ? (
                <div className="w-full flex justify-center items-center">
                  <Loader
                    type="TailSpin"
                    height="36"
                    width="36"
                    color="#0b9588"
                  />
                </div>
              ) : (
                <div>
                  <FormSelect
                    options={csvList?.map((csv) => ({
                      value: csv,
                      label: csv.name,
                    }))}
                    isSearchable
                    label="Select a CSV"
                    onChange={(option) => {
                      updateFormValue('csv', option.value)
                    }}
                    value={{
                      value: formValues.csv,
                      label: formValues.csv?.name,
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {dataSourceOptions?.available?.find(
            (opt) => opt.name === 'Google Analytics',
          )?.id === formValues.dataSourceId && (
            <div className="py-4">
              {gaPropertiesLoading ? (
                <div className="w-full flex justify-center items-center">
                  <Loader
                    type="TailSpin"
                    height="36"
                    width="36"
                    color="#0b9588"
                  />
                </div>
              ) : !!gaProperties && gaProperties.length > 0 ? (
                <div>
                  <FormSelect
                    options={gaProperties.map((prop) => ({
                      value: prop,
                      label: prop.name,
                    }))}
                    label="Select a GA property"
                    onChange={(option) => {
                      updateFormValue('gaProperty', option.value, 'gaView', {})
                    }}
                    value={{
                      value: formValues.gaProperty,
                      label: formValues.gaProperty?.name,
                    }}
                  />
                  <FormSelect
                    options={gaProperties
                      .find((prop) => prop.id === formValues.gaProperty.id)
                      ?.views.map((view) => ({
                        label: view.name,
                        value: view,
                      }))}
                    label="Select a GA view or data stream"
                    onChange={(option) => {
                      updateFormValue('gaView', option.value)
                    }}
                    value={{
                      value: formValues.gaView,
                      label: formValues.gaView.name,
                    }}
                    disabled={
                      !formValues.gaProperty?.id ||
                      (formValues.gaProperty.id &&
                        formValues.gaProperty.id.indexOf('UA-') < 0)
                    }
                  />
                </div>
              ) : (
                <div>No GA Properties found for your Google account.</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DataSourceSelection
