import { useState, useContext } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from 'react-query'
import Loader from 'react-loader-spinner'

import {
  FormSelect,
  TextInput,
  Button,
  Icon,
  FileInput,
  Avatar,
  FormCheckbox,
} from '.'
import { GlobalContext } from '../context/GlobalContext'
import { orgOptions } from '../utils/content'
import api from '../services/api'
import ArrowRightIcon from '../public/arrow_right.svg'
import CloseIcon from '../public/close_circle.svg'
import timezones from '../utils/timezones'

const CreateWorkspaceForm = ({ workspace }) => {
  const router = useRouter()
  const globalContext = useContext(GlobalContext)
  const [localWorkspace, setLocalWorkspace] = useState({
    name: '',
    description: '',
    size: '',
    focus: '',
    image: '',
    members_can_create_goals: false,
    timezone: 'America/New_York',
  })

  const mutation = useMutation(() => api.createWorkspace(localWorkspace), {
    onSuccess: (response) => {
      router.push(`/workspaces`)
    },
  })

  return (
    <div>
      <div className="flex flex-col sm:flex-row mb-5 items-center">
        <Avatar image={localWorkspace?.image} type="team" />
        <div className="flex-1 flex-col flex justify-center">
          <div className="flex flex-col sm:flex-row items-center">
            <FileInput
              onUpdate={(imageString) =>
                setLocalWorkspace({ ...localWorkspace, image: imageString })
              }
              fileLabel="Workspace Icon"
            />
            {localWorkspace?.image && (
              <Button
                action={() =>
                  setLocalWorkspace({ ...localWorkspace, image: '' })
                }
                text="REMOVE"
                size="small"
                theme="red-transparent"
                IconLeft={() => (
                  <Icon
                    icon={(props) => <CloseIcon {...props} />}
                    size="small"
                    fill="#de4d15"
                  />
                )}
              />
            )}
          </div>
          <div className="text-dark-50 mt-4">
            Upload a photo for your profile (JPG, JPEG, PNG only; 1 MB Maximum File Size).
          </div>
        </div>
      </div>
      <TextInput
        label="Workspace Name"
        placeholder="Acme, Co."
        value={localWorkspace.name}
        onChange={(e) =>
          setLocalWorkspace({
            ...localWorkspace,
            name: e.target.value,
          })
        }
      />
      <FormSelect
        options={orgOptions.description}
        label="Which of the following describes your team best?"
        onChange={(option) => {
          setLocalWorkspace({
            ...localWorkspace,
            description: option,
          })
        }}
        value={localWorkspace.description}
      />
      <FormSelect
        options={orgOptions.size}
        label="What is your team size?"
        onChange={(option) => {
          setLocalWorkspace({
            ...localWorkspace,
            size: option,
          })
        }}
        value={localWorkspace.size}
      />
      <FormSelect
        options={orgOptions.focus}
        label="What would you like to manage?"
        onChange={(option) => {
          setLocalWorkspace({
            ...localWorkspace,
            focus: option,
          })
        }}
        value={localWorkspace.focus}
      />

      <FormSelect
        options={timezones.map((timezone) => ({
          value: timezone,
          label: timezone,
        }))}
        isSearchable
        label="Workspace Timezone"
        onChange={(timezone) => {
          setLocalWorkspace({
            ...localWorkspace,
            timezone: timezone.value,
          })
        }}
        value={{
          value: localWorkspace.timezone,
          label: localWorkspace.timezone,
        }}
      />
      <div>
        <FormCheckbox
          checked={localWorkspace.members_can_create_goals}
          label="Allow non-admins to create goals?"
          classes="my-10"
          onChange={() =>
            setLocalWorkspace({
              ...localWorkspace,
              members_can_create_goals:
                !localWorkspace.members_can_create_goals,
            })
          }
        />
      </div>
      <div className="flex justify-end">
        <Button
          text="Create Workspace"
          type="submit"
          showSpinner={true}
          disabled={
            !localWorkspace.name ||
            !localWorkspace.focus ||
            !localWorkspace.size
          }
          action={mutation.mutate}
          IconRight={() => <Icon icon={ArrowRightIcon} fill="white" />}
        />
      </div>
      {mutation.isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50 z-50">
          <Loader type="TailSpin" height="72" width="72" color="white" />
        </div>
      )}
    </div>
  )
}

export default CreateWorkspaceForm
