import { Fragment } from 'react'
import Link from 'next/link'

import { ButtonWrapper } from '.'

const getPaddingClass = (size) => {
  switch (size) {
    case 'x-small':
      return 'py-1 px-3 text-xs'
      break
    case 'small':
      return 'py-1 px-6'
      break
    default:
      return 'px-8 py-3'
  }
}

const getColorClasses = (theme) => {
  switch (theme) {
    case 'ghost':
      return 'border border-dark-50 text-dark-50 bg-transparent'
    case 'red':
      return 'border border-red-3 text-red-3 bg-transparent'
    case 'red-transparent':
      return 'border-0 text-red-3 bg-transparent px-0'
    case 'dark-transparent':
      return 'border-0 text-navy-5 bg-transparent px-0'
    case 'yellow':
      return 'border-0 text-navy-5 bg-yellow-3 hover:bg-yellow-2'
    case 'light-teal':
      return 'border-0 text-navy-5 bg-teal-1 hover:bg-teal-2'
    default:
      return 'bg-navy-5 hover:bg-navy-3 text-white'
  }
}

const Button = ({
  text,
  action,
  color = 'white',
  style,
  size,
  href,
  disabled,
  type,
  theme,
  classes,
  IconLeft,
  IconRight,
  dataTip = '',
}) => {
  return (
    <ButtonWrapper href={href}>
      <button
        onClick={action ? action : () => null}
        data-tip={dataTip}
        className={`transition-all duration-300 rounded-full flex items-center text-center opacity-${
          disabled ? '50' : '1'
        } text-${color} ${getPaddingClass(size)} ${getColorClasses(
          theme,
        )} cursor-${disabled ? 'default' : 'pointer'} ${classes}`}
        disabled={disabled}
        type={type}
        style={style}>
        {IconLeft && (
          <div className="mr-1">
            <IconLeft />
          </div>
        )}
        {text}
        {IconRight && (
          <div className="ml-2">
            <IconRight />
          </div>
        )}
      </button>
    </ButtonWrapper>
  )
}

export default Button
