import Link from 'next/link'

import { ProgressBar } from '.'
import Icon from './Icon'
import TargetIcon from '../public/target.svg'
import ClockIcon from '../public/clock.svg'

import { theme } from '../tailwind.config'
import { TagPill } from '../components'
import { getRelativeWeeksText, truncateString } from '../utils'
import { Fragment } from 'react'

const ProjectCard = ({ includeFooter = false, project, classes = '' }) => {
  return (
    <Link href={`/workspaces/${project.team_id}/projects/${project.id}`}>
      <button className={`flex flex-col ${classes}`}>
        <div className="p-5 flex flex-col justify-between w-full">
          <div className="flex">
            {project.image ? (
              <img
                className={`${
                  includeFooter ? 'w-24 h-24' : 'w-16 h-16'
                } object-cover rounded-full`}
                src={project.image}
              />
            ) : (
              <div
                className={`${
                  includeFooter ? 'w-24 h-24' : 'w-16 h-16'
                } flex items-center justify-center bg-dark-5 rounded-lg`}>
                <img
                  className={`${
                    includeFooter ? 'w-16 h-16' : 'w-10 h-10'
                  } object-contain`}
                  src="/settings_avatar_default.png"
                />
              </div>
            )}
            <div className="ml-4 flex-1 text-left flex justify-center flex-col">
              <h2 className="text-navy-5 font-medium">{project.name}</h2>
              {project.description && (
                <p className="text-xs text-dark-50 font-light mt-1">
                  {truncateString(project.description)}
                </p>
              )}
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-dark-50 text-xxs uppercase">Progress:</h3>
              <div>{project.progress}%</div>
            </div>
            <ProgressBar
              percentComplete={project.progress}
            />
          </div>
        </div>
        {includeFooter && (
          <div className="p-4 border-t border-dark-15 flex justify-between items-center w-full">
            <div className="flex items-center">
              {!!project.goals?.length && (
                <Fragment>
                  <Icon icon={TargetIcon} fill={theme.colors.dark['50']} />
                  <span className="text-dark-50 ml-1 uppercase text-xxs">
                    {project.goals.length} Goals
                  </span>
                </Fragment>
              )}
            </div>
            <TagPill>
              <Icon
                icon={ClockIcon}
                fill={theme.colors.navy[5]}
                classes="mr-0.5"
              />
              <span className="text-navy-5 uppercase ml-1 text-xxs">
                {getRelativeWeeksText(project.end_date)}
              </span>
            </TagPill>
          </div>
        )}
      </button>
    </Link>
  )
}

export default ProjectCard
