import { useEffect, useState, Fragment } from 'react'
import { format, isAfter, isBefore } from 'date-fns'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { getStatusTextClass, statusLabels, tzDate } from '../utils'
import {
  LineChart,
  CardContainer,
  CardBody,
  ProgressCard,
  PieChart,
  FormSelect,
  DateRangePicker,
  ReportTools,
} from '.'
import BarChart from './BarChart'
import CardHeader from './CardHeader'

const CategoryReport = ({ category, updateFilters, filters }) => {
  const router = useRouter()
  const [activeChartType, setActiveChartType] = useState('line')
  const [goalSort, setGoalSort] = useState('title-asc')
  const [activeListingType, setActiveListingType] = useState('goals')
  const [pieChartData, setPieChartData] = useState({
    ontrack: [],
    behind: [],
    ahead: [],
  })

  const updateActiveListingType = (newActiveListingType = 'projects') => {
    const statuses = {
      ontrack: [],
      behind: [],
      ahead: [],
    }

    if (newActiveListingType === 'goals') {
      category.goals.forEach((goal) => {
        statuses[goal.last_status ? goal.last_status.status : 'ontrack'].push(
          goal,
        )
      })
    } else {
      category.projects.forEach((project) => {
        statuses[
          project.last_status ? project.last_status.status : 'ontrack'
        ].push(project)
      })
    }

    setActiveListingType(newActiveListingType)
    setPieChartData(statuses)
  }

  useEffect(() => {
    updateActiveListingType()
  }, [])

  const getAnnotationLines = (category) => {
    const annotationLines = []
    category.goals.forEach((goal) => {
      if (isBefore(new tzDate(goal.end_date), new tzDate(filters.end_date))) {
        annotationLines.push({
          x: format(new tzDate(goal.end_date), 'MMM d'),
          label: `End of goal: ${goal.name}`.toUpperCase(),
          strokeColor: '#fa8050',
        })
      }

      if (isAfter(new tzDate(goal.start_date), new tzDate(filters.start_date))) {
        annotationLines.push({
          x: format(new tzDate(goal.start_date), 'MMM d'),
          label: `Start of goal: ${goal.name}`.toUpperCase(),
          strokeColor: '#0b9588',
        })
      }
    })
    return annotationLines
  }

  return (
    <Fragment>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col flex-1">
          <div className="flex flex-row flex-wrap hidden-pdf">
            <div className="w-50 max-w-full mt-3 mb-3">
              <label className="text-sm">Dates</label>
              <div
                className="mt-1 border bg-white flex flex-col justify-center items-start"
                style={{
                  borderRadius: 4,
                  height: 42,
                  borderColor: 'rgba(41, 50, 65, 0.15)',
                  marginTop: 5,
                }}>
                <DateRangePicker
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  updateFilters={updateFilters}
                  classes="text-sm flex-1 px-3"
                />
              </div>
            </div>
            {category?.category.daily_data ? (
              <ReportTools
                title="Categories Report"
                type="Category"
                download={router.query?.download}
                data={category}
                filters={{ ...filters, goalCategory: category.category.id }}
              />
            ) : null}
          </div>
          <div className="flex flex-row flex-wrap items-stretch">
            <div className="flex items-stretch w-full md:w-2/3 md:pr-5">
              <CardContainer classes="w-full" hasHeader>
                <div className="flex items-center px-6 pt-6">
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold">
                      {category.category.name}
                    </span>
                  </div>
                </div>
                {category.category.daily_data.length === 0 && (
                  <div className="font-serif py-24 md:py-40 px-6 text-center">
                    {category.category.data_collection_started
                      ? 'No data for this period.'
                      : 'Data updates occur at midnight, please return tomorrow for your first report on data performance.'}
                  </div>
                )}
                {category.category.daily_data && !!category.category.daily_data.length && (
                  <Fragment>
                    {activeChartType === 'line' ? (
                      <LineChart
                        lines={category.category.daily_data.map((d) => ({
                          x: format(new tzDate(d.date), 'MMM d'),
                          y: parseFloat(d.cumulative_data, 10),
                        }))}
                        annotationLines={getAnnotationLines(category)}
                      />
                    ) : (
                      <BarChart
                        data={category.category.daily_data.map((d) => ({
                          // label: format(new Date(d.created_at), 'MMM d'),
                          day: new tzDate(d.date),
                          x: parseFloat(d.cumulative_data, 10),
                        }))}
                      />
                    )}
                  </Fragment>
                )}
              </CardContainer>
            </div>
            <div className="flex flex-col w-full md:w-1/3 mt-5 md:mt-0">
              <CardContainer classes="w-full" hasHeader>
                <div className="p-6 border-b border-dark-15">
                  <div className="text-2xl text-navy-4  font-bold">
                  {category.category.start_display}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Starting Value
                  </div>
                </div>
                <div className="p-6 border-b border-dark-15">
                  <div className="text-2xl text-navy-4  font-bold">
                    {category.category.end_display}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    {category.category.average ? 'Rolling Average' : 'Current Value'}
                  </div>
                </div>
              </CardContainer>
              <CardContainer classes="mt-6">
                <div className="flex items-center">
                  {category.data_source?.name === 'CSV' ? (
                    <FontAwesomeIcon
                      icon={faFileCsv}
                      className="h-10 mr-4 text-teal-4"
                    />
                  ) : (
                    <img
                      className="w-10 h-10 rounded-lg mr-4  object-contain"
                      src={category.category?.data_source[0].image}
                    />
                  )}
                  <div className="flex flex-col">
                    <h3 className="text-xl">
                      {category.category.data_source[0].name}
                    </h3>
                    {category.gaView?.property_name && (
                      <span className="text-sm text-dark-50 uppercase">
                        {`${category.gaView.property_name}${
                          category.gaView?.view_name
                            ? ` - ${category.gaView?.view_name}`
                            : ''
                        }`}
                      </span>
                    )}
                  </div>
                </div>
              </CardContainer>
            </div>
          </div>
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col w-full md:w-2/3 md:pr-5">
              <CardContainer
                classes="w-full"
                hasHeader
                classes="mt-5 overflow-hidden">
                <div className="p-6 pb-0">
                  <div className="rounded-full border border-dark-15 flex justify-start w-32">
                    <button
                      className={`flex justify-center p-2 border-r border-dark-15 text-sm pl-3 ${
                        activeListingType === 'projects'
                          ? 'text-navy-4'
                          : 'text-dark-15'
                      }`}
                      onClick={() => updateActiveListingType('projects')}>
                      Projects
                    </button>
                    <button
                      className={`flex justify-center p-2 text-sm ${
                        activeListingType === 'goals'
                          ? 'text-navy-4'
                          : 'text-dark-15'
                      }`}
                      onClick={() => updateActiveListingType('goals')}>
                      Goals
                    </button>
                  </div>
                </div>
                <div className="p-6 flex">
                  <div className="flex-1 flex items-center">
                    <button
                      className="md:ml-5 text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setGoalSort(
                          goalSort === 'title-asc' ? 'title-desc' : 'title-asc',
                        )
                      }>
                      Title
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            goalSort === 'title-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            goalSort === 'title-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="flex-1 md:px-8"></div>
                  <div className="flex-1 md:px-8"></div>
                  <div className="flex-1 md:px-8">
                    <button
                      className="text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setGoalSort(
                          goalSort === 'progress-asc'
                            ? 'progress-desc'
                            : 'progress-asc',
                        )
                      }>
                      completion rate
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            goalSort === 'progress-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            goalSort === 'progress-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                </div>
                <CardBody disablePadding>
                  {activeListingType === 'goals' && (
                    <Fragment>
                      {category?.goals?.length == 0 && (
                        <div className="py-12 px-3 border-t border-dark-15 text-center font-serif">
                          No goals to display!
                        </div>
                      )}
                      {category?.goals
                        ?.sort((a, b) => {
                          switch (goalSort) {
                            case 'title-asc':
                              return a.name < b.name
                                ? -1
                                : a.name > b.name
                                ? 1
                                : 0
                            case 'progress-asc':
                              return a.progress - b.progress
                            case 'progress-desc':
                              return b.progress - a.progress
                            case 'title-desc':
                            default:
                              return b.name < a.name
                                ? -1
                                : b.name > a.name
                                ? 1
                                : 0
                          }
                        })
                        .map((goal) => {
                          return (
                            <ProgressCard
                              data={goal}
                              key={goal.id}
                              classes="border-t border-dark-15 w-full"
                              startDate={format(
                                new tzDate(goal.start_date),
                                'MMM d, yyyy',
                              )}
                              endDate={format(
                                new tzDate(goal.end_date),
                                'MMM d, yyyy',
                              )}
                              disableContextualMenu
                            />
                          )
                        })}
                    </Fragment>
                  )}
                  {activeListingType === 'projects' && (
                    <Fragment>
                      {category?.projects?.length == 0 && (
                        <div className="py-12 px-3 border-t border-dark-15 text-center font-serif">
                          No projects to display
                        </div>
                      )}
                      {category?.projects
                        .sort((a, b) => {
                          switch (goalSort) {
                            case 'title-asc':
                              return a.name < b.name
                                ? -1
                                : a.name > b.name
                                ? 1
                                : 0
                            case 'progress-asc':
                              return a.progress - b.progress
                            case 'progress-desc':
                              return b.progress - a.progress
                            case 'title-desc':
                            default:
                              return b.name < a.name
                                ? -1
                                : b.name > a.name
                                ? 1
                                : 0
                          }
                        })
                        .map((project) => {
                          return (
                            <ProgressCard
                              data={project}
                              key={project.id}
                              classes="border-t border-dark-15 w-full"
                              startDate={format(
                                new tzDate(project.start_date),
                                'MMM d, yyyy',
                              )}
                              endDate={format(
                                new tzDate(project.end_date),
                                'MMM d, yyyy',
                              )}
                              isProject
                              disableContextualMenu
                            />
                          )
                        })}
                    </Fragment>
                  )}
                </CardBody>
              </CardContainer>
            </div>
            <div className="flex flex-col w-full md:w-1/3 mt-5 md:mt-0">
              <CardContainer classes="w-full" classes="mt-5 overflow-hidden">
                <h2 className="text-sm text-dark-50 uppercase">Success Rate</h2>
                <Fragment>
                  {Object.keys(pieChartData)
                    .map((key) => {
                      return {
                        x: statusLabels[key],
                        y: pieChartData[key].length,
                      }
                    })
                    .filter((d) => d.y !== 0).length == 0 && (
                    <div className="py-20 px-3 text-center font-serif">
                      No data to display!
                    </div>
                  )}
                  <PieChart
                    data={Object.keys(pieChartData)
                      .map((key) => {
                        return {
                          x: statusLabels[key],
                          y: pieChartData[key].length,
                        }
                      })
                      .filter((d) => d.y !== 0)}
                  />
                </Fragment>
              </CardContainer>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col md:w-2/3 md:pr-5">
              {category.category?.project && (
                <CardContainer
                  classes="w-full"
                  hasHeader
                  classes="mt-5 overflow-hidden">
                  <CardHeader title="Project" disabledButton />
                  <CardBody disablePadding>
                    {!category?.project && (
                      <div className="py-12 px-3 border-t border-dark-15 text-center font-serif">
                        This goal is not connected to a project.
                      </div>
                    )}
                    {category?.project && (
                      <ProgressCard
                        data={data.project}
                        isProject
                        key={data.project.id}
                        classes="border-t border-dark-15 w-full"
                        startDate={format(
                          new tzDate(data.project.start_date),
                          'MMM d, yyyy',
                        )}
                        endDate={format(
                          new tzDate(data.project.end_date),
                          'MMM d, yyyy',
                        )}
                        disableContextualMenu
                      />
                    )}
                  </CardBody>
                </CardContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CategoryReport
