import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'

import { Avatar, Button, Icon } from '.'
import ContextualMenu from './ContextualMenu'
import TrashIcon from '../public/trash.svg'
import EnvelopeIcon from '../public/envelope.svg'
import { theme } from '../tailwind.config'
import api from '../services/api'
import { WorkspaceSettingsContext } from '../context/WorkspaceSettingsContext'

const WorkspaceUserCard = ({
  workspaceUser,
  classes,
  isAdmin,
  isInvitation,
  refetchWorkspace,
}) => {
  const { addToast } = useToasts()
  const workspaceSettingsContext = useContext(WorkspaceSettingsContext)

  const getDisplayName = (workspaceUser) => {
    if (workspaceUser.user) {
      return `${workspaceUser.user.first_name} ${workspaceUser.user.last_name}`
    } else if (workspaceUser.first_name) {
      return `${workspaceUser.first_name} ${workspaceUser.last_name}`
    }
    return workspaceUser.name
  }

  let menuOptions = [
    {
      text: isInvitation ? 'Revoke Invitation' : 'Remove Member',
      textClass: 'text-red-3',
      Icon: () => <Icon icon={TrashIcon} fill={theme.colors.red[4]} />,
      action: (e) => {
        e.preventDefault()
        remove()
      },
    },
  ]

  if (isInvitation) {
    menuOptions = [
      {
        text: 'Resend Invitation',
        Icon: () => <Icon icon={EnvelopeIcon} fill={theme.colors.navy[5]} />,
        action: (e) => {
          e.preventDefault()
          resendInvite()
        },
      },
    ].concat(menuOptions)
  }

  const remove = () => {
    if (isInvitation) {
      api.revokeInvitation(workspaceUser.id).then((response) => {
        if (refetchWorkspace) {
          refetchWorkspace()
        } else {
          workspaceSettingsContext.refetchWorkspace()
        }
        addToast('Invitation revoked successfully!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        })
      })
    } else {
      api.removeWorkspaceUser(workspaceUser).then((response) => {
        if (refetchWorkspace) {
          refetchWorkspace()
        } else {
          workspaceSettingsContext.refetchWorkspace()
        }
        addToast('Workspace member removed successfully!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        })
      })
    }
  }

  const resendInvite = () => {
    api
      .sendInvitations({
        invitations: [
          {
            name: `${workspaceUser.first_name} ${workspaceUser.last_name}`,
            email: workspaceUser.email,
          },
        ],
        workspaceId: workspaceUser.team_id,
      })
      .then((response) => {
        addToast('Resent invitation successfully!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        })
      })
  }

  return (
    <div
      className={`flex flex-col sm:flex-row sm:justify-between py-4 px-2 w-full relative ${classes}`}>
      <div className="flex items-center">
        <Avatar image={workspaceUser.user?.image} type="user" size="small" />
        <div className="flex flex-col">
          <div>
            <span className="font-bold">{getDisplayName(workspaceUser)}</span>
            <span className="font-light text-sm">{`${
              workspaceUser.user?.title ? `, ${workspaceUser.user.title}` : ''
            }`}</span>
          </div>
          <div className="text-dark-50 text-xs font-bold">
            {workspaceUser.user?.email || workspaceUser.email}
          </div>
        </div>
      </div>
      {!isAdmin && (
        <div className="flex mt-5 sm:mt-0">
          <ContextualMenu classes="top-5 right-0" items={menuOptions} />
        </div>
      )}
    </div>
  )
}

export default WorkspaceUserCard
