import Link from 'next/link'
import { useContext, useState, useEffect, Fragment } from 'react'
import { useRouter } from 'next/router'

import { nav } from '../utils/content'
import { GlobalContext } from '../context/GlobalContext'
import { Icon, NavItem, Avatar, NotificationsDropdown } from '../components'
import { isWorkspaceAdmin } from '../utils'
import Logout from '../public/logout.svg'
import People from '../public/people-icon.svg'
import BellIcon from '../public/bell.svg'
import Briefcase from '../public/briefcase.svg'

const AppNavigation = () => {
  const globalContext = useContext(GlobalContext)
  const router = useRouter()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsNavOpen(false))
  }, [])

  const isCurrentItem = (itemRoute) => {
    if (!router.pathname.includes(itemRoute)) {
      return false
    }
    const urlSegments = router.pathname.split('/')
    return (
      urlSegments.length &&
      (urlSegments[urlSegments.length - 1] == itemRoute.substr(1) ||
        (urlSegments.length > 1 &&
          urlSegments[urlSegments.length - 2] == itemRoute.substr(1)))
    )
  }

  return (
    <Fragment>
      <div
        className={`fixed top-0 left-0 h-screen w-screen bg-dark-15 z-50 hidden-pdf transition-opacity ${isNavOpen || isNotificationsOpen
          ? 'opacity-1'
          : 'opacity-0 pointer-events-none'
          }`}
        onClick={() => {
          setIsNavOpen(false)
          setIsNotificationsOpen(false)
        }}
      />
      <div className="bg-white px-3 md:px-6 border-b-2 border-dark-15 z-100 hidden-pdf">
        <div className="container mx-auto py-3 lg:py-5 flex items-center justify-between">
          <Link
            href={
              globalContext.activeWorkspace?.id &&
                router.route.indexOf('/dashboard') < 0
                ? `/workspaces/${globalContext.activeWorkspace?.id}/dashboard`
                : '/workspaces'
            }>
            <a className="flex items-center">
              <img src="/sunsett-final-logo.png" className="w-20 self-center" />
            </a>
          </Link>
          <nav className="flex-1 mx-3 justify-center hidden lg:flex">
            {globalContext.activeWorkspace?.id && (
              <ul className="flex flex-col lg:flex-row">
                {nav.items
                  .filter((item) => {
                    return (
                      !item.adminOnly ||
                      isWorkspaceAdmin(globalContext.activeWorkspaceUser)
                    )
                  })
                  .map((item, index) => {
                    const isCurrent = isCurrentItem(item.route)
                    return (
                      <li
                        className="flex items-center lg:mr-3"
                        key={`nav-item-${index}`}>
                        <Link
                          href={`/workspaces/${globalContext.activeWorkspace?.id}${item.route}`}>
                          <a
                            className={`${isCurrent ? 'text-teal-4' : 'text-navy-5'
                              } px-3 py-1 hover:bg-dark-5 rounded-lg transition-all flex items-center`}>
                            <Icon
                              icon={item.icon}
                              fill={isCurrent ? '#0b9588' : '#293241'}
                            />
                            <span className="ml-2">{item.text}</span>
                          </a>
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            )}
          </nav>
          <div
            className={`flex lg:relative ${globalContext.user ? '' : 'py-5'}`}>
            {globalContext.user && (
              <Fragment>
                <button
                  className="mr-5 sm:mr-8 relative"
                  onClick={() => {
                    setIsNavOpen(false)
                    setIsNotificationsOpen(!isNotificationsOpen)
                  }}>
                  <Icon icon={BellIcon} />
                  {!!globalContext.user.notifications?.filter((n) => !n.seen)
                    .length && (
                      <div className="bg-red-3 rounded-full h-2.5 w-2.5 absolute top-1.5 right-0" />
                    )}
                </button>
                <button
                  className="appearance-none border-0 bg-white flex items-center"
                  onClick={() => {
                    setIsNavOpen(!isNavOpen)
                    setIsNotificationsOpen(false)
                  }}>
                  <span className="hidden sm:block">
                    {`${globalContext.user?.first_name} ${globalContext.user?.last_name}`}
                  </span>
                  <Avatar
                    size="small"
                    classes="ml-3"
                    image={globalContext.user?.image}
                    type="user"
                  />

                  <img
                    src="/chevron_down.svg"
                    className={`transition-all transform ${isNavOpen ? 'rotate-180' : 'rotate-0'
                      }`}
                  />
                </button>
                <NotificationsDropdown
                  isOpen={isNotificationsOpen}
                  close={() => setIsNotificationsOpen(false)}
                />
                <div
                  className={`${isNavOpen
                    ? 'lg:translate-y-8 opacity-1'
                    : 'translate-y-0 opacity-0 pointer-events-none'
                    } h-screen lg:h-auto absolute top-0 lg:top-full transition-all transform w-full lg:w-56 shadow-md rounded-md left-0 right-auto lg:left-auto lg:right-0 bg-white`}>
                  <button
                    onClick={() => setIsNavOpen(false)}
                    className="appearance-none bg-transparent border-0 absolute top-5 right-5 lg:hidden">
                    <img src="/close_circle.svg" />
                  </button>
                  <div className="px-3 py-5 border-dark-15 border-b-2">
                    {!!globalContext.activeWorkspace && (
                      <Fragment>
                        <h2>{globalContext.activeWorkspace?.name}</h2>
                        <div className="flex items-center">
                          <Link href="/workspaces">
                            <a className="text-xxs text-teal-3 uppercase">
                              switch
                            </a>
                          </Link>
                          {!!globalContext.activeWorkspace &&
                            !!globalContext.activeWorkspaceUser &&
                            isWorkspaceAdmin(
                              globalContext.activeWorkspaceUser,
                            ) && (
                              <Link
                                href={`/workspaces/${globalContext.activeWorkspace.id}/settings`}>
                                <a className="text-xxs text-teal-3 ml-2 uppercase">
                                  settings
                                </a>
                              </Link>
                            )}
                        </div>
                      </Fragment>
                    )}
                    <div className="text-dark-50">
                      {globalContext.user?.email}
                    </div>
                  </div>
                  {!globalContext.activeWorkspace && (
                    <NavItem
                      item={{
                        text: 'My Workspaces',
                        route: `/workspaces/`,
                        icon: People,
                      }}
                    />
                  )}
                  {globalContext.activeWorkspace && (
                    <ul className="flex flex-col lg:hidden border-b-2 border-dark-15">
                      {nav.items
                        .filter((item) => {
                          return (
                            !item.adminOnly ||
                            isWorkspaceAdmin(globalContext.activeWorkspaceUser)
                          )
                        })
                        .map((item, index) => {
                          return (
                            <NavItem
                              item={item}
                              key={`nav-item-${index}`}
                              isRelativeToWorkspace
                            />
                          )
                        })}
                    </ul>
                  )}
                  <ul className="flex flex-col border-b-2 border-dark-15 lg:border-b-0 bg-white">
                    {nav.secondaryItems.map((item, index) => {
                      return (
                        <NavItem
                          item={item}
                          key={`nav-item-secondary-${index}`}
                        />
                      )
                    })}
                    {globalContext.activeWorkspace &&
                      isWorkspaceAdmin(globalContext.activeWorkspaceUser) && (
                        <NavItem
                          item={{
                            text: 'Workspace Settings',
                            route: `/workspaces/${globalContext.activeWorkspace.id}/settings`,
                            icon: Briefcase,
                          }}
                        />
                      )}
                    <li className="flex flex-1 items-center">
                      <a
                        className="text-red-3 px-3 py-5 border-dark-15 border-t-2 hover:bg-dark-5 transition-all flex items-center w-full cursor-pointer"
                        onClick={() => {
                          globalContext.logout()
                          router.push('/')
                        }}>
                        <Icon icon={Logout} fill="#de4d15" />
                        <span className="ml-2">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AppNavigation
