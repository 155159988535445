import { format } from 'date-fns'
import { tzDate } from "../utils"

const DatumCard = ({ datum, classes }) => {
  return (
    <div className={`flex items-center bg-white p-6 ${classes}`}>
      <div className="px-2 flex-1">
        {format(new tzDate(datum.date), 'MMM d, y')}
      </div>
      <div className="px-2 flex flex-col items-center">
        <span className="font-bold">
          {datum.single_display}
        </span>
        <span className="text-xs text-dark-50">VALUE</span>
      </div>
    </div>
  )
}

export default DatumCard
