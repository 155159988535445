import { useContext, useState } from 'react'
import Loader from 'react-loader-spinner'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import { GlobalContext } from '../context/GlobalContext'
import api from '../services/api'
import { FormSelect, Button, FormCheckbox } from '.'
import { digestFrequencyOptions } from '../utils/content'

const NotificationsSettings = () => {
  const globalContext = useContext(GlobalContext)
  const [activeWorkspaceUser, setActiveWorkspaceUser] = useState({})
  const [hasMadeChanges, setHasMadeChanges] = useState(false)
  const { addToast } = useToasts()

  const discard = (e) => {
    e.preventDefault()
    setActiveWorkspaceUser(data.find((t) => t.id == activeWorkspaceUser.id))
    setHasMadeChanges(false)
  }

  const updateWorkspaceUser = (key, val) => {
    setHasMadeChanges(true)
    setActiveWorkspaceUser({
      ...activeWorkspaceUser,
      [key]: val,
    })
  }

  const updateAll = (e, val) => {
    e.preventDefault()
    setHasMadeChanges(true)
    setActiveWorkspaceUser({
      ...activeWorkspaceUser,
      email_on_project_creation: val,
      email_on_project_completion: val,
      email_on_goal_hit: val,
    })
  }

  const { isLoading, error, data, refetch } = useQuery(
    'workspaceUsers',
    () => api.getWorkspaces(),
    {
      onSuccess: (response) => {
        setHasMadeChanges(false)
        if (!activeWorkspaceUser?.id) {
          setActiveWorkspaceUser(response[0])
        }
      },
    },
  )

  const mutation = useMutation(
    () => api.updateWorkspaceUser(activeWorkspaceUser),
    {
      onSuccess: (response) => {
        refetch()
        addToast('Preferences updated successfully!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        })
      },
    },
  )

  return (
    <section>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center p-6">
          <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
        </div>
      ) : (
        <form>
          <div className="">
            <div className="p-6">
              <h3 className="text-sm mb-3 font-bold">Workspace</h3>
              <FormSelect
                options={data?.map((workspaceUser) => {
                  return {
                    value: workspaceUser.id,
                    label: workspaceUser.team.name,
                  }
                })}
                label=""
                onChange={(option) => {
                  setActiveWorkspaceUser(
                    data?.find((t) => t.id == option.value),
                  )
                }}
                value={{
                  value: activeWorkspaceUser.id,
                  label: activeWorkspaceUser.team?.name,
                }}
              />

              <h2 className="uppercase text-xs font-bold text-dark-50 mb-5">
                Notification Frequency
              </h2>
              <h3 className="text-sm mb-3 font-bold">
                How often do you want to receive a progress digest?
              </h3>
              <FormSelect
                options={digestFrequencyOptions}
                label=""
                onChange={(option) => {
                  updateWorkspaceUser('digest_frequency', option.value)
                }}
                value={digestFrequencyOptions.find(
                  (o) => o.value == activeWorkspaceUser.digest_frequency,
                )}
              />
            </div>
            <div className="p-6 pb-3 border-t border-dark-15">
              <h2 className="uppercase text-xs font-bold text-dark-50 mb-5">
                Notifications
              </h2>
              <h3 className="text-sm mb-3 font-bold">Email me when:</h3>
              <FormCheckbox
                checked={activeWorkspaceUser.email_on_project_creation}
                label="When a project is created"
                onChange={() =>
                  updateWorkspaceUser(
                    'email_on_project_creation',
                    !activeWorkspaceUser.email_on_project_creation,
                  )
                }
              />
              <FormCheckbox
                checked={activeWorkspaceUser.email_on_project_completion}
                label="When a project is completed"
                onChange={() =>
                  updateWorkspaceUser(
                    'email_on_project_completion',
                    !activeWorkspaceUser.email_on_project_completion,
                  )
                }
              />
              <FormCheckbox
                checked={activeWorkspaceUser.email_on_goal_hit}
                label="When a goal target benchmark is hit"
                onChange={() =>
                  updateWorkspaceUser(
                    'email_on_goal_hit',
                    !activeWorkspaceUser.email_on_goal_hit,
                  )
                }
              />
            </div>
            <div className="flex items-center px-6">
              <button
                className="text-dark-50 uppercase text-xs"
                onClick={(e) => updateAll(e, true)}>
                Select All
              </button>
              <div className="border-dark-15 border-r h-4 mx-3" />
              <button
                className="text-dark-50 uppercase text-xs"
                onClick={(e) => updateAll(e, false)}>
                Clear All
              </button>
            </div>
            <div className="p-6 flex justify-end">
              <Button
                disabled={!hasMadeChanges}
                text="Discard"
                theme="ghost"
                classes="mr-3"
                action={discard}
              />
              <Button
                disabled={!hasMadeChanges}
                text="Save"
                action={(e) => {
                  e.preventDefault()
                  mutation.mutate(e)
                }}
              />
            </div>
          </div>
        </form>
      )}
    </section>
  )
}

export default NotificationsSettings
