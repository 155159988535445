import { useRouter } from 'next/router'

import { DataSourceCard } from '.'

const DataSourceDashboard = ({ dataSources }) => {
  const router = useRouter()

  return dataSources.map((dataSource, index) => (
    <DataSourceCard
      key={dataSource.id}
      dataSource={dataSource}
      borderClasses={index + 1 === dataSources.length ? 'border-b-0' : ''}
      workspaceId={router.query?.workspaceId}
    />
  ))
}

export default DataSourceDashboard
