import { Button } from '../components'

const EmptyDashboardContent = ({ text, buttonText, buttonHref, icon }) => {
  return (
    <div className="px-4 md:px-8 py-4 md:py-12 flex justify-center items-center flex-col">
      {icon}
      <p className="mt-5 text-center">{text}</p>
      <div className="mt-5">
        <Button href={buttonHref} text={buttonText} />
      </div>
    </div>
  )
}

export default EmptyDashboardContent
