import { useState, Fragment } from 'react'
import Loader from 'react-loader-spinner'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'

import {
  CardContainer,
  TextInput,
  Button,
  Alert,
  Icon,
  TextArea,
  FileInput,
  Avatar,
  FormCheckbox,
} from '.'
import api from '../services/api'
import ArrowRightIcon from '../public/arrow_right.svg'
import FormDatePicker from './FormDatePicker'
import CloseIcon from '../public/close_circle.svg'

const CreateProjectForm = () => {
  const [step, setStep] = useState(1)
  const [isWorking, setIsWorking] = useState(false)
  const [errors, setErrors] = useState([])
  const [formValues, setFormValues] = useState({
    id: null,
    name: '',
    description: '',
    start_date: new Date(),
    end_date: new Date(),
    image: '',
    categories: [],
    goals: [],
  })
  const router = useRouter()

  const { isLoading, error, data } = useQuery('project_options', () =>
    api.getProjectMetaOptions(),
  )

  const updateFormValue = (key, val) => {
    setErrors([])
    setFormValues({
      ...formValues,
      [key]: val,
    })
  }

  const getTitle = (step) => {
    switch (step) {
      case 2:
        return "What's important to the project?"
      case 1:
      default:
        return "Let's start your project!"
    }
  }

  const getFormValidity = () => {
    switch (step) {
      case 1:
        return !!formValues.name
      case 2:
        return !!formValues.categories?.length && !!formValues.goals?.length
    }
  }

  const handleNext = (e) => {
    e.preventDefault()
    setErrors([])
    setIsWorking(true)

    if (isFormValid) {
      if (step === 2) {
        api
          .createProject(router.query.workspaceId, formValues)
          .then((response) => {
            setIsWorking(false)
            router.replace(
              `/workspaces/${router.query.workspaceId}/goals/new?projectId=${response.id}`,
            );
          })
      } else {
        setIsWorking(false)
        setStep(step + 1)
      }
    }
  }

  const isFormValid = getFormValidity()
  return (
    <div className="mt-8 md:mt-12 mb-10">
      <h1 className="text-center mb-20 font-serif text-3xl">
        {getTitle(step)}
      </h1>
      <CardContainer classes="relative w-186 max-w-full mx-auto">
        <div className="-mt-9 flex justify-center z-10 relative">
          <div className="rounded-full bg-yellow-3 py-1 px-4 self-center text-xxs uppercase tracking-wider">
            Step {step} of 5
          </div>
        </div>
        <form
          className={`pt-5 ${isWorking ? 'opacity-50' : ''}`}
          onSubmit={handleNext}>
          {step === 1 && (
            <Fragment>
              <TextInput
                required
                label="Project Name"
                placeholder="Acme Gala 2021"
                value={formValues.name}
                onChange={(e) => updateFormValue('name', e.target.value)}
                classes="w-full"
              />
              <TextArea
                label="Description"
                placeholder="This is where you can put important details about your project."
                value={formValues.description}
                onChange={(e) => updateFormValue('description', e.target.value)}
              />
              <div className="flex flex-col sm:flex-row">
                <div className="flex-1 mb-4 sm:mb-0 sm:mr-2">
                  <FormDatePicker
                    value={formValues.start_date}
                    onChange={(val) => updateFormValue('start_date', val)}
                    label="Start Date"
                  />
                </div>
                <div className="flex-1 mb-4 sm:mb-0 sm:ml-2">
                  <FormDatePicker
                    value={formValues.end_date}
                    onChange={(val) => updateFormValue('end_date', val)}
                    label="End Date"
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row my-10">
                <Avatar
                  image={formValues ? formValues.image : null}
                  type="team"
                />
                <div className="flex-1 flex-col flex justify-center">
                  <div className="flex flex-col sm:flex-row items-center">
                    <FileInput
                      onUpdate={(imageString) =>
                        updateFormValue('image', imageString)
                      }
                    />
                    {formValues?.image && (
                      <Button
                        action={() => updateFormValue('image', '')}
                        text="REMOVE"
                        size="small"
                        theme="red-transparent"
                        IconLeft={() => (
                          <Icon
                            icon={(props) => <CloseIcon {...props} />}
                            size="small"
                            fill="#de4d15"
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="text-dark-50 mt-4">
                    Upload a photo for your profile (JPG, JPEG, PNG only; 1 MB Maximum File Size).
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {step === 2 && (
            <section className="mb-10">
              <h3 className="mb-3 font-bold text-sm">
                How would you describe this project?
              </h3>
              <div className="flex flex-wrap">
                {data &&
                  data.goals.map((option) => (
                    <FormCheckbox
                      checked={formValues.goals.includes(option.slug)}
                      label={option.name}
                      key={option.name}
                      classes="w-1/2 px-2"
                      onChange={() => {
                        updateFormValue(
                          'goals',
                          formValues.goals.includes(option.slug)
                            ? formValues.goals.filter((o) => o != option.slug)
                            : formValues.goals.concat([option.slug]),
                        )
                      }}
                    />
                  ))}
              </div>
              <div className="mt-5">
                <h3 className="mb-3 font-bold text-sm">
                  What are your goals for the project?
                </h3>
                <div className="flex flex-wrap">
                  {data?.categories.map((category) => (
                    <FormCheckbox
                      key={category.slug}
                      checked={formValues.categories.includes(category.slug)}
                      label={category.name}
                      classes="w-1/2 px-2"
                      onChange={() => {
                        updateFormValue(
                          'categories',
                          formValues.categories.includes(category.slug)
                            ? formValues.categories.filter(
                                (catSlug) => catSlug != category.slug,
                              )
                            : formValues.categories.concat([category.slug]),
                        )
                      }}
                    />
                  ))}
                </div>
              </div>
            </section>
          )}
          {!!errors.length && <Alert type="danger" text={errors.join(' ')} />}
          <div
            className={`flex items-center mt-5 ${
              step === 2 ? 'justify-between' : 'justify-end'
            }`}>
            {step === 2 && (
              <a
                href="#"
                className="text-teal-3 underline"
                onClick={(e) => {
                  e.preventDefault()
                  setStep(1)
                }}>
                Back
              </a>
            )}
             <Button
              text="Next"
              type="submit"
              showSpinner={true}
              disabled={!isFormValid || isWorking}
              action={handleNext}
              IconRight={() => <Icon icon={ArrowRightIcon} fill="white" />}
            />
          </div>
        </form>
        {isWorking && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50 rounded-xl">
            <Loader type="TailSpin" height="72" width="72" color="white" />
          </div>
        )}
      </CardContainer>
    </div>
  )
}

export default CreateProjectForm
