import { Button, Icon } from '.'
import ArrowRightIcon from '../public/arrow_right.svg'
import ArrowLeftIcon from '../public/arrow_left.svg'
import { Fragment } from 'react'

const CardHeader = ({
  title,
  icon,
  buttonText = 'View All',
  buttonHref,
  buttonAction,
  isSlider,
  onNext,
  onPrev,
  ButtonComponent,
  disabledButton = false,
  buttonClasses = '',
}) => {
  return (
    <header
      className={`flex flex-col xs:flex-row xs:justify-between xs:items-center p-5 border-dark-15 border-b flex-wrap`}>
      <div className="flex items-center">
        {icon}
        <h1 className={`font-serif text-2xl ${icon ? 'ml-3' : ''}`}>{title}</h1>
      </div>
      {!disabledButton && (
        <Fragment>
          {ButtonComponent ? (
            <div className={`mt-3 sm:mt-0 sm:my-1`}>
              <ButtonComponent />
            </div>
          ) : (
            <div className={`mt-3 sm:mt-0 sm:my-1 ${buttonClasses}`}>
              {isSlider ? (
                <div className="flex items-center">
                  <button onClick={onPrev}>
                    <Icon icon={ArrowLeftIcon} />
                  </button>
                  <button onClick={onNext} className="ml-3">
                    <Icon icon={ArrowRightIcon} />
                  </button>
                </div>
              ) : (
                <Button
                  size="x-small"
                  theme="yellow"
                  IconRight={() => <Icon icon={ArrowRightIcon} />}
                  href={buttonHref}
                  action={buttonAction}
                  text={buttonText}
                />
              )}
            </div>
          )}
        </Fragment>
      )}
    </header>
  )
}

export default CardHeader
