import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileCsv,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

import { ContextualMenu, Icon, DataConnectButton, Button, TextInput } from '.'
import TrashIcon from '../public/trash.svg'
import ArrowRightIcon from '../public/arrow_right.svg'
import { theme } from '../tailwind.config'
import { truncateString } from '../utils'

const DataSourceCard = ({
  dataSource,
  showControls = false,
  borderClasses = '',
  classes = '',
  isConnectCard,
  isConnected = false,
  showSuccessModal,
  disconnectDataSource,
  connectDataSource,
  workspaceId,
  canAddConnection,
  needsReconnect,
}) => {
  const [shopifyStoreSlug, setShopifyStoreSlug] = useState('')
  return isConnectCard ? (
    <div
      className={`relative flex flex-col bg-white ${
        borderClasses ? borderClasses : 'border-b border-dark-15'
      } ${classes}`}>
      <div className="p-4 flex flex-col flex-1">
        <div className="flex-1">
          <div className="flex items-center flex-1">
            {dataSource.name === 'CSV' ? (
              <FontAwesomeIcon
                icon={faFileCsv}
                className="h-14 mr-4 text-teal-4"
              />
            ) : (
              <img
                className="w-14 h-14 rounded-lg mr-4  object-contain"
                src={dataSource.image}
              />
            )}
            <span className="font-bold text-md">{dataSource.name}</span>
          </div>
          {dataSource.description && (
            <p
              className="text-md font-light py-4"
              dangerouslySetInnerHTML={{
                __html: dataSource.description,
              }}
            />
          )}
          {needsReconnect && (
            <div className="bg-red-3 text-sm items-center flex p-2 rounded-md">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="h-4 mr-2 text-white"
              />
              <span className="text-white">Connection failed!</span>
            </div>
          )}
        </div>
        {/* {dataSource.name === 'Shopify' && isConnectCard && !isConnected && (
          <TextInput
            required
            disabled={isConnected}
            label="Store Name"
            placeholder="my-store"
            value={shopifyStoreSlug}
            onChange={(e) => setShopifyStoreSlug(e.target.value)}
            classes="w-full"
          />
        )} */}
      </div>
      <div className="p-4 border-t border-dark-15 flex flex-">
        <div className="flex flex-col items-end flex-1">
          {dataSource.name === 'CSV' ? (
            <Button
              href={`/workspaces/${workspaceId}/csv`}
              text="Manage"
              size="small"
              theme="yellow"
              classes=""
              IconRight={() => (
                <Icon
                  icon={(props) => <ArrowRightIcon {...props} />}
                  fill={theme.colors.navy[4]}
                />
              )}
            />
          ) : (
            <DataConnectButton
              disconnectDataSource={disconnectDataSource}
              connectDataSource={connectDataSource}
              isConnected={isConnected}
              needsReconnect={needsReconnect}
              dataSource={dataSource}
              canAddConnection={canAddConnection}
              shopifyStoreSlug={shopifyStoreSlug}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`p-4 relative bg-white ${
        borderClasses ? borderClasses : 'border-b border-dark-15'
      } ${classes}`}>
      <div className="flex items-center">
        {dataSource.name === 'CSV' ? (
          <FontAwesomeIcon
            icon={faFileCsv}
            className="h-14 mr-6 text-teal-4 ml-2"
          />
        ) : (
          <img
            className="w-14 h-14 rounded-lg mr-4  object-contain"
            src={dataSource.image}
          />
        )}
        <div className="flex flex-col">
          <span className="font-bold text-md">{dataSource.name}</span>
          <div className="text-sm text-dark-50">
            {dataSource.pivot?.platform_id}
          </div>
        </div>
      </div>
      {dataSource.name === 'CSV' && (
        <Button
          href={`/workspaces/${workspaceId}/csv`}
          text="Manage"
          size="x-small"
          theme="yellow"
          classes="absolute top-8 right-5"
          IconRight={() => (
            <Icon
              icon={(props) => <ArrowRightIcon {...props} />}
              fill={theme.colors.navy[4]}
            />
          )}
        />
      )}
      {showControls && dataSource.name !== 'CSV' && (
        <ContextualMenu
          classes="top-7 right-0"
          items={[
            {
              text: `Disconnect`,
              action: (args) => {
                disconnectDataSource({
                  ...args,
                  dataSourceId: dataSource.pivot.data_source_id,
                })
              },
              Icon: () => <Icon icon={TrashIcon} fill={theme.colors.red[2]} />,
              textClass: 'text-red-3',
            },
          ]}
        />
      )}
    </div>
  )
}

export default DataSourceCard
