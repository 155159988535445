import { Icon, Button, CardContainer, Modal } from '.'
import CloseIcon from '../public/close_circle.svg'

const CsvInstructionsModal = ({ isOpen, close }) => {
  return (
    <Modal isOpen={isOpen}>
      <CardContainer classes="relative w-186 max-w-full max-h-full overflow-auto mx-5">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-serif mb-4 text-navy-5">
            CSV Formatting Instructions
          </h2>
          <p className="font-light text-navy-5 mb-4">
            In order to properly process your CSV, please make sure your file is
            formatted in the following ways before uploading:
          </p>
          <ul className="list-disc font-light px-4 sm:px-8 mb-3">
            <li className="my-2">
              The first column <em>must</em> be titled 'date' and be formatted
              in any of the following ways: 'YYYY-MM-DD', 'MM-DD-YYYY',
              'YYYY/MM/DD', 'MM/DD/YYYY'
            </li>
            <li className="my-2">
              The second column can have any title, but the data must be in a
              number format. Commas in numbers are OK as long as they are
              wrapped by double quotation marks (e.g. "24,399" rather than
              24,399).
            </li>
            <li className="my-2">
              You may also leave certain currency symbols ($, €, £, ¥) in your
              data.
            </li>
          </ul>
          <img src="/csv_template.png" className="my-2" />
          <p className="text-dark-50 italic text-sm mb-3">
            Example of CSV formatting
          </p>
          <Button text="Close" action={close} />
        </div>
      </CardContainer>
    </Modal>
  )
}

export default CsvInstructionsModal
