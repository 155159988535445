import { useRef } from 'react'
import { useToasts } from 'react-toast-notifications'

const FileInput = ({ onUpdate, fileLabel = 'Thumbnail', disabled }) => {
  const inputRef = useRef()
  const { addToast } = useToasts()

  const handleProfilePicChanged = () => {
    const reader = new FileReader()
    const file = inputRef.current.files[0]
    if (file && file.size > 1000000){
      addToast(`Please upload an image under 1MB.`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })
    return;
    }
    reader.onloadend = () => {
      onUpdate(reader.result)
    }
    reader.readAsDataURL(file)
  }

  return (
    <div
      className={`rounded-full border-dark-50 border-2 px-4 relative flex items-center ${
        disabled ? 'opacity-25' : ''
      }`}>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleProfilePicChanged}
        className="absolute top-0 left-0 w-full h-full opacity-0"
        disabled={disabled}
      />
      <label className="text-navy-4">Upload {fileLabel}</label>
    </div>
  )
}

export default FileInput
