const ReportPrintHeader = ({ meta }) => {
  return (
    <div className="mb-5 hidden flex-pdf">
      <div className="flex-1 flex items-center">
        <img src="/sunsett-icon.png" className="w-16" />
        <img src="/sunsett-logo.png" className="w-32" />
      </div>
      <div className="text-right">
        <div>
          {meta.title} for {meta.dates}
        </div>
        {meta.goals ? <div>Goals: {meta.goals}</div> : null}
        {meta.projects ? <div>Projects: {meta.projects}</div> : null}
        {meta.category ? <div>Category: {meta.category}</div> : null}
      </div>
    </div>
  )
}

export default ReportPrintHeader
