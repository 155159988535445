import { Fragment } from 'react'

import { isWorkspaceAdmin } from '../utils'
import { WorkspaceUserCard } from '.'

const WorkspaceUserListing = ({ workspace, refetchWorkspace }) => {
  const workspaceAdmins = workspace.team_users.filter((u) =>
    isWorkspaceAdmin(u),
  )
  const workspaceNonAdmins = workspace.team_users.filter(
    (u) => !isWorkspaceAdmin(u),
  )
  return (
    <div>
      <div className="border-dark-15 border-b py-3 px-5">
        <h2 className="uppercase text-xs mt-4 font-bold">Admins</h2>
      </div>
      {workspaceAdmins.map((workspaceUser) => (
        <WorkspaceUserCard
          key={workspaceUser.id}
          workspaceUser={workspaceUser}
          isAdmin
          classes="border-b border-dark-15"
        />
      ))}
      {!!workspaceNonAdmins.length && (
        <Fragment>
          <div className="border-dark-15 border-b py-3 px-5">
            <h2 className="uppercase text-xs mt-4 font-bold">Members</h2>
          </div>
          {workspaceNonAdmins.map((workspaceUser, index) => (
            <WorkspaceUserCard
              key={workspaceUser.id}
              workspaceUser={workspaceUser}
              classes={
                index !== workspaceNonAdmins.length - 1 ||
                !!workspace.invitations.length
                  ? 'border-b border-dark-15'
                  : ''
              }
            />
          ))}
        </Fragment>
      )}
      {!!workspace.invitations?.length && (
        <Fragment>
          <div className="border-dark-15 border-b py-3 px-5">
            <h2 className="uppercase text-xs mt-4 font-bold">Invitations</h2>
          </div>
          {workspace.invitations.map((invitation, index) => (
            <WorkspaceUserCard
              key={invitation.id}
              workspaceUser={invitation}
              refetchWorkspace={refetchWorkspace}
              isInvitation
              classes={
                index !== workspace.invitations.length - 1
                  ? 'border-b border-dark-15'
                  : ''
              }
            />
          ))}
        </Fragment>
      )}
    </div>
  )
}

export default WorkspaceUserListing
