/**
 * IMPORTANT!
 *
 * "Teams" are now called "Workspaces"
 * Names have been adjusted where appropriate on the front end,
 * but the back end has not been, therefore api calls will be to
 * `/teams/...` instead of `/workspaces/...`
 */

import axios from 'axios'
import format from 'date-fns/format'
import Cookies from 'js-cookie'

const apiClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
})

apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log('err.response', err.response)
    if (
      err.response.status == 401 &&
      !err.response.message == 'Either the email or password are incorrect.'
    ) {
      localStorage.removeItem('sunsett_user')
      localStorage.removeItem('sunsett_token')
      Cookies.remove('XSRF-TOKEN')
      window.location.href = '/'
    }
    throw err
  },
)

const formatGoal = (goal) => {
  return {
    ...goal,
    goalCategoryId: goal.goalCategory.value,
    projectId: goal.project.value || goal.project.id,
    goalCategoryValueTypeId: goal.goalCategoryValueType?.value,
    goalCategoryDirectionId: goal.goalCategoryDirection?.id,
    startDate: format(goal.startDate, 'yyyy-MM-dd'),
    endDate: format(goal.endDate, 'yyyy-MM-dd'),
    csvId: goal.csv?.id,
  }
}
export default {
  login(email, password) {
    return apiClient.post('/login', { email, password })
  },
  register({ email, password, title, first_name, last_name }) {
    return apiClient.post('/register', {
      email,
      password,
      first_name,
      last_name,
      title,
    })
  },
  requestPasswordReset(email) {
    return apiClient.post('/reset-password-request', { email })
  },
  changePassword(token, password, passwordConfirmation) {
    return apiClient.post(`/reset-password/${token}`, {
      password,
      password_confirmation: passwordConfirmation,
    })
  },
  getSubscriptionLevels() {
    return apiClient.get('/subscriptions').then((response) => response.data)
  },
  updateWorkspaceSubscription(workspace) {
    return apiClient
      .post(`/teams/${workspace.id}/subscription-limits-update`, workspace)
      .then((response) => response.data)
  },
  getUser(id) {
    return apiClient.get(`/users/${id}`).then((response) => response.data)
  },
  updateUser(user) {
    return apiClient
      .put(`/users/${user.id}`, {
        ...user,
      })
      .then((response) => response.data)
  },
  updatePassword({ currentPassword, newPassword, newPasswordConfirmation }) {
    return apiClient
      .post('reset-password', {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      })
      .then((response) => response.data)
  },
  requestEmailVerification() {
    return apiClient.post('/email-verify').then((response) => response.data)
  },
  verifyEmail(token) {
    return apiClient
      .post(`/email-verify/${token}`)
      .then((response) => response.data)
  },
  getInvitation(invitationId) {
    return apiClient
      .get(`/invitation/${invitationId}`)
      .then((response) => response.data)
  },
  respondToInvitation({ invitationId, response }) {
    return apiClient
      .post(`/invitation/${invitationId}`, {
        response,
      })
      .then((response) => response.data)
  },
  getNotifications(page) {
    return apiClient
      .get(`/notifications${page ? `?page=${page}` : ''}`)
      .then((response) => response.data)
  },
  markNotificationsAsSeen(notificationIds) {
    return apiClient.post('/notifications/seen', {
      notificationIds,
    })
  },
  trackEvent(event, data) {
    return apiClient.post('/users/track', {
      event,
      data
    })
  },
  /**
   * WORKSPACES (originally called teams)
   */
  getWorkspaces() {
    return apiClient.get('/teams').then((response) => response.data)
  },
  updateWorkspaceUser(workspaceUser) {
    return apiClient
      .put(`/teams/${workspaceUser.team.id}/users/${workspaceUser.id}`, {
        ...workspaceUser,
      })
      .then((response) => response.data)
  },
  removeWorkspaceUser(workspaceUser) {
    return apiClient
      .delete(`/teams/${workspaceUser.team_id}/users/${workspaceUser.id}`)
      .then((response) => response.data)
  },
  createWorkspace({
    name,
    focus,
    description,
    size,
    image,
    timezone,
    members_can_create_goals = false,
  }) {
    return apiClient
      .post('/teams', {
        name,
        focus: focus.value,
        description: description?.value,
        size: size.value,
        image,
        timezone,
        members_can_create_goals,
      })
      .then((response) => response.data)
  },
  updateWorkspace(workspace) {
    return apiClient
      .put(`/teams/${workspace.id}`, {
        ...workspace,
      })
      .then((response) => response.data)
  },
  updateWorkspaceAdmin(workspaceId, workspaceUserId) {
    return apiClient
      .put(`/teams/${workspaceId}/admin`, {
        workspaceUserId,
      })
      .then((response) => response.data)
  },
  getWorkspace(id) {
    return apiClient.get(`/teams/${id}`).then((response) => response.data)
  },
  checkWorkspaceLimit(workspaceId, type) {
    return apiClient
      .get(`/teams/${workspaceId}/limit?type=${type}`)
      .then((response) => response.data)
  },
  getWorkspaceDashboard(workspaceId) {
    return apiClient
      .get(`/teams/${workspaceId}/dashboard-data`)
      .then((response) => response.data)
  },
  getWorkspaceDataSourceOptions(workspaceId) {
    return apiClient
      .get(`/teams/${workspaceId}/data-source-options`)
      .then((response) => response.data)
  },
  getNonce(workspaceId, dataSourceId) {
    return apiClient
      .get(`/teams/${workspaceId}/nonce/${dataSourceId}`)
      .then((response) => response.data)
  },
  connectShopify(data) {
    return apiClient
      .post(`/connect-shopify`, data)
      .then((response) => response.data)
  },
  connectEventbrite(data) {
    return apiClient
      .post(`/connect-eventbrite`, {
        ...data,
        teamId: data.workspaceId,
      })
      .then((response) => response.data)
  },
  getGoalFilterOptions(dataSourceId, categoryId) {
    return apiClient
      .get(
        `/goals/filter-options?dataSourceId=${dataSourceId}&goalCategoryId=${categoryId}`,
      )
      .then((response) => response.data)
  },
  sendInvitations({ invitations, workspaceId }) {
    return apiClient.post(`/teams/${workspaceId}/invite`, {
      invitations,
    })
  },
  revokeInvitation(invitationId) {
    return apiClient.delete(`/invitations/${invitationId}`)
  },
  connectDataSource(workspaceId, dataSourceOptionId, code, accessToken) {
    return apiClient.post(`/teams/${workspaceId}/data-source`, {
      dataSourceOptionId,
      code,
      accessToken,
    })
  },
  disconnectDataSource(workspaceId, dataSourceOptionId) {
    return apiClient.delete(
      `/teams/${workspaceId}/data-source?dataSourceOptionId=${dataSourceOptionId}`,
    )
  },
  getGaProperties(workspaceId) {
    return apiClient
      .get(`/teams/${workspaceId}/ga-properties`)
      .then((response) => response.data)
  },
  getCsvList(
    workspaceId,
    { pageNumber = 1, searchTerm = '', goalId, sortBy, all = false },
  ) {
    return apiClient
      .get(`/teams/${workspaceId}/csvs`, {
        params: {
          page: pageNumber,
          q: searchTerm,
          goalId,
          sortBy,
          all,
        },
      })
      .then((response) => response.data)
  },
  getCsv(workspaceId, csvId) {
    return apiClient
      .get(`/teams/${workspaceId}/csvs/${csvId}`)
      .then((response) => response.data)
  },
  storeCsv(workspaceId, csv, file) {
    const formData = new FormData()
    if (file){
      formData.append('file', file)
    }
    formData.append('name', csv.name)
    formData.append('description', csv.description)
    if (csv.type){
      formData.append('type', csv.type)
    }

    return apiClient
      .post(
        csv.id
          ? `/teams/${workspaceId}/csvs/${csv.id}`
          : `/teams/${workspaceId}/csv`,
        formData,
      )
      .then((response) => response.data)
  },
  deleteCsv(workspaceId, csvId) {
    return apiClient
      .delete(`/teams/${workspaceId}/csvs/${csvId}`)
      .then((response) => response.data)
  },
  getReportData({
    type,
    workspaceId,
    start_date,
    end_date,
    projects,
    projectCategory,
    goalCategory,
    goals,
  }) {
    return apiClient
      .get(`/teams/${workspaceId}/report-data`, {
        params: {
          type,
          start_date,
          end_date,
          projects: projects?.filter((p) => p != ''),
          goals: goals?.filter((g) => g != ''),
          project_category_id: projectCategory,
          goal_category_id: goalCategory,
        },
      })
      .then((response) => response.data)
  },
  getCategoryReportData({ workspaceId, category, start_date, end_date }) {
    return apiClient
      .get(
        `/teams/${workspaceId}/category-report-data?${
          category ? `category=${category}` : ''
        }${start_date ? `startDate=${start_date}&` : ''}${
          end_date ? `&endDate=${end_date}&` : ''
        }`,
      )
      .then((response) => response.data)
  },
  getSingleCategoryReportData({
    workspaceId,
    categoryId,
    goalGaId,
    start_date,
    end_date,
  }) {
    return apiClient
      .get(
        `/teams/${workspaceId}/category-report-data/${categoryId}?${
          goalGaId ? `gaViewId=${goalGaId}&` : ''
        }${start_date ? `startDate=${start_date}&` : ''}${
          end_date ? `&endDate=${end_date}&` : ''
        }`,
      )
      .then((response) => response.data)
  },
  getSavedReports({ teamId }) {
    return apiClient
      .get(`/teams/${teamId}/saved-reports`)
      .then((response) => response.data)
  },
  createSavedReport(
    teamId,
    name,
    type,
    startDate,
    endDate,
    projectIds,
    projectCategoryIds,
    goalIds,
    goalCategoryOptionIds,
  ) {
    return apiClient
      .post(`/teams/${teamId}/saved-reports`, {
        name,
        type,
        startDate,
        endDate,
        projectIds,
        projectCategoryIds,
        goalIds,
        goalCategoryOptionIds,
      })
      .then((response) => response.data)
  },
  deleteSavedReport(savedReportId) {
    return apiClient
      .delete(`/saved-reports/${savedReportId}`)
      .then((response) => response.data)
  },
  getPastTimePeriodData(
    startDate,
    endDate,
    {
      workspaceId,
      dataSourceId,
      goalCategory,
      goalFilters,
      gaView,
      gaProperty,
    },
  ) {
    return apiClient
      .get(`/teams/${workspaceId}/previous-period-data`, {
        params: {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          dataSourceId,
          goalCategoryId: goalCategory.value,
          gaView,
          goalFilters: goalFilters,
          gaProperty,
        },
      })
      .then((response) => response.data)
  },
  /**
   * SUBSCRIPTIONS
   */
  getStripeCheckoutSession({
    workspaceId,
    back,
    mode = 'subscription',
    type,
    isAnnual = false,
  }) {
    return apiClient
      .post(`/teams/${workspaceId}/stripe-checkout-session`, {
        back,
        mode,
        type,
        isAnnual,
      })
      .then((response) => response.data)
  },
  getStripePortalSession({ workspaceId, back }) {
    return apiClient
      .post(`/teams/${workspaceId}/stripe-portal-session`, {
        back,
      })
      .then((response) => response.data)
  },
  /**
   * PROJECTS
   */
  getProjects(workspaceId) {
    return apiClient
      .get(`/teams/${workspaceId}/projects`)
      .then((response) => response.data)
  },
  getProject(workspaceId, projectId) {
    return apiClient
      .get(`/teams/${workspaceId}/projects/${projectId}`)
      .then((response) => response.data)
  },
  createProject(workspaceId, project) {
    return apiClient
      .post(`/teams/${workspaceId}/projects`, {
        ...project,
        project_goals: project.goals,
        startDate: format(project.start_date, 'yyyy-MM-dd'),
        endDate: format(project.end_date, 'yyyy-MM-dd'),
        newGoal: project.newGoal ? formatGoal(project.newGoal) : null,
      })
      .then((response) => response.data)
  },
  updateProject(workspaceId, project) {
    return apiClient
      .put(`/teams/${workspaceId}/projects/${project.id}`, {
        ...project,
        startDate:
          project.start_date instanceof Date
            ? format(project.start_date, 'yyyy-MM-dd')
            : project.start_date,
        endDate:
          project.end_date instanceof Date
            ? format(project.end_date, 'yyyy-MM-dd')
            : project.end_date,
        project_goals: project.project_goals.map((g) => g.slug),
        categories: project.categories.map((c) => c.slug),
      })
      .then((response) => response.data)
  },
  archiveProject({ projectId, isArchived }) {
    return apiClient
      .put(`/projects/${projectId}/updated-archived-status`, {
        isArchived,
      })
      .then((response) => response.data)
  },
  deleteProject(projectId) {
    return apiClient
      .delete(`/projects/${projectId}`)
      .then((response) => response.data)
  },
  getProjectMetaOptions() {
    return apiClient
      .get('/projects/meta-options')
      .then((response) => response.data)
  },

  /**
   * GOALS
   */
  createGoal(goal, workspaceId) {
    return apiClient
      .post(`/teams/${workspaceId}/goals`, formatGoal(goal))
      .then((response) => response.data)
  },
  updateGoal(goal) {
    return apiClient
      .put(`/goals/${goal.id}`, {
        ...goal,
        goalBenchmarkIds: goal.benchmarks.map((b) => b.value),
        endDate: format(new Date(goal.end_date), 'yyyy-MM-dd'),
        goalCategoryValueTypeId: goal.valueType.value,
        goalCategoryValue: goal.goal_value,
      })
      .then((response) => response.data)
  },
  getGoalMetaOptions(dataSourceId) {
    return apiClient
      .get(`/goals/options?dataSourceId=${dataSourceId}`)
      .then((response) => response.data)
  },
  getFilterValueOptions(dataSourceId, categoryId, filterId, goal, workspaceId) {
    return apiClient
      .get(`/goals/filter-value-options`, {
        params: {
          dataSourceId,
          categoryId,
          filterId,
          goal,
          teamId: workspaceId,
        },
      })
      .then((response) => response.data)
  },
  getGoals(workspaceId, isArchivedRequest = false, page = 1, all = false) {
    return apiClient
      .get(
        `/teams/${workspaceId}/goals?archived=${isArchivedRequest}&page=${page}&all=${all}`,
      )
      .then((response) => (all ? response : response.data))
  },
  getGoal(goalId) {
    return apiClient.get(`/goals/${goalId}`).then((response) => response.data)
  },
  archiveGoal({ goalId, isArchived }) {
    return apiClient
      .put(`/goals/${goalId}/updated-archived-status`, {
        isArchived,
      })
      .then((response) => response.data)
  },
  deleteGoal(goalId) {
    return apiClient
      .delete(`/goals/${goalId}`)
      .then((response) => response.data)
  },
  getGoalReportData({ goalId, start_date, end_date }) {
    return apiClient
      .get(`/goals/${goalId}/report-data`, {
        params: {
          start_date,
          end_date,
        },
      })
      .then((response) => response.data)
  },
  getCsrfToken() {
    return axios
      .get(`${process.env.NEXT_PUBLIC_API_URL}sanctum/csrf-cookie`, {
        withCredentials: true,
      })
      .catch((e) => {
        document.location.href = document.location.href
      })
  },
}
