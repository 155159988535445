import DatePicker from 'react-date-picker/dist/entry.nostyle'
import styled from 'styled-components'
import { differenceInDays } from 'date-fns'
import { useToasts } from 'react-toast-notifications'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { theme } from '../tailwind.config'

const FormDatePicker = ({
  value,
  onChange,
  label,
  required = false,
  disabled,
  onlyFutureDates = true,
}) => {
  const { addToast } = useToasts()
  const handleDateChange = (selectedDate) => {
    if (onlyFutureDates && differenceInDays(selectedDate, new Date()) < 0) {
      addToast('Please select a date in the future.', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })

      return
    }
    onChange(selectedDate)
  }

  return (
    <Wrapper>
      <label className="mb-2 inline-block text-sm">{label}</label>
      <DatePicker
        value={value}
        onChange={handleDateChange}
        className="w-full"
        required={required}
        format="M/d/y"
        clearIcon={null}
        disabled={disabled}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .react-date-picker__wrapper {
    border: 1px solid ${theme.colors.dark[15]};
    padding: 5px;
    border-radius: 6px;
  }

  * {
    font-family: ${theme.fontFamily.sans} !important;
  }
`

export default FormDatePicker
