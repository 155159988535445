import Link from 'next/link'

import { Icon } from '.'
import { getNotificationDateText, getNotificationLink } from '../utils'

const NotificationCard = ({ notification, disableBorderTop, close }) => {
  return (
    <li
      className={`flex flex-1 items-center border-dark-15 ${
        disableBorderTop ? 'border-t-0' : 'border-t'
      }`}>
      <Link href={getNotificationLink(notification)}>
        <a
          className={`text-dark-50 px-3 py-4 hover:bg-dark-5 transition-all flex items-center w-full`}
          onClick={close}>
          <div>
            {notification.team?.image ? (
              <img
                className="w-10 h-10 rounded-full mr-4  object-cover"
                src={notification.team.image}
              />
            ) : (
              <div className="flex items-center justify-center w-10 h-10 bg-dark-5 rounded-full mr-4">
                <img
                  className="w-5 h-5 object-contain"
                  src="/settings_avatar_default.png"
                />
              </div>
            )}
          </div>
          <div className="flex-1">
            <div className="flex">
              <span className="text-lg text-teal-4">
                {`${notification.title}`}
                <span className="ml-2 mr-1">&bull;</span>
                {`${getNotificationDateText(notification.created_at)}`}
              </span>
            </div>

            <p className="text-navy-5">{notification.description}</p>
          </div>
          {!notification.seen && (
            <div className="bg-red-3 rounded-full h-2.5 w-2.5 self-start mt-2" />
          )}
        </a>
      </Link>
    </li>
  )
}

export default NotificationCard
