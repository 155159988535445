import React from 'react'
import PropTypes from 'prop-types'

import { statusColors } from '../utils'

export class StackTooltip extends React.Component {
  static propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    orientation: PropTypes.string,
    datum: PropTypes.object,
  }

  render() {
    const { datum, x, y, label } = this.props

    return (
      <g style={{ pointerEvents: 'none' }}>
        <foreignObject
          x={x}
          y={y}
          width={70}
          height="100"
          style={{ overflow: 'visible' }}>
          <div className="flyout-tooltip bg-white shadow-md py-1 px-2 rounded">
            <div
              className="text-navy-4 flex justify-center flex-col"
              style={{ fontSize: '8px' }}>
              <div className="flex items-start">
                <div
                  className="h-2 w-2 rounded-full"
                  style={{
                    backgroundColor:
                      statusColors[
                        datum.status ? datum.status.toLowerCase() : 'ontrack'
                      ],
                  }}
                />
                <label>
                  <strong className="ml-1">{datum.status}</strong>
                </label>
              </div>
              <span
                className="font-bold text-dark-50"
                style={{ fontSize: '8px' }}>
                {label}
                <span className="text-navy-4 ml-2">{`${datum.y}`}</span>
              </span>
            </div>
          </div>
        </foreignObject>
      </g>
    )
  }
}

export default StackTooltip
