import { useContext, useEffect, useState } from 'react'
import { ReportContext } from '../context/ReportContext'
import {
  Modal,
  CardContainer,
  CardBody,
  CardHeader,
  Icon,
  Button,
  TextInput,
  ContextualMenu,
  WorkspaceSubscriptionGuard
} from '.'
import CloseIcon from '../public/close_circle.svg'
import { useRouter } from 'next/router'
import { GlobalContext } from '../context/GlobalContext'
import XLSX from 'xlsx'
import { format } from 'date-fns'
import SaveIcon from '../public/save.svg'
import DownloadIcon from '../public/download.svg'
import { tzDate } from '../utils'
import { useQuery } from 'react-query'
import Loader from 'react-loader-spinner'
import api from '../services/api'

const ReportTools = ({
  title,
  data,
  filters,
  type,
  download,
  goalId,
  pageCount = 1
}) => {
  const reportContext = useContext(ReportContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const globalContext = useContext(GlobalContext)

  useEffect(() => {
    if (download) {
      setTimeout(() => {
        reportContext.exportPdf(pageCount, title, data, filters)
      }, 1000)
    }
  }, [])

  const getStatusData = () => {
    let datesByStatus = data.data
    let countsByDate = []
    Object.keys(datesByStatus).forEach((status) => {
      datesByStatus[status].forEach((data) => {
        if (!countsByDate[data.date]) {
          countsByDate[data.date] = []
        }
        countsByDate[data.date][status] = data.count
      })
    })
    return Object.keys(countsByDate).map((date) => {
      let row = countsByDate[date]
      return [date, row.ahead, row.ontrack, row.behind]
    })
  }

  const getCountData = (single) => {
    let dailyData = (type == 'Category') ? data.category.daily_data : data.daily_data;
    return Object.keys(dailyData).map((key) => {
      let row = dailyData[key];
      return [
        format(
          new tzDate(
            row.date
          ),
          'MMM d, yyyy',
        ),
        single ? row.single_data : row.cumulative_data
      ]
    })
  }

  const getStatisticsData = () => {
    let key = type.toLowerCase() + 's_meta'
    return [
      ['Current ' + type + 's', data?.[key]?.total],
      ['Open ' + type + 's', data?.[key]?.opened],
      ['Completed ' + type + 's', data?.[key]?.completed],
    ]
  }

  const getSingleStatistics = () => {
    let target = (type == 'Category') ? data.category : data;
    let rows = [
        ['Starting Value', target.start_display],
        [target.is_average ? 'Rolling Average' : 'Current Value', target.end_display]
      ];
      if (type != 'Category'){
        rows.push(['Goal',target.goal_display])
      }
    return rows;
  }

  const getCurrentStatusData = (currentType) => {
    let currentData = {}
    let ahead = 0
    let ontrack = 0
    let behind = 0
    if (!currentType) {
      currentData = data.data
      ahead = currentData['ahead'][currentData['ahead'].length - 1].count
      ontrack = currentData['ontrack'][currentData['ontrack'].length - 1].count
      behind = currentData['behind'][currentData['behind'].length - 1].count
    } else {
      const currentData = {
        ontrack: [],
        behind: [],
        ahead: [],
      }
      data[currentType].forEach((goal) => {
        currentData[
          goal.last_status ? goal.last_status.status : 'ontrack'
        ].push(goal)
      })
      ahead = currentData.ahead.length
      ontrack = currentData.ontrack.length
      behind = currentData.behind.length
    }
    const total = ahead + ontrack + behind

    return [
      ['Ahead', total ? (ahead / total) * 100 + '%' : ''],
      ['On Track', total ? (ontrack / total) * 100 + '%' : ''],
      ['Behind', total ? (behind / total) * 100 + '%' : ''],
      ['', ''],
      [
        'Total Current Success Rate (OnTrack/Ahead)',
        total ? ((ahead + ontrack) / total) * 100 + '%' : '',
      ],
    ]
  }

  const getDetailsData = (currentType) => {
    return data?.[currentType.toLowerCase() + 's']?.map((item) => {
      return [
        item.last_status?.status,
        item.name,
        format(new tzDate(item.start_date), 'MMM d, yyyy'),
        format(new tzDate(item.end_date), 'MMM d, yyyy'),
        item.progress
      ]
    })
  }

  const getHeaders = () => {
    let meta = reportContext.getMeta(filters, title, data)
    let headers = []
    headers.push(['Sunsett.io', '', '', ''])
    headers.push(['', '', '', ''])
    headers.push(['Title', meta.title, '', ''])
    headers.push(['Date Range', meta.dates, '', ''])
    if (meta.goals) {
      headers.push(['Goals', meta.goals, '', ''])
    }
    if (meta.projects) {
      headers.push(['Projects', meta.projects, '', ''])
    }
    if (meta.category) {
      headers.push(['Category', meta.category, '', ''])
    }
    headers.push(['', '', '', ''])
    return headers
  }

  const getStatusSheet = () => {
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      ['Date', 'Ahead', 'On Track', 'Behind'],
      ...getStatusData(),
    ])
  }
  const getCountSheet = () => {
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      ['Date', 'Count'],
      ...getCountData(false),
    ])
  }
  const getStatisticsSheet = () => {
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      ['Type', 'Value'],
      ...getStatisticsData(),
    ])
  }
  const getSingleStatisticsSheet = () => {
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      ['Type', 'Value'],
      ...getSingleStatistics(),
    ])
  }
  const getCurrentStatusSheet = (currentType) => {
    if (!currentType) {
      currentType = null
    }
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      ['Current Status', 'Percentage of Total ' + type + 's'],
      ...getCurrentStatusData(currentType),
    ])
  }
  const getDetailsSheet = (currentType) => {
    if (!currentType) {
      currentType = type
    }
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      [
        'Goal Status',
        currentType + ' Name',
        'Start Date',
        'End Date',
        '% Complete',
      ],
      ...getDetailsData(currentType),
    ])
  }

  const getSingleGoalDetailsSheet = () => {
    return XLSX.utils.aoa_to_sheet([
      ...getHeaders(),
      ['Date', 'Count'],
      ...getCountData(true),
    ])
  }

  const downloadXls = () => {
    var filename = format(new Date(), 'yyyy-MM-dd') + '.xlsx'
    var wb = XLSX.utils.book_new()
    if (type == 'Goal' || type == 'Project') {
      XLSX.utils.book_append_sheet(
        wb,
        getStatusSheet(),
        type + ' Status By Time',
      )
      XLSX.utils.book_append_sheet(
        wb,
        getStatisticsSheet(),
        type + ' Statistics',
      )
      XLSX.utils.book_append_sheet(
        wb,
        getCurrentStatusSheet(),
        'Current ' + type + ' Status',
      )
      XLSX.utils.book_append_sheet(wb, getDetailsSheet(), type + ' Details')
    }
    if (type == 'Single Goal') {
      XLSX.utils.book_append_sheet(wb, getCountSheet(), 'Goal By Time')
      XLSX.utils.book_append_sheet(
        wb,
        getSingleStatisticsSheet(),
        'Goal Statistics',
      )
      XLSX.utils.book_append_sheet(
        wb,
        getSingleGoalDetailsSheet(),
        'Goal Details',
      )
    }
    if (type == 'Category') {
      XLSX.utils.book_append_sheet(wb, getCountSheet(), 'Category By Time')
      XLSX.utils.book_append_sheet(
        wb,
        getSingleStatisticsSheet(),
        'Category Statistics',
      )
      XLSX.utils.book_append_sheet(wb, getDetailsSheet('Goal'), 'Goal Details')
      XLSX.utils.book_append_sheet(
        wb,
        getCurrentStatusSheet('goals'),
        'Current Goal Status',
      )
      XLSX.utils.book_append_sheet(
        wb,
        getDetailsSheet('Project'),
        'Project Details',
      )
      XLSX.utils.book_append_sheet(
        wb,
        getCurrentStatusSheet('projects'),
        'Current Project Status',
      )
    }
    XLSX.writeFile(wb, filename)
  }

  return (
    <div className="relative flex items-center justify-end flex-1">
      <Button
        theme="dark-transparent"
        classes="pl-2 pr-2"
        action={() => setIsModalOpen(true)}
        text="Save"
        className="mr-4"
        IconLeft={() => <Icon icon={SaveIcon} />}
      />

      <ContextualMenu
        label={
          <Button
            theme="dark-transparent"
            classes="pl-2 pr-2"
            onClick={() => setIsModalOpen(true)}
            text="Download"
            IconLeft={() => <Icon icon={DownloadIcon} />}
          />
        }
        relative={true}
        items={[
          {
            text: `PDF`,
            action: (args) => {
              globalContext.track('File Downloaded', {type: 'pdf'});
              reportContext.exportPdf(pageCount, title, data, filters)
            },
          },
          {
            text: `XLS`,
            action: (args) => {
              globalContext.track('File Downloaded', {type: 'xls'});
              downloadXls()
            },
          },
        ]}
      />
      <Modal isOpen={isModalOpen}>
        {isModalOpen ?
        <CreateSavedReport type={type} goalId={goalId} filters={filters} setIsModalOpen={setIsModalOpen}/> : null}
      </Modal>
    </div>
  )
}

const CreateSavedReport = ({setIsModalOpen, filters, type, goalId}) => {
  const reportContext = useContext(ReportContext)
  const [reportName, setReportName] = useState('')
  const router = useRouter()
  const globalContext = useContext(GlobalContext)

  const {
    isLoading,
    error,
    data: workspaceGoalLimit,
  } = useQuery(
    ['report_limits', { workspaceId: router.query?.workspaceId }],
    () =>
      api.checkWorkspaceLimit(router.query.workspaceId, 'max_saved_reports'),
    {
      enabled: !!router.query?.workspaceId,
      onSuccess: (hasAdditionalSavedReportsAvailable) => {
        if (!hasAdditionalSavedReportsAvailable) {
          router.push(
            `/workspaces/${
              router.query.workspaceId
            }/choose-plan?redirected=true&limit=saved_report&back=${encodeURIComponent(
              router.asPath,
            )}`,
          )
        }
      },
    },
  )

  return <WorkspaceSubscriptionGuard>
      {isLoading ? (
        <div className="w-full py-12 flex justify-center items-center">
          <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
        </div>
      ) : (
        <CardContainer
          classes="relative max-w-full max-h-full overflow-auto mx-5 w-124"
          hasHeader>
          <CardHeader
            title="Create Saved Report"
            ButtonComponent={() => (
              <button onClick={() => setIsModalOpen(false)}>
                <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
              </button>
            )}
          />
          <CardBody>
            <TextInput
              label="Report Name"
              placeholder="Ex. Ticket Sales for Location"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
            <div className="flex justify-end">
              <Button
                text="Save"
                disabled={!reportName}
                action={() =>
                  reportContext
                    .saveReport(
                      filters,
                      reportName,
                      type,
                      goalId ? goalId : null,
                    )
                    .then(() => {
                      router.push(
                        `/workspaces/${globalContext.activeWorkspace.id}/reports?type=saved`,
                      )
                    })
                }
              />
            </div>
          </CardBody>
        </CardContainer>)}
        </WorkspaceSubscriptionGuard>
}
export default ReportTools
