import { Modal, CardContainer, Icon, Button } from '.'
import CloseIcon from '../public/close_circle.svg'

const ShopifyStoreAuthorizedModal = ({ isOpen, setIsOpen, isLoggedIn }) => {
  return (
    <Modal isOpen={isOpen}>
      <CardContainer classes="relative w-186 max-w-full max-h-full overflow-auto mx-5">
        <div className="flex justify-end mb-5">
          <button onClick={() => setIsOpen(false)}>
            <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
          </button>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-48 w-48 relative">
            <img src="/data_source_bg_shape.svg" className="w-48 absolute" />
            <img
              src="/shopify.png"
              className="h-20 w-20 absolute top-14 left-16 rounded-lg"
            />
          </div>
          <h2 className="text-2xl font-serif mb-4 text-navy-5">
            You successfully connected your Shopify store!
          </h2>
          <p className="font-light text-navy-5 mb-4">
            {isLoggedIn
              ? `Go to your workspace and add Shopify as a data source to start using your Shopify store!`
              : `Login or create an account to add your Shopify store as a workspace
            data source!`}
          </p>
          <Button text="Close" action={() => setIsOpen(false)} />
        </div>
      </CardContainer>
    </Modal>
  )
}

export default ShopifyStoreAuthorizedModal
