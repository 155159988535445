const TextArea = ({
  value,
  onChange,
  label,
  required = false,
  placeholder,
  inputId = '',
  classes = '',
}) => {
  return (
    <div className={`relative flex flex-col mb-5 max-w-full ${classes || ''}`}>
      <label htmlFor={inputId} className="mb-2 text-sm">
        {label}
      </label>
      <textarea
        name={label}
        id={inputId}
        placeholder={placeholder}
        onChange={onChange}
        className="bg-white p-2 rounded-md border border-dark-15"
        required={required}
        value={value}
      />
    </div>
  )
}

export default TextArea
