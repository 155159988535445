import House from '../public/house.svg'
import Squares from '../public/squares.svg'
import Target from '../public/target.svg'
import Sliders from '../public/sliders.svg'
import List from '../public/list.svg'
import Gear from '../public/gear.svg'
import CircleQuestion from '../public/question_circle.svg'
import Lock from '../public/lock.svg'

export const tips = [
  {
    title: 'How do I create a workspace?',
    description:
      'A workspace is a central location for you and your team to create projects and goals. The number of accessible user accounts, data connects, active projects and goals are dependent on your Sunsett subscription plan.',
  },
  {
    title: 'How do I change my email notification preferences?',
    description:
      'To change your email notifications: Profile > account settings > notifications. Select your preferred email progress digest frequency from the drop down menu and preferred email notification type.',
  },
  {
    title: 'How do I create a new project?',
    description:
      'To create a new project, select Projects Tab > Select Create Projects Button.',
  },
  {
    title: 'How do I add more collaborators to my plan?',
    description:
      'Collaborators can be added in Sunsett Professional or Team plans. To add a collaborator navigate to workspace settings > members tab > send invite.',
  },
  {
    title: 'Can I upgrade my plan at any time?',
    description:
      'Yes. You can upgrade your plan at any time during the month; however, your new plan will not be active until the close of your current billing cycle.',
  },
  {
    title: 'What is a project in Sunsett.io?',
    description:
      'Sunsett projects allow you to review multiple data sets tied to a specific event, conversion action or volume goal for your team, or your individual business. ',
  },
  {
    title: 'How do I customize a goal report?',
    description:
      'To customize a goal report: Navigate to Reports > Goals tab > Category > Pick your category',
  },
  {
    title: 'How do I create a new project?',
    description:
      'To create a new project, select Projects Tab > Select “Create Project” button.',
  },
  {
    title: 'How do I add new data sets to current projects?',
    description:
      'During project set-up select the check mark, to the left of your preferred data set, to initiate goal setting instructions for your project.',
  },
  {
    title: 'Can I change the initial start and end date of my project?',
    description:
      'Select project tab > Navigate to your current project > Select three dots in top right hand corner of project bar > edit project > add your new start and/or end date > update project.',
  },
  {
    title: 'How do I create a new account?',
    description:
      'Navigate to sunsett.io. Select “Launch Sunsett” button in top right hand corner. Select the “Sign-up” button in the top right hand corner. Enter your preferred email credentials, password, first name, last name, company title and team details.',
  },
  {
    title: 'How do I change my profile settings?',
    description:
      'To change your profile settings: Click your profile picture in the top right hand corner > Account settings, Profile.',
  },
  {
    title: 'How do I edit a goal?',
    description:
      'To edit a goal, select Goals Tab > select your Current Goal. Click on the Three Dots, then select Edit Goal. Once Edited, click on Update Goal to save your edit.',
  },
  {
    title: 'Can non-admin users create goals?',
    description:
      "If you're subscribed to a Sunsett Professional or Team plan, you can opt into allowing other users to create goals by simply clicking the Allow non-admins to create goals box in your Workspace Settings.",
  },
  {
    title: 'How do I generate a report?',
    description:
      'To generate a report: Navigate to the Reports tab > select what you want your report for (Project, Goal, or Categories)',
  },
  {
    title: 'How do I generate an individual category report?',
    description:
      'To generate an individual category report, navigate to the Categories section under the Reports tab.',
  },
  {
    title: 'Can I export reports to PDF?',
    description:
      'Yes, you can export reports to PDF or XLS by clicking the Download button at the top right hand corner of your report. ',
  },
  {
    title: 'How do I organize my CSVs?',
    description:
      'To best organize your connected CSVs: navigate to Data Sources > Connected > CSV Manage. You can also customize your CSVs by your Goals: Click on All Goals > Choose your specific Goal.',
  },
  {
    title: 'What are categories?',
    description:
      'Categories can be found by navigating to the Reports tab > Categories. Categories are summaries of your metric goals, and their related data sources. ',
  },
  {
    title: 'How do I connect third party data sources?',
    description:
      'To connect a third party data source: navigate to Data Sources > Add Data Sources > Click your preferred data source.',
  },
]

export const getStartedSteps = [
  {
    title: 'Verify your account',
    description: 'Confirm email',
  },
  {
    title: 'Add data source',
    description: 'Connect your apps and CSVs',
    route: '/data',
    workspaceKey: 'data_sources',
  },
  {
    title: 'Create a project',
    description: 'Start tracking your data',
    route: '/projects',
    workspaceKey: 'projects',
  },
  {
    title: 'Set goals',
    description: 'Determine success metrics',
    route: '/goals',
    workspaceKey: 'goals',
  },
]

export const orgOptions = {
  description: [
    {
      value: 'individual',
      label: 'Individual',
    },
    {
      value: 'analyst',
      label: 'Analyst',
    },
    {
      value: 'marketing team',
      label: 'Marketing Team',
    },
    {
      value: 'product team',
      label: 'Product Team',
    },
    {
      value: 'nonprofit team',
      label: 'Nonprofit Team',
    },
    {
      value: 'design team',
      label: 'Design Team',
    },
    {
      value: 'stage and performance team',
      label: 'Stage and Performance Team',
    },
    {
      value: 'event planning team',
      label: 'Event Planning Team',
    },
    {
      value: 'sales team',
      label: 'Sales Team',
    },
    {
      value: 'consultant',
      label: 'Consultant',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
  size: [
    {
      value: '1-5 people',
      label: '1-5 people',
    },
    {
      value: '6-10 people',
      label: '6-10 people',
    },
    {
      value: '11-19 people',
      label: '11-19 people',
    },
    {
      value: '20-29 people',
      label: '20-29 people',
    },
    {
      value: '30-39 people',
      label: '30-39 people',
    },
    {
      value: '40-49 people',
      label: '40-49 people',
    },
    {
      value: '50+ people',
      label: '50+ people',
    },
  ],
  focus: [
    {
      value: 'social media engagement',
      label: 'Social media engagement',
    },
    {
      value: 'event engagement',
      label: 'Event engagement',
    },
    {
      value: 'product sales',
      label: 'Product sales',
    },
    {
      value: 'ticket sales',
      label: 'Ticket sales',
    },
    {
      value: 'regional engagement',
      label: 'Regional engagement',
    },
    {
      value: 'donor engagement',
      label: 'Donor engagement',
    },
    {
      value: 'website interaction',
      label: 'Website interaction',
    },
    {
      value: 'demographic engagement',
      label: 'Demographic engagement',
    },
  ],
}

export const footer = {
  items: [
    {
      text: 'Support',
      link: 'https://sunsett.io/pages/faqs',
      targetBlank: true,
    },
    {
      text: 'Terms of Use',
      route: '/terms-of-use',
    },
    {
      text: 'Privacy Policy',
      route: '/privacy-policy',
    },
    {
      text: 'About Monicat Data',
      link: 'https://www.monicatdata.com/aboutus',
      targetBlank: true,
    },
  ],
}

export const nav = {
  items: [
    {
      text: 'Dashboard',
      route: '/dashboard',
      icon: House,
    },
    {
      text: 'Data Sources',
      route: '/data',
      icon: Squares
    },
    {
      text: 'Goals',
      route: '/goals',
      icon: Target,
    },
    {
      text: 'Projects',
      route: '/projects',
      icon: List,
    },
    {
      text: 'Reports',
      route: '/reports',
      icon: Sliders,
    },
  ],
  secondaryItems: [
    {
      text: 'Account Settings',
      route: '/account',
      icon: Gear,
    },

    {
      text: 'Help Center',
      link: 'https://sunsett.io/pages/faqs',
      targetBlank: true,
      icon: CircleQuestion,
    },
    {
      text: 'Privacy',
      route: '/privacy-policy',
      icon: Lock,
    },
  ],
}

export const reportNavItems = [
  {
    text: 'Projects',
    key: 'projects',
  },
  {
    text: 'Goals',
    key: 'goals',
  },
  {
    text: 'Categories',
    key: 'categories',
  },
  {
    text: 'Saved',
    key: 'saved',
  },
]

export const settingsNavItems = {
  workspace: [
    {
      text: 'General',
      key: 'general',
    },
    {
      text: 'Members',
      key: 'members',
    },
    {
      text: 'Plan',
      key: 'plan',
    },
  ],
  account: [
    {
      text: 'Profile',
      key: 'profile',
    },
    {
      text: 'Password',
      key: 'password',
    },
    {
      text: 'Notifications',
      key: 'notifications',
    },
  ],
}

export const digestFrequencyOptions = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
]

export const plansContent = [
  {
    name: 'Free',
    cost: {
      monthly: 0,
    },
    limits: [
      'Single user account',
      'Up to 1 data connects',
      'Up to 1 active projects',
      'Up to 1 active goals',
      'Up to 0 saved report',
    ],
    features: ['Data insights', 'Email Alerts', 'Performance Notifications'],
  },{
    name: 'Starter',
    cost: {
      monthly: 23,
    },
    limits: [
      'Single user account',
      'Up to 2 data connects',
      'Up to 2 active projects',
      'Up to 4 active goals',
      'Up to 1 saved report',
    ],
    features: ['Data insights', 'Email Alerts', 'Performance Notifications'],
  },
  {
    name: 'Professional',
    cost: {
      monthly: 49,
    },
    limits: [
      'Up to 2 collaborators',
      'Up to 4 data connects',
      'Up to 4 active goals',
      'Up to 8 active projects',
      'Up to 4 saved reports',
    ],
    features: [
      'Data insights',
      'Email Alerts',
      'Performance Notifications'
    ],
  },
  {
    name: 'Team',
    cost: {
      monthly: 130,
    },
    limits: [
      'Up to 4 collaborators',
      'Unlimited data connects',
      'Unlimited active projects',
      'Unlimited active goals',
      'Unlimited saved reports',
    ],
    features: [
      'Data insights',
      'Email Alerts',
      'Performance Notifications'
    ],
  },
]
