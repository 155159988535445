import { useState, useContext, Fragment } from 'react'
import Loader from 'react-loader-spinner'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import Link from 'next/link'

import {
  CardContainer,
  Button,
  Alert,
  Icon,
  DataSourceSelection,
  GoalForm,
} from '.'
import { GlobalContext } from '../context/GlobalContext'
import api from '../services/api'
import ArrowRightIcon from '../public/arrow_right.svg'
import { addDays, differenceInDays, isSameDay, subDays } from 'date-fns'
import format from 'date-fns/format'

const CreateGoalForm = () => {
  const router = useRouter()
  const globalContext = useContext(GlobalContext)
  const [step, setStep] = useState(1)
  const [isWorking, setIsWorking] = useState(false)
  const [errors, setErrors] = useState([])
  const [isLoadingPastData, setIsLoadingPastData] = useState(false)
  const [autoRetrievedDates, setAutoRetrievedDates] = useState({
    start: '',
    end: '',
  })
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    startDate: new Date(),
    endDate: addDays(new Date(), 1),
    image: '',
    dataSourceId: '',
    goalCategory: '',
    goalCategoryDirection: '',
    goalCategoryValueType: null,
    goalCategoryValue: null,
    goalFilters: [],
    // goalBenchmarkIds: [],
    project: {},
    gaProperty: {},
    gaView: {},
    csv: null,
    targetValue: null,
  })

  const { 
    isLoading: projectLoading, 
    error: projectsError, 
    data: project
  } = useQuery(
    ['project', router.query?.projectId],
    () => api.getProject(router.query.workspaceId, router.query.projectId),
    {
      enabled: !!router.query?.projectId,
      onSuccess: (project) => {
        updateFormValue('project', project);
      }
    },
  )
  
  const {
    isLoading,
    error,
    data: dataSourceOptions,
  } = useQuery(
    ['workspace_data_source_options', router.query?.workspaceId],
    () => api.getWorkspaceDataSourceOptions(router.query.workspaceId),
    {
      enabled: !!router.query?.workspaceId,
    },
  )

  const updateFormValue = (key, val, key2, val2) => {
    setErrors([])
    let newFormValues = {
      ...formValues,
      [key]: val,
    }
    if (key2) {
      newFormValues[key2] = val2
    }

    if (step === 3) {
      setFormValues(newFormValues)
      return
    }

    const updateNewFormValues = () => {
      if (
        dataSourceOptions?.available?.find((opt) => opt.name === 'CSV')?.id !==
        newFormValues.dataSourceId
      ) {
        newFormValues = {
          ...newFormValues,
          csv: null,
        }
      }
      setFormValues(newFormValues)
    }

    // check if the target value needs to be updated
    const isDirectionThatNeedsUpdating =
      newFormValues.goalCategoryDirection?.label == 'Decrease' ||
      newFormValues.goalCategoryDirection?.label == 'Increase' ||
      newFormValues.goalCategoryDirection?.label == 'Retain'

    const valuesThatTriggerUpdateHaveChanged =
      formValues.goalCategoryDirection?.label !=
        newFormValues.goalCategoryDirection?.label ||
      formValues.goalCategory?.label != newFormValues.goalCategory?.label ||
      formValues.goalFilters.length != newFormValues.goalFilters.length ||
      formValues.goalCategoryValue != newFormValues.goalCategoryValue ||
      formValues.goalCategoryValueType?.label !=
        newFormValues.goalCategoryValueType?.label ||
      !isSameDay(formValues.startDate, newFormValues.startDate) ||
      !isSameDay(formValues.endDate, newFormValues.endDate)

    const hasAllNecessaryValues =
      newFormValues.goalCategoryDirection?.label == 'Retain' ||
      (!!newFormValues.goalCategoryValue &&
        !!formValues.goalCategoryValueType &&
        !!newFormValues.goalCategoryValue &&
        formValues.goalCategoryValueType)

    if (isDirectionThatNeedsUpdating) {
      if (valuesThatTriggerUpdateHaveChanged) {
        if (hasAllNecessaryValues) {
          const diffInDays = differenceInDays(
            newFormValues.endDate,
            newFormValues.startDate,
          )
          const previousPeriodEndDate = subDays(newFormValues.startDate, 1)
          const previousPeriodStartDate = subDays(
            previousPeriodEndDate,
            diffInDays,
          )
          setAutoRetrievedDates({
            start: format(previousPeriodStartDate, 'MMM d, yyyy'),
            end: format(previousPeriodEndDate, 'MMM d, yyy'),
          })
          updateNewFormValues()
          setIsLoadingPastData(true)
          api
            .getPastTimePeriodData(
              previousPeriodStartDate,
              previousPeriodEndDate,
              {
                workspaceId: globalContext.activeWorkspace.id,
                ...newFormValues,
              },
            )
            .then((response) => {
              setIsLoadingPastData(false)
              const responseValue = Number(response.value)
              switch (newFormValues.goalCategoryDirection.label) {
                case 'Increase':
                  if (
                    newFormValues.goalCategoryValueType.label == 'Count' ||
                    newFormValues.goalCategoryValueType.label == '$'
                  ) {
                    newFormValues.targetValue =
                      +responseValue + +newFormValues.goalCategoryValue
                  } else if (newFormValues.goalCategoryValueType.label == '%') {
                    newFormValues.targetValue =
                      +responseValue +
                      +responseValue * (newFormValues.goalCategoryValue / 100)
                  }

                  updateNewFormValues()
                  break
                case 'Decrease':
                  if (
                    newFormValues.goalCategoryValueType.label == 'Count' ||
                    newFormValues.goalCategoryValueType.label == '$'
                  ) {
                    newFormValues.targetValue =
                      responseValue - newFormValues.goalCategoryValue
                  } else if (newFormValues.goalCategoryValueType.label == '%') {
                    newFormValues.targetValue =
                      responseValue -
                      responseValue * (newFormValues.goalCategoryValue / 100)
                  }

                  updateNewFormValues()
                  break
                case 'Retain':
                  newFormValues.targetValue = response.value

                  updateNewFormValues()
              }
            })
            .catch((e) => {
              // show error
            })
        } else {
          newFormValues.targetValue = null
          updateNewFormValues()
        }
      }
    } else {
      // if the direction changes and the new one is target, reset
      // the direction value
      if (
        newFormValues.goalCategoryDirection?.label !=
        formValues.goalCategoryDirection?.label
      ) {
        newFormValues.goalCategoryValueType = null
        newFormValues.goalCategoryValue = ''
      }
      newFormValues.targetValue = null
      updateNewFormValues()
    }
  }

  const getTitle = (step) => {
    switch (step) {
      case 2:
        return 'How will we measure the goal?'
      case 3:
        return 'How should we define the goal?'
      default:
        return project ? "Let's create a goal for this project!" : "Let's set a goal!"
    }
  }

  const getFormValidity = () => {
    const googleDataSourceId = dataSourceOptions?.available?.find(
      (opt) => opt.name === 'Google Analytics',
    )?.id
    const csvDataSourceId = dataSourceOptions?.available?.find(
      (opt) => opt.name === 'CSV',
    )?.id
    switch (step) {
      case 1:
        return (
          !!formValues.dataSourceId &&
          (formValues.dataSourceId !== googleDataSourceId ||
            formValues.gaProperty.id?.indexOf('UA-') < 0 ||
            (!!formValues.gaProperty?.id && !!formValues.gaView.id)) &&
          (formValues.dataSourceId !== csvDataSourceId || !!formValues.csv?.id)
        )
      case 2:
        return (
          !!formValues.goalCategory &&
          !!formValues.goalCategoryDirection &&
          ((!!formValues.goalCategoryValue &&
            !!formValues.goalCategoryValueType) ||
            (formValues.goalCategoryDirection?.label === 'Retain' &&
              formValues.targetValue != null)) &&
          !!formValues.startDate &&
          !!formValues.endDate
          // && !!formValues.goalBenchmarkIds.length
        )
      case 3:
        return !!formValues.name
    }
  }

  const handleNext = (e) => {
    e.preventDefault()
    setErrors([])
    setIsWorking(true)

    if (isFormValid) {
      if (step === 3) {
        setIsWorking(true)
        api
          .createGoal(formValues, router.query.workspaceId)
          .then((response) =>
            router.replace(
              `/workspaces/${router.query.workspaceId}/goals/${response.id}`,
            ),
          )
      } else {
        setStep(step + 1)
        setIsWorking(false)
      }
    }
  }

  const isFormValid = getFormValidity()
  return (
    <div className="mt-8 md:mt-12 mb-10">
      <h1 className="text-center mb-20 font-serif text-3xl">
        {getTitle(step)}
      </h1>
      <CardContainer classes="relative w-186 max-w-full mx-auto">
        <div className="-mt-9 flex justify-center z-10 relative">
          <div className="rounded-full bg-yellow-3 py-1 px-4 self-center text-xxs uppercase tracking-wider">
            Step {step + (project ? 2 : 0)} of {project ? 5 : 3}
          </div>
        </div>
        <form
          className={`pt-5 ${isWorking ? 'opacity-50' : ''}`}
          onSubmit={handleNext}>
          {step == 1 && (
            <DataSourceSelection
              title="What data do you want to set a goal for? (Select one)"
              formValues={formValues}
              updateFormValue={updateFormValue}
              isLoading={isLoading}
              dataSourceOptions={dataSourceOptions}
            />
          )}
          {step > 1 && (
            <GoalForm
              formValues={formValues}
              updateFormValue={updateFormValue}
              isFirstStep={step === 2}
              project={project}
              autoRetrievedDates={autoRetrievedDates}
              isLoadingPastData={isLoadingPastData}
            />
          )}
          {!!errors.length && <Alert type="danger" text={errors.join(' ')} />}
          <div
            className={`mt-10 flex items-center ${
              step === 1 ? 'justify-end' : 'justify-between'
            }`}>
            {step !== 1 && (
              <a
                href="#"
                className="text-teal-3 underline"
                onClick={(e) => {
                  e.preventDefault()
                  setStep(step - 1)
                }}>
                Back
              </a>
            )}
            {(project && step == 1) && (
              <Link href={`/workspaces/${router.query.workspaceId}/projects`}>
                <a className="text-dark-50 uppercase text-sm mr-6">Skip</a>
              </Link>
            )}
            <Button
              text={step < 3 ? 'Next' : 'Create Goal'}
              type="submit"
              showSpinner={true}
              disabled={!isFormValid || isWorking}
              action={handleNext}
              IconRight={() => <Icon icon={ArrowRightIcon} fill="white" />}
            />
          </div>
        </form>
        {isWorking && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50 rounded-xl">
            <Loader type="TailSpin" height="72" width="72" color="white" />
          </div>
        )}
      </CardContainer>
    </div>
  )
}

export default CreateGoalForm
