import { useState, Fragment } from 'react'
import subDays from 'date-fns/subDays'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import api from '../services/api'
import { statusLabels } from '../utils'
import {
  CardContainer,
  CardBody,
  ProgressCard,
  PieChart,
  FormSelect,
  DateRangePicker,
  ReportTools,
} from '.'
import StackBarChart from './StackBarChart'
import { tzDate } from '../utils'


const ProjectsReport = () => {
  const router = useRouter()
  const [projectSort, setProjectSort] = useState('title-asc')
  const [filters, setFilters] = useState({
    end_date: router.query?.end || format(new Date(), 'yyyy-MM-dd'),
    start_date:
      router.query?.start || format(subDays(new Date(), 6), 'yyyy-MM-dd'),
    type: 'projects',
    projectCategory: router.query?.category || '',
    projects: router.query?.projects ? router.query.projects.split(',') : [],
  })

  const { isLoading, error, data, refetch } = useQuery(
    [
      'report-data',
      {
        ...filters,
        workspaceId: router.query.workspaceId,
      },
    ],
    () =>
      api.getReportData({
        workspaceId: router.query.workspaceId,
        ...filters,
      }),
    {
      enabled: !!router.query.workspaceId,
    },
  )

  const updateUrl = (newFilters) => {
    router.replace(
      `/workspaces/${router.query.workspaceId}/reports?start=${newFilters.start_date}&end=${newFilters.end_date}&projects=${newFilters.projects}&category=${newFilters.projectCategory}&type=${newFilters.type}`,
    )
  }

  // useEffect(() => updateFilters(filters), [])

  const updateFilters = (updatedFilters) => {
    const newFilters = {
      ...filters,
      ...updatedFilters,
    }

    updateUrl(newFilters)
    setFilters(newFilters)
  }
  return (
    <Fragment>
      <div className={`flex flex-row flex-wrap hidden-pdf`}>
        <FormSelect
          options={[{ label: 'All projects', value: '' }].concat(
            data?.all_projects.map((t) => ({
              label: t.name,
              value: t.id,
            })),
          )}
          classes="w-full md:w-auto min-w-1/4 max-w-full mt-3 md:mt-0 md:mr-3"
          label="Projects"
          onChange={(option) => {
            const isClear = option && option.find((o) => o.value == '')
            updateFilters({
              projects:
                option && !isClear ? option.map((o) => String(o.value)) : [],
            })
          }}
          placeholder="Filter by project"
          isMulti
          value={data?.all_projects
            .filter((p) => filters.projects.includes(String(p.id)))
            .map((t) => ({
              label: t.name,
              value: t.id,
            }))}
        />
        <FormSelect
          options={[{ label: 'All categories', value: '' }].concat(
            data?.project_categories.map((c) => ({
              label: c.name,
              value: c.id,
            })),
          )}
          classes="w-48 max-w-full"
          label="Category"
          onChange={(option) => {
            updateFilters({ projectCategory: option.value })
          }}
          value={[{ label: 'All categories', value: '' }]
            .concat(
              data
                ? data.project_categories.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))
                : [],
            )
            .find((c) => {
              return c.value == filters.projectCategory
            })}
        />
        <div className="w-50 max-w-full mt-3 md:mt-0 md:ml-3">
          <label className="text-sm">Dates</label>
          <div
            className="mt-1 border bg-white flex flex-col justify-center items-start"
            style={{
              borderRadius: 4,
              height: 42,
              borderColor: 'rgba(41, 50, 65, 0.15)',
              marginTop: 5,
            }}>
            <DateRangePicker
              startDate={filters.start_date}
              endDate={filters.end_date}
              updateFilters={updateFilters}
              classes="text-sm flex-1 px-3"
            />
          </div>
        </div>
        {data ? (
          <ReportTools
            title="Projects Report"
            type="Project"
            download={router.query?.download}
            data={data}
            filters={filters}
            pageCount={2}
          />
        ) : null}
      </div>
      <div className="flex flex-col md:flex-row">
        {isLoading || !data ? (
          <div className="px-3 py-56 w-full flex justify-center items-center">
            <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
          </div>
        ) : (
          <Fragment>
            <div className="flex flex-col md:w-2/3 md:pr-5">
              <CardContainer classes="w-full relative" hasHeader>
              <div className="flex items-center px-6 pt-6">
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold">
                      Project Status
                    </span>
                  </div>
                </div>
                
                {data && (
                  <StackBarChart
                    raw={data.data}
                  />
                )}
              </CardContainer>
              <div className="page-2-pdf">
              <CardContainer
                classes="w-full"
                hasHeader
                classes="mt-5 overflow-hidden">
                <div className="p-6 flex">
                  <div className="flex-1 flex items-center">
                    <button
                      className="md:ml-5 text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setProjectSort(
                          projectSort === 'title-asc'
                            ? 'title-desc'
                            : 'title-asc',
                        )
                      }>
                      Title
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            projectSort === 'title-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            projectSort === 'title-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="flex-1 md:px-8"></div>
                  <div className="flex-1 md:px-8"></div>
                  <div className="flex-1 md:px-8">
                    <button
                      className="text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setProjectSort(
                          projectSort === 'progress-asc'
                            ? 'progress-desc'
                            : 'progress-asc',
                        )
                      }>
                      completion rate
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            projectSort === 'progress-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            projectSort === 'progress-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                </div>
                <CardBody disablePadding>
                  {data?.projects?.length == 0 && (
                    <div className="py-12 px-3 border-t border-dark-15 text-center font-serif">
                      No projects to display
                    </div>
                  )}
                  {data?.projects
                    .sort((a, b) => {
                      switch (projectSort) {
                        case 'title-asc':
                          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                        case 'progress-asc':
                          return a.progress - b.progress
                        case 'progress-desc':
                          return b.progress - a.progress
                        case 'title-desc':
                        default:
                          return b.name < a.name ? -1 : b.name > a.name ? 1 : 0
                      }
                    })
                    .map((project) => {
                      return (
                        <ProgressCard
                          data={project}
                          key={project.id}
                          classes="border-t border-dark-15 w-full"
                          isProject
                          startDate={format(
                            new tzDate(project.start_date),
                            'MMM d, yyyy',
                          )}
                          endDate={format(
                            new tzDate(project.end_date),
                            'MMM d, yyyy',
                          )}
                          disableContextualMenu
                        />
                      )
                    })}
                </CardBody>
              </CardContainer>
              </div>
            </div>
            <div className="flex flex-col md:w-1/3 mt-5 md:mt-0">
              <CardContainer classes="w-full" hasHeader>
                <div className="p-6 border-b border-dark-15">
                  <div className="text-2xl text-navy-4  font-bold">
                    {data?.projects_meta?.total}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Current Projects
                  </div>
                </div>
                <div className="p-6 border-b border-dark-15">
                  <div className="text-2xl text-navy-4  font-bold">
                    {data?.projects_meta?.opened}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Opened Projects
                  </div>
                </div>
                <div className="p-6">
                  <div className="text-2xl text-navy-4 font-bold">
                    {data?.projects_meta?.completed}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Completed Projects
                  </div>
                </div>
              </CardContainer>
              <CardContainer classes="w-full" classes="mt-5 overflow-hidden">
                <h2 className="text-sm text-dark-50 uppercase">Success Rate</h2>
                {Object.keys(data.data)
                  .map((key) => {
                    if (data.data[key].length){
                      return {
                        x: statusLabels[key],
                        y: data.data[key][data.data[key].length - 1].count,
                      }
                    } else {
                      return {

                      }
                    }
                  })
                  .filter((d) => d.y !== 0).length == 0 && (
                  <div className="py-20 px-3 text-center font-serif">
                    No data to display!
                  </div>
                )}
                <PieChart
                  data={Object.keys(data.data)
                    .map((key) => {
                      if (data.data[key].length){
                        return {
                          x: statusLabels[key],
                          y: data.data[key][data.data[key].length - 1].count,
                        }
                      } else {
                        return {
  
                        }
                      }
                    })
                    .filter((d) => d.y !== 0)}
                />
              </CardContainer>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default ProjectsReport
