const OnboardLayout = ({ sidebarText, children }) => {
  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <div className="relative md:w-1/3 lg:w-1/4 flex items-center justify-center p-5 h-64 md:h-auto">
        <a href="https://sunsett.io">
          <img
            src="/onboard_sidebar_sm.jpg"
            className="absolute top-0 left-0 w-full h-full object-cover object-left md:hidden"
          />
        </a>
        <a href="https://sunsett.io">
          <img
            src="/onboard_sidebar.jpg"
            className="absolute top-0 left-0 w-full h-full object-cover object-left hidden md:block"
          />
        </a>
        <h1 className="relative text-5xl text-center font-serif w-80 max-w-full">
          {/*sidebarText*/}
        </h1>
      </div>
      <div className="flex flex-1 px-3 md:px-7 pb-8 md:py-16 bg-dark-5 flex-col">
        {children}
      </div>
    </div>
  )
}

export default OnboardLayout
