import Modal from 'react-modal'

import { Icon } from '.'
import CloseIcon from '../public/close_circle.svg'
import Button from './Button'
import CardContainer from './CardContainer'

Modal.setAppElement('#__next')

const WelcomeModal = ({ isOpen, closeModal }) => {
  return (
    <Modal
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: 'rgba(41, 50, 65, 0.5)',
        },
        content: {
          zIndex: 9999,
          background: 'transparent',
          border: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          inset: '1px',
          maxWidth: '100%',
        },
      }}
      isOpen={isOpen}>
      <CardContainer classes="relative">
        <button className="absolute top-4 right-4" onClick={closeModal}>
          <Icon icon={CloseIcon} fill="rgba(41, 50, 65, 0.5)" />
        </button>
        <div className="">
          <h1 className="text-center mb-3 font-serif text-3xl">
            You're all set!
          </h1>
          <p className="text-center mb-3 font-light text-xl">
            Get started using the process outlined below.
          </p>
          <div className="flex flex-col md:flex-row mt-8 w-full md:w-186 max-w-full md:mx-5">
            <div className="mb-4 md:mb-0 border flex-1 items-center md:justify-start border-dark-15 rounded-lg p-4 flex flex-row md:flex-col">
              <img
                className="w-12 md:w-24 max-w-full"
                src="/data_summary.svg"
              />
              <div className="ml-4 md:ml-0">
                <h2 className="md:text-center mb-2 md:mt-5 font-serif text-2xl">
                  Add Data Source
                </h2>
                <p className="md:text-center text-md font-light">
                  Connect your apps.
                </p>
              </div>
            </div>
            <div className="mb-4 md:mb-0 border flex-1 items-center md:justify-start border-dark-15 rounded-lg p-4 flex flex-row md:flex-col md:mx-8">
              <img
                className="w-12 md:w-24 max-w-full"
                src="/project_summary.svg"
              />
              <div className="ml-4 md:ml-0">
                <h2 className="md:text-center mb-2 md:mt-5 font-serif text-2xl">
                  Create Project
                </h2>
                <p className="md:text-center text-md font-light">
                  Start tracking your data.
                </p>
              </div>
            </div>
            <div className="mb-4 md:mb-0 border flex-1 items-center md:justify-start border-dark-15 rounded-lg p-4 flex flex-row md:flex-col">
              <img
                className="w-12 md:w-24 max-w-full"
                src="/goal_summary.svg"
              />
              <div className="ml-4 md:ml-0">
                <h2 className="md:text-center mb-2 md:mt-5 font-serif text-2xl">
                  Set Goals
                </h2>
                <p className="md:text-center text-md font-light">
                  Determine success metrics.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <Button action={closeModal} text="Get Started" />
          </div>
        </div>
      </CardContainer>
    </Modal>
  )
}

export default WelcomeModal
