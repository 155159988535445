import {
  VictoryChart,
  VictoryPie,
  VictoryTooltip,
  VictoryAxis,
  VictoryLabel,
} from 'victory'
import { useState, useEffect } from 'react'

import getTheme from '../services/victory-theme'
import { DonutTooltip } from '.'

const StatusLabel = (props) => {
  return (
    <VictoryLabel
      // dy={-7}
      style={{ backgroundColor: 'white', fontSize: 8 }}
      backgroundStyle={{ fill: 'white' }}
      backgroundPadding={20}
      text={props.text}
    />
  )
}

const PieChart = ({
  data,
  label = 'PROJECTS',
  colorScale = ['#ffe085', '#db9429', '#ffcd38'],
}) => {
  const [chartWidth, setChartWidth] = useState(
    window.innerWidth < 768 ? window.innerWidth : window.innerWidth / 3,
  )
  const updateWidth = (e) => {
    setChartWidth(
      e.target.innerWidth < 768 ? e.target.innerWidth : e.target.innerWidth / 3,
    )
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return data?.length ? (
    <div className="sunsett-pie-chart" style={{ padding: '20px 0' }}>
      <svg style={{ height: 0 }}>
        <defs>
          <filter id="filter" x="0" y="0" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feOffset dx="2" dy="2" result="offsetblur" />
            <feFlood floodColor="#bbbbbb50" />
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
      </svg>
      <VictoryChart>
        <VictoryAxis
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fill: 'transparent' },
          }}
        />
        <VictoryPie
          colorScale={colorScale}
          innerRadius={({ datum }) => 50}
          data={data}
          standalone={false}
          theme={getTheme()}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 16 }}
              // active={true}
              constrainToVisibleArea
              // standalone
              // pointerOrientation="top"
              // flyoutWidth={150}
              // flyoutHeight={50}
              // flyoutPadding={0}
              // flyoutStyle={{
              //   fill: 'white',
              //   stroke: 'rgba(41, 50, 65, 0.15)',
              //   strokeWidth: 1,
              // }}
              // pointerWidth={25}
              // pointerLength={0}
              // cornerRadius={10}
              centerOffset={{ x: 0, y: -50 }}
              text={({ datum }) => ' '}
              style={{ fontSize: 16 }}
              // center={{ x: 150, y: 30 }}
              // dx={150}
              // dy={-100}
              flyoutComponent={<DonutTooltip label={label} />}
              labels={() => ''}
            />
          }
        />
      </VictoryChart>
    </div>
  ) : null
}

export default PieChart
