const CardContainer = ({ children, classes, hasHeader = false }) => {
  return (
    <div
      className={`bg-white rounded-xl border border-dark-15 max-w-full ${classes} ${
        hasHeader ? 'p-0' : 'p-6'
      }`}>
      {children}
    </div>
  )
}

export default CardContainer
