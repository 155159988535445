import { useRouter } from 'next/router'
import { useState, useCallback, useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { useToasts } from 'react-toast-notifications'

import {
  CardContainer,
  CardHeader,
  CardBody,
  Button,
  TextInput,
  FormCheckbox,
  TextArea,
  CsvInstructionsModal,
} from '.'
import { Fragment } from 'react'
import api from '../services/api'
import { GlobalContext } from '../context/GlobalContext'

const AddEditCsvForm = ({ csv }) => {
  const router = useRouter()
  const globalContext = useContext(GlobalContext)
  const { addToast } = useToasts()

  const [localCsv, setLocalCsv] = useState(
    csv || {
      name: '',
      type: 'overwrite',
      description: '',
    },
  )
  const [hasChanged, setHasChanged] = useState(false)
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false)
  const [file, setFile] = useState()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      setFile(acceptedFiles[0])
    }, []),
    onDropRejected: () => {
      addToast(
        'Sorry, there was an error with your CSV upload. Please make sure to follow the directions and try again.',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        },
      )
    },
    accept: '.csv',
    multiple: false,
  })

  const updateLocalCsv = (key, val) => {
    setHasChanged(true)
    setLocalCsv({
      ...localCsv,
      [key]: val,
    })
  }

  const submitForm = () => {
    api.storeCsv(router.query.workspaceId, localCsv, file).then((response) => {
      setHasChanged(false)
      addToast('CSV saved successfully!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })

      if (!localCsv.id) {
        router.replace(`/workspaces/${router.query.workspaceId}/csv`)
      } else {
        setFile(null)
      }
    })
  }

  const onDelete = () => {
    globalContext.setConfirmModal({
      type: 'update',
      payload: {
        isOpen: true,
        onConfirm: () => {
          globalContext.setConfirmModal({
            type: 'update',
            payload: {
              isWorking: true,
            },
          })

          api
            .deleteCsv(router.query.workspaceId, localCsv.id)
            .then((response) => {
              router.replace(`/workspaces/${router.query.workspaceId}/csv`)
              globalContext.setConfirmModal({
                type: 'reset',
                payload: {},
              })
            })
        },
        title: 'Are you sure?',
        description: `${
          localCsv.goals?.length
            ? `${localCsv.goals?.length} goals are currently using this CSV. `
            : ''
        }Once you delete this CSV, all data will be removed from the system permanently.`,
        isWorking: false,
        closeModal: () =>
          globalContext.setConfirmModal({
            type: 'reset',
            payload: {},
          }),
      },
    })
  }

  return (
    <div className="flex items-center justify-center">
      <CardContainer
        classes="relative w-186 max-w-full max-h-full overflow-auto"
        hasHeader>
        <CardHeader
          title={`${csv?.id ? 'Edit' : 'Add'} CSV`}
          ButtonComponent={() => null}
        />
        <CardBody>
          <TextInput
            label="Name"
            onChange={(e) => updateLocalCsv('name', e.target.value)}
            value={localCsv?.name}
          />
          <TextArea
            label="Description"
            placeholder="Describe this data..."
            value={localCsv?.description || ''}
            onChange={(e) => updateLocalCsv('description', e.target.value)}
          />
          <div className="mt-8 mb-3 ">
            <a
              href="#"
              className="text-teal-4 underline"
              onClick={() => setIsInstructionsModalOpen(true)}>
              IMPORTANT: See CSV formatting instructions.
            </a>
          </div>
          {csv?.id && (
            <div className="mb-5">
              <h3 className="text-sm text-navy-4">Data Type</h3>
              <div className="flex">
                <FormCheckbox
                  checked={localCsv.type === 'overwrite'}
                  label="Replace"
                  onChange={() => {
                    updateLocalCsv('type', 'overwrite')
                  }}
                />
                <FormCheckbox
                  checked={localCsv.type === 'supplement'}
                  label="Add to Data Set"
                  classes="pl-4"
                  onChange={() => {
                    updateLocalCsv('type', 'supplement')
                  }}
                />
              </div>
            </div>
          )}
          <div
            {...getRootProps()}
            className="w-full flex flex-col justify-center items-center py-20 rounded-md bg-dark-5 cursor-pointer">
            <FontAwesomeIcon
              icon={faFileCsv}
              className="h-10 w-10 text-teal-4 mb-4"
            />
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="font-sans text-lg">Drop your CSV here</p>
            ) : (
              <Fragment>
                {file?.name ? (
                  <div>
                    <p className="font-sans text-lg font-bold mb-3 text-center">
                      {file.name}
                    </p>
                    <p className="font-sans text-lg text-center">
                      Click SAVE to start your upload!
                    </p>
                  </div>
                ) : (
                  <p className="font-sans text-lg">
                    Drag 'n' drop your CSV here, or click to select files
                  </p>
                )}
              </Fragment>
            )}
          </div>
          <div className="flex justify-end mt-5">
            {localCsv.id ? (
              <Button
                classes="mr-4"
                text="Delete CSV"
                action={onDelete}
                theme="red"
              />
            ) : (
              <Button
                classes="mr-4"
                text="Cancel"
                action={router.back}
                theme="ghost"
              />
            )}
            <Button
              disabled={
                localCsv.id
                  ? !localCsv.name || (file && !localCsv.type) || (!file && localCsv.type)
                  : !localCsv.name || !file
              }
              text="Save"
              action={submitForm}
            />
          </div>
        </CardBody>
      </CardContainer>
      <CsvInstructionsModal
        isOpen={isInstructionsModalOpen}
        close={() => setIsInstructionsModalOpen(false)}
      />
    </div>
  )
}

export default AddEditCsvForm
