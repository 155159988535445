import Link from 'next/link'
import { differenceInWeeks, format } from 'date-fns'

import Icon from './Icon'
import ClockIcon from '../public/clock.svg'
import ListIcon from '../public/list.svg'

import { theme } from '../tailwind.config'
import { TagPill, ProgressBar, LineChart } from '../components'
import { Fragment } from 'react'
import { getRelativeWeeksText, tzDate } from '../utils'

const GoalCard = ({ includeFooter = false, goal, classes = '' }) => {

  return (
    <Link href={`/workspaces/${goal.team_id}/goals/${goal.id}`}>
      <button className={`flex flex-col flex-1 ${classes}`}>
        <div className="p-5 flex flex-col justify-start w-full flex-1">
          <div className="flex">
            {goal.data_source?.image ? (
              <img
                className="w-8 h-8 object-cover rounded-full"
                src={goal.data_source.image}
              />
            ) : (
              <div className="w-8 h-8 flex items-center justify-center bg-dark-5 rounded-lg">
                <img
                  className={`h-4 w-4 object-contain`}
                  src="/settings_avatar_default.png"
                />
              </div>
            )}
            <div className="ml-4 flex-1 text-left flex justify-between items-center">
              <h2 className="text-navy-5 font-medium">{goal.name}
              <div className="text-xxs text-dark-50 uppercase">{goal.category?.name} {(goal.is_average ? ' (Rolling Average)' : '')}</div>
              </h2>
              <span className="text-xs text-dark-50 font-light">
                {Math.abs(
                  differenceInWeeks(
                    new tzDate(goal.start_date),
                    new tzDate(goal.end_date),
                  ),
                )}
                W
              </span>
            </div>
          </div>
          { goal.progress ? <div className="mt-4 flex items-center">
            <ProgressBar
              percentComplete={goal.progress}
            />
            <div className="ml-4">
              <div>{goal.progress}%</div>
            </div>
          </div> : null }
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="font-bold text-sm mr-1 font-navy-5">
                 {goal.end_display|| '-'}
                </span>
                <span className="text-xxs text-dark-50 uppercase">
                {goal.is_average ? 'Rolling Average' : 'Current Value'}
                </span>
              </div>
              { goal.goal_display ? 
                <div
                  className={`text-sm`}>
                    <span className="text-xxs text-dark-50 uppercase mr-1">
                      Goal</span>
                     <span className="font-bold text-sm font-navy-5">
                 {goal.goal_display}
                  </span>
                    
                  </div>
                    
                    : ''}
            </div>
          </div>
          <div className="pt-8 pb-3 flex items-center flex-col justify-center flex-1">
            {goal.daily_data?.length > 0 ? (
              <LineChart
                disableAxes
                disableLabel
                lines={goal.daily_data.map((d) => ({
                  x: format(new Date(d.date), 'MMM d'),
                  y: parseFloat(d.cumulative_data, 10),
                }))}
                isCardChart
                goalValue={goal.goal_end_value}
              />
            ) : (
              <span className="font-serif text-sm py-12">
                Data updates occur at midnight, please return tomorrow for your
                first report on data performance.
              </span>
            )}
          </div>
        </div>
        {includeFooter && (
          <div className="p-4 border-t border-dark-15 flex justify-between items-center w-full">
            <div className="flex items-center">
              {goal.project_id && (
                <Fragment>
                  <Icon icon={ListIcon} fill={theme.colors.dark['50']} />
                  <span className="text-dark-50 ml-1 uppercase text-xxs">
                    {goal.project.name}
                  </span>
                </Fragment>
              )}
            </div>
            <TagPill>
              <Icon
                icon={ClockIcon}
                fill={theme.colors.navy[5]}
                classes="mr-0.5"
              />
              <span className="text-navy-5 uppercase ml-1 text-xxs">
                {getRelativeWeeksText(goal.end_date)}
              </span>
            </TagPill>
          </div>
        )}
      </button>
    </Link>
  )
}

export default GoalCard
