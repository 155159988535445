import Button from './Button'

const TabLayout = ({
  title,
  navItems,
  activeNavItemKey,
  setActiveNavItemKey,
  children,
  button,
}) => {
  return (
    <section className="flex-1">
      <div className="flex justify-between items-center hidden-pdf">
        <h1 className="font-serif text-3xl">{title}</h1>
        {button && (
          <Button
            href={button.href}
            theme={button.theme}
            text={button.text}
            size="small"
            classes="text-sm"
          />
        )}
      </div>
      <nav className="hidden-pdf">
        <ul className="flex border-b border-dark-15">
          {navItems.map((item) => {
            const isActiveItem = item.key === activeNavItemKey
            return (
              <li key={item.key}>
                <button
                  onClick={() => setActiveNavItemKey(item.key)}
                  className={`py-4 px-2 mr-3 relative transition-all ${
                    isActiveItem
                      ? 'text-teal-4 border-teal-4'
                      : 'text-dark-50 border-transparent'
                  }`}>
                  {item.text}
                  <div
                    className={`absolute bottom-0 h-1 transform -translate-x-2/4 left-1/2 bg-teal-4 transition-all ${
                      isActiveItem ? 'w-full' : 'w-0'
                    }`}
                  />
                </button>
              </li>
            )
          })}
        </ul>
      </nav>
      <div className="py-5 md:py-10 tab-wrap">{children}</div>
    </section>
  )
}

export default TabLayout
