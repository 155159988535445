import { useEffect, useState, Fragment } from 'react'
import subDays from 'date-fns/subDays'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import api from '../services/api'
import { statusLabels, getStatusTextClass, tzDate } from '../utils'
import {
  LineChart,
  CardContainer,
  CardBody,
  ProgressCard,
  PieChart,
  FormSelect,
  DateRangePicker,
  ProjectCard,
  ReportTools,
} from '.'
import ChartToggle from './ChartToggle'
import { VictoryBar } from 'victory'
import BarChart from './BarChart'
import CardHeader from './CardHeader'
import DatumCard from './DatumCard'

const GoalsReport = ({ goalId, isDetail, goal }) => {
  const router = useRouter()
  const [activeChartType, setActiveChartType] = useState('line')
  const [goalSort, setGoalSort] = useState('title-asc')
  const [filters, setFilters] = useState({
    end_date:
      router.query?.end || goal.end_date || format(new Date(), 'yyyy-MM-dd'),
    start_date:
      router.query?.start ||
      goal.start_date ||
      format(subDays(new Date(), 6), 'yyyy-MM-dd'),
  })

  const { isLoading, error, data, refetch } = useQuery(
    [
      'report-data',
      {
        ...filters,
        workspaceId: router.query.workspaceId,
      },
    ],
    () =>
      api.getGoalReportData({
        goalId: router.query.goalId,
        ...filters,
      }),
    {
      enabled: !!router.query.workspaceId,
    },
  )

  const updateUrl = (newFilters) => {
    if (isDetail) {
      router.replace(
        `/workspaces/${router.query.workspaceId}/goals/${goalId}?start=${newFilters.start_date}&end=${newFilters.end_date}`,
      )
    }
  }

  const updateFilters = (updatedFilters) => {
    const newFilters = {
      ...filters,
      ...updatedFilters,
    }
    updateUrl(newFilters)
    setFilters(newFilters)
  }

  const listData = data?.daily_data;

  /*const listData = data?.daily_data.filter((d, i) => {
    // Less than 10 days, return data for each day
    // More than 10 but less than 90 days, return data by week
    // More than 90 but less than 548 (365 x 1.5) days, return data by month
    // More than 548, return data by year
    if (data.daily_data.length < 30) {
      return true
    } else if (data.daily_data.length < 180) {
      return i % 7 === 0
    } else if (data.daily_data.length < 1096) {
      return i % 30 === 0
    } else {
      return i % 365 === 0
    }
  })*/

  let filterDatesMatchData = data && 
    data.daily_data && data.daily_data.length > 0 &&
    (new tzDate(filters.start_date).toDateString()) == (new tzDate(data.daily_data[0].date).toDateString()) && 
    (new tzDate(filters.end_date).toDateString()) == (new tzDate(data.daily_data[data.daily_data.length-1].date).toDateString())
  return (
    <Fragment>
      <div className="flex flex-row flex-wrap hidden-pdf">
        <div className="w-50 max-w-full mt-3 mb-3">
          <label className="text-sm">Dates</label>
          <div
            className="mt-1 border bg-white flex flex-col justify-center items-start"
            style={{
              borderRadius: 4,
              height: 42,
              borderColor: 'rgba(41, 50, 65, 0.15)',
              marginTop: 5,
            }}>
            <DateRangePicker
              startDate={filters.start_date}
              endDate={filters.end_date}
              updateFilters={updateFilters}
              classes="text-sm flex-1 px-3"
            />
          </div>
        </div>
        {data ? (
          <ReportTools
            title={goal.name}
            type="Single Goal"
            download={router.query?.download}
            data={data}
            filters={filters}
            goalId={goalId}
          />
        ) : null}
      </div>
      <div className="flex flex-col md:flex-row">
        {isLoading || !data ? (
          <div className="px-3 py-56 w-full flex justify-center items-center">
            <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
          </div>
        ) : (
          <div className="flex flex-col flex-1">
            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col lg:w-2/3 lg:pr-5">
                <CardContainer classes="w-full relative" hasHeader>
                  <div className="px-3 pt-3 flex justify-end absolute w-full z-10 hidden-pdf">
                    <ChartToggle
                      setActiveChartType={setActiveChartType}
                      activeChartType={activeChartType}
                    />
                  </div>
                  {data?.daily_data && data.daily_data.length === 0 && (
                    <div className="font-serif py-24 md:py-40 px-6 text-center">
                      {data.data_collection_started
                        ? 'No data for this period.'
                        : 'Data updates occur at midnight, please return tomorrow for your first report on data performance.'}
                    </div>
                  )}
                  {data?.daily_data && !!data.daily_data.length && (
                    <Fragment>
                      {activeChartType === 'line' ? (
                        <LineChart
                          lines={data.daily_data.map((d, index) => ({
                            x: format(new tzDate(d.date), 'MMM d'),
                            y: parseFloat(d.cumulative_data, 10),
                          }))}
                          goalValue={goal.goal_end_value}
                        />
                      ) : (
                        <BarChart
                          data={data.daily_data.map((d) => ({
                            x: format(new tzDate(d.date), 'MMM d'),
                            y: parseFloat(d.cumulative_data, 10),
                          }))}
                          goalValue={goal.goal_end_value}
                        />
                      )}
                    </Fragment>
                  )}
                </CardContainer>
                {!filterDatesMatchData ? <div class="mt-4 text-sm">Dates Displayed in chart reflect goal date range</div> : null }
              </div>
              <div className="flex flex-col lg:w-1/3 mt-5 lg:mt-0">
                <CardContainer classes="w-full" hasHeader>
                  <div className="p-6 border-b border-dark-15">
                    <div className="text-2xl text-navy-4  font-bold">
                      {data.start_display}
                    </div>
                    <div className="text-dark-50 text-sm uppercase">
                      Starting Value
                    </div>
                  </div>
                  <div className="p-6 border-b border-dark-15">
                    <div
                      className={`text-2xl font-bold`}>
                      {data.end_display}
                    </div>
                    <div className="text-dark-50 text-sm uppercase">
                      {data.is_average ? 'Rolling Average' : 'Current Value'}
                    </div>
                  </div>
                  <div className="p-6">
                    <div
                      className={`text-2xl font-bold`}>
                      {data.goal_display}
                    </div>
                    <div className="text-dark-50 text-sm uppercase">
                      Goal
                    </div>
                  </div>

                </CardContainer>
                {data?.project && (
                  <ProjectCard
                    project={data.project}
                    includeFooter
                    classes="bg-white rounded-xl w-full border-dark-15 border hover:shadow-lg transition-all cursor-pointer mt-6"
                  />
                )}
              </div>
            </div>
            {!!listData?.length && (
              <div className="flex flex-row hidden-pdf">
                <div className="flex flex-col md:w-2/3 md:pr-5">
                  <CardContainer hasHeader classes="overflow-hidden mt-6">
                    {listData.map((d, i) => {
                      return (
                        <DatumCard
                          datum={d}
                          classes={i === 0 ? '' : 'border-dark-15 border-t'}
                          key={`datum-card__${i}`}
                        />
                      )
                    })}
                  </CardContainer>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default GoalsReport
