const Alert = ({ type = 'danger', text }) => {
  const getClasses = (type) => {
    switch (type) {
      case 'danger':
        return 'bg-red-1 text-red-3 border-red-2'
    }
  }
  return (
    <div className={` border rounded-md px-3 py-2 mb-4 ${getClasses(type)}`}>
      {text}
    </div>
  )
}

export default Alert
