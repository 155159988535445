import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { Icon } from '../components'
import { GlobalContext } from '../context/GlobalContext'

const NavItem = ({ item, isRelativeToWorkspace = false }) => {
  const router = useRouter()
  const isCurrent = router.pathname.includes(item.route)
  const globalContext = useContext(GlobalContext)

  return (
    <li className="flex flex-1 items-center">
      {item.route ? (
        <Link
          href={`${
            isRelativeToWorkspace
              ? `/workspaces/${globalContext.activeWorkspace?.id}`
              : ''
          }${item.route}`}>
          <a
            className={`${
              isCurrent ? 'text-teal-4' : 'text-navy-5'
            } px-3 py-4 hover:bg-dark-5 transition-all flex items-center w-full`}>
            <Icon icon={item.icon} fill={isCurrent ? '#0b9588' : '#293241'} />
            <span className="ml-2">{item.text}</span>
          </a>
        </Link>
      ) : (
        <a
          href={item.link}
          target={item.targetBlank ? '_blank' : ''}
          className={`${
            isCurrent ? 'text-teal-4' : 'text-navy-5'
          } px-3 py-4 hover:bg-dark-5 transition-all flex items-center w-full`}>
          <Icon icon={item.icon} fill={isCurrent ? '#0b9588' : '#293241'} />
          <span className="ml-2">{item.text}</span>
        </a>
      )}
    </li>
  )
}

export default NavItem
