import { useState } from 'react'
import { getRandomId } from '../utils'

const TextInput = ({
  onChange,
  label,
  placeholder,
  id,
  type = 'text',
  required,
  value,
  classes = '',
  disabled = false,
  onKeyDown = () => null,
}) => {
  const [inputType, setInputType] = useState(type)
  const [showPasswordVisibilityToggle] = useState(type === 'password')
  const inputId = id || getRandomId()

  return (
    <div className={`relative flex flex-col mb-5 max-w-full ${classes}`}>
      <label htmlFor={inputId} className="mb-2 text-sm">
        {label}
      </label>
      <input
        type={inputType}
        name={label}
        id={inputId}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={`p-2 rounded-md border border-dark-15 ${
          disabled ? 'bg-dark-5 bg-dark-input' : 'bg-white '
        }`}
        required={required}
        value={value}
        disabled={disabled}
      />
      {showPasswordVisibilityToggle && (
        <a
          href="#"
          className="absolute right-3 bottom-3"
          onClick={(e) => {
            e.preventDefault()
            setInputType(inputType === 'password' ? 'text' : 'password')
          }}>
          <img src="/password-eye.svg" />
        </a>
      )}
    </div>
  )
}

export default TextInput
