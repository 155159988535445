import React, { useContext, Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Cookies from 'js-cookie'

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  })
}
const ErrorBoundaryComponent = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : ({ children }) => <div>{children}</div>

import {
  AppNavigation,
  AppFooter,
  VerifyEmailPrompt,
  ConfirmModal,
} from '../components'
import { GlobalContext } from '../context/GlobalContext'
import api from '../services/api'

const UNSECURED_PATHNAMES = [
  '/',
  '/onboard',
  '/reset-password/[token]',
  '/about',
  '/forgot-password',
  '/privacy-policy',
  '/request-reset-password',
  '/terms-of-use',
  '/email-verify/[token]',
  '/shopify-auth',
]

const AppLayout = ({ children }) => {
  const globalContext = useContext(GlobalContext)
  const router = useRouter()
  const isInvitationPage =
    router.pathname == '/invitation/[invitationId]/respond'

  useEffect(() => {
    if (
      router.query?.workspaceId &&
      (!globalContext.activeWorkspace?.id ||
        router.query.workspaceId != globalContext.activeWorkspace.id)
    ) {
      api
        .getWorkspace(router.query?.workspaceId)
        .then((response) => globalContext.setActiveWorkspace(response))
        .catch(globalContext.handleFetchError)
    }

    // this query param comes from the home page or somewhere
    // no in the app where they indicate they're interested
    // in a particular plan
    if (router.query?.referralPlanId) {
      const twentyMinutesFromNow = new Date(
        new Date().getTime() + 20 * 60 * 1000,
      )
      Cookies.set('sunsett_referral_plan_id', router.query.referralPlanId, {
        expires: twentyMinutesFromNow,
      })
    }
  }, [router.query])

  if (!globalContext.user && !UNSECURED_PATHNAMES.includes(router.pathname)){
    router.push('/?next=' + router.asPath);
  }
  return (
    <ErrorBoundaryComponent>
      <div className="font-sans text-navy-5">
        {UNSECURED_PATHNAMES.includes(router.pathname) ? (
          <Fragment>{children}</Fragment>
        ) : (
          <div className="flex flex-col bg-dark-5 min-h-screen bg-pdf-white">
            <AppNavigation />
            <div className="px-3 md:px-6 flex-1">
              <div className="flex-1 container mx-auto px-3 md:px-0 py-10">
                {(globalContext.user?.email_verified_at ||
                  isInvitationPage) && <Fragment>{children}</Fragment>}
                {globalContext.user &&
                  !globalContext.user.email_verified_at &&
                  !isInvitationPage && (
                    <VerifyEmailPrompt token={router.query?.token} />
                  )}
              </div>
            </div>
            <AppFooter />
            <ConfirmModal confirmModal={globalContext.confirmModal} />
          </div>
        )}
      </div>
    </ErrorBoundaryComponent>
  )
}

export default AppLayout
