import {
  VictoryStack,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
} from 'victory'
import { format } from 'date-fns'

import getTheme from '../services/victory-theme'
import { getYAxisValusFromMax } from '../utils'

import { StackTooltip } from '.'
import { tzDate } from '../utils'

const StackBarChart = ({ raw, label = 'Projects' }) => {   
  const transformDataForBarChart = (raw) => {
    const formattedData = {}
    Object.keys(raw).forEach((key) => {
      formattedData[key] = raw[key].map((d) => ({
        x: format(new tzDate(d.date), 'MMM d'),
        y: d.count,
        status: key.charAt(0).toUpperCase() + key.substr(1),
      }))
    })
    return formattedData
  }
  
  const xTickFormat = (tick) => {
    if (Object.values(data)[0].length < 7) {
      return tick
    }

    const increment = Math.ceil(Object.values(data)[0].length / 8)
    let index = null
    Object.values(data)[0].forEach((d, i) => {
      if (d.x == tick) {
        index = i
      }
    })
    return index != null && index % increment === 0
      ? tick
      : ''
  }

  const getCountsByDate = (raw) => {
    let countsByDate = {};
    Object.keys(raw).forEach((status, i) => {
      let countsForStatus = raw[status];
      countsForStatus.forEach((dayEntry) => {
        if (countsByDate[dayEntry.date]){
          countsByDate[dayEntry.date]+= dayEntry.count
        } else {
          countsByDate[dayEntry.date] = dayEntry.count;
        }
      });
    });
    return countsByDate;
  }

  const getMaxCount = (countsByDate) => {
    return (Math.max(...Object.values(countsByDate)));
  }

  const yAxisValues = () => {
    const countsByDate  = getCountsByDate(raw);
    const maxCount = getMaxCount(countsByDate);
    return getYAxisValusFromMax(maxCount);
  }

  const getDisplayData = (data) => {
    if (data.length <= 10) {
      return data
    } else if (data.length <= 90) {
      return data.filter((d, i) => {
        return i % 7 === 0
      })
    } else if (data.length <= 548) {
      return data.filter((d, i) => {
        return i % 30 === 0
      })
    } else {
      return data.filter((d, i) => {
        return i % 365 === 0
      })
    }
  }

  let data = transformDataForBarChart(raw);

  return (
   <div className="sunsett-bar-chart">

    <VictoryChart
      theme={getTheme()}
      domainPadding={{ x: [10, 10] }}
      style={{
        parent: {
          height: 'auto',
        },
      }}>
      <VictoryAxis
        crossAxis
        tickFormat={(t) => xTickFormat(t)}
        style={{
          axis: { stroke: 'transparent' },
          grid: {
            fill: 'none',
            stroke: 'transparent',
            pointerEvents: 'painted',
          },
        }}
      />
      <VictoryAxis dependentAxis tickValues={yAxisValues()} />
      <VictoryStack colorScale={['#ffcd38', '#db9429', '#ffe085']}>
        {getDisplayData(Object.values(data)).map((d, i) => (
          <VictoryBar
            data={d}
            key={`bar-chart-${i}`}
            style={{
              data: {
                strokeWidth: 1,
                stroke: 'white',
              },
            }}
            stroke="red"
            labels={({ datum }) => `${datum.status}: ${datum.y}`}
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                flyoutComponent={<StackTooltip label={label} />}
                labels={() => ''}
              />
            }
          />
        ))}
      </VictoryStack>
    </VictoryChart>
    </div>
  )
}

export default StackBarChart
