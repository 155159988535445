import { useEffect, useState, Fragment } from 'react'
import subDays from 'date-fns/subDays'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import api from '../services/api'
import { statusLabels, tzDate } from '../utils'
import {
  CardContainer,
  CardBody,
  ProgressCard,
  PieChart,
  FormSelect,
  StackBarChart,
  DateRangePicker,
  ReportTools,
} from '.'

const GoalsReport = () => {
  const router = useRouter()
  const [goalSort, setGoalSort] = useState('title-asc')
  const [filters, setFilters] = useState({
    end_date: router.query?.start_date || format(new Date(), 'yyyy-MM-dd'),
    start_date:
      router.query?.end_date || format(subDays(new Date(), 6), 'yyyy-MM-dd'),
    type: 'goals',
    goalCategory: router.query?.category || '',
    goals: router.query?.goals ? router.query.goals.split(',') : [],
    projects: router.query?.projects ? router.query.projects.split(',') : [],
  })

  const { isLoading, error, data, refetch } = useQuery(
    [
      'report-data',
      {
        ...filters,
        workspaceId: router.query.workspaceId,
      },
    ],
    () =>
      api.getReportData({
        workspaceId: router.query.workspaceId,
        ...filters,
      }),
    {
      enabled: !!router.query.workspaceId,
    },
  )

  const updateUrl = (newFilters) => {
    router.replace(
      `/workspaces/${router.query.workspaceId}/reports?start=${newFilters.start_date}&end=${newFilters.end_date}&goals=${newFilters.goals}&projects=${newFilters.projects}&category=${newFilters.goalCategory}&type=${newFilters.type}`,
    )
  }

  // useEffect(() => updateUrl(filters), [])

  const updateFilters = (updatedFilters) => {
    const newFilters = {
      ...filters,
      ...updatedFilters,
    }
    updateUrl(newFilters)
    setFilters(newFilters)
  }

  return (
    <Fragment>
      <div className="flex flex-row flex-wrap hidden-pdf">
        <FormSelect
          options={data?.all_goals.map((t) => ({
            label: t.name,
            value: t.id,
          }))}
          classes="w-full md:w-auto min-w-1/4 max-w-full mt-3 md:mt-0 md:mr-3"
          label="Goals"
          onChange={(option) => {
            const isClear = option?.find((o) => o.value == '')
            updateFilters({
              goals:
                option && !isClear ? option.map((o) => String(o.value)) : [],
            })
          }}
          isMulti
          value={data?.all_goals
            .filter((p) => filters.goals.includes(String(p.id)))
            .map((t) => ({
              label: t.name,
              value: t.id,
            }))}
        />
        <FormSelect
          options={[{ label: 'All projects', value: '' }].concat(
            data?.all_projects.map((t) => ({
              label: t.name,
              value: t.id,
            })),
          )}
          classes="w-full md:w-auto min-w-1/4 max-w-full mt-3 md:mt-0 md:mr-3"
          label="Projects"
          onChange={(option) => {
            const isClear = option?.find((o) => o.value == '')
            updateFilters({
              projects:
                option && !isClear ? option.map((o) => String(o.value)) : [],
            })
          }}
          placeholder="Filter by project"
          isMulti
          value={data?.all_projects
            .filter((p) => filters.projects.includes(String(p.id)))
            .map((t) => ({
              label: t.name,
              value: t.id,
            }))}
        />
        <FormSelect
          options={[{ label: 'All categories', value: '' }].concat(
            data?.goal_categories.map((c) => ({
              label: c.name,
              value: c.id,
            })),
          )}
          classes="w-48 max-w-full"
          label="Category"
          onChange={(option) => {
            updateFilters({ goalCategory: option.value })
          }}
          value={[{ label: 'All categories', value: '' }]
            .concat(
              data?.goal_categories.map((c) => ({
                label: c.name,
                value: c.id,
              })),
            )
            .find((c) => {
              return c && c.value == filters.goalCategory
            })}
        />
        <div className="w-50 max-w-full mt-3 md:mt-0 md:ml-3">
          <label className="text-sm">Dates</label>
          <div
            className="mt-1 border bg-white flex flex-col justify-center items-start"
            style={{
              borderRadius: 4,
              height: 42,
              borderColor: 'rgba(41, 50, 65, 0.15)',
              marginTop: 5,
            }}>
            <DateRangePicker
              startDate={filters.start_date}
              endDate={filters.end_date}
              updateFilters={updateFilters}
              classes="text-sm flex-1 px-3"
            />
          </div>
        </div>
        {data ? (
          <ReportTools
            title="Goals Report"
            type="Goal"
            download={router.query?.download}
            data={data}
            filters={filters}
            pageCount={2}
          />
        ) : null}
      </div>
      <div className="flex flex-col md:flex-row">
        
        {isLoading || !data ? (
          <div className="px-3 py-56 w-full flex justify-center items-center">
            <Loader type="TailSpin" height="72" width="72" color="#0b9588" />
          </div>
        ) : (
          <Fragment>
            <div className="flex flex-col md:w-2/3 md:pr-5">
              <CardContainer classes="w-full relative" hasHeader>
              <div className="flex items-center px-6 pt-6">
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold">
                      Goal Status
                    </span>
                  </div>
                </div>
                
                {data && (
                      <StackBarChart
                        label="Goals"
                        raw={data.data}/>
                )}
              </CardContainer>
              <div className="page-2-pdf">
              <CardContainer
                hasHeader
                classes="mt-5 overflow-hidden">
                <div className="p-6 flex">
                  <div className="flex-1 flex items-center">
                    <button
                      className="md:ml-5 text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setGoalSort(
                          goalSort === 'title-asc' ? 'title-desc' : 'title-asc',
                        )
                      }>
                      Title
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            goalSort === 'title-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            goalSort === 'title-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="flex-1 md:px-8"></div>
                  <div className="flex-1 md:px-8"></div>
                  <div className="flex-1 md:px-8">
                    <button
                      className="text-xs text-dark-50 uppercase flex"
                      onClick={() =>
                        setGoalSort(
                          goalSort === 'progress-asc'
                            ? 'progress-desc'
                            : 'progress-asc',
                        )
                      }>
                      completion rate
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`h-2 mr-6 ${
                            goalSort === 'progress-asc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`h-2 mr-6 ${
                            goalSort === 'progress-desc'
                              ? 'text-navy-5'
                              : 'text-dark-15'
                          } ml-2`}
                        />
                      </div>
                    </button>
                  </div>
                </div>
                <CardBody disablePadding>
                  {data?.goals?.length == 0 && (
                    <div className="py-12 px-3 border-t border-dark-15 text-center font-serif">
                      No goals to display!
                    </div>
                  )}
                  {data?.goals
                    .sort((a, b) => {
                      switch (goalSort) {
                        case 'title-asc':
                          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                        case 'progress-asc':
                          return a.progress - b.progress
                        case 'progress-desc':
                          return b.progress - a.progress
                        case 'title-desc':
                        default:
                          return b.name < a.name ? -1 : b.name > a.name ? 1 : 0
                      }
                    })
                    .map((goal) => {
                      return (
                        <ProgressCard
                          data={goal}
                          key={goal.id}
                          classes="border-t border-dark-15 w-full"
                          startDate={format(
                            new tzDate(goal.start_date),
                            'MMM d, yyyy',
                          )}
                          endDate={format(
                            new tzDate(goal.end_date),
                            'MMM d, yyyy',
                          )}
                          disableContextualMenu
                        />
                      )
                    })}
                </CardBody>
              </CardContainer>
              </div>
            </div>
            <div className="flex flex-col md:w-1/3 mt-5 md:mt-0">
              <CardContainer classes="w-full" hasHeader>
                <div className="p-6 border-b border-dark-15">
                  <div className="text-2xl text-navy-4  font-bold">
                    {data?.goals_meta?.total}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Current Goals
                  </div>
                </div>
                <div className="p-6 border-b border-dark-15">
                  <div className="text-2xl text-navy-4  font-bold">
                    {data?.goals_meta?.opened}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Opened Goals
                  </div>
                </div>
                <div className="p-6">
                  <div className="text-2xl text-navy-4 font-bold">
                    {data?.goals_meta?.completed}
                  </div>
                  <div className="text-dark-50 text-sm uppercase">
                    Completed Goals
                  </div>
                </div>
              </CardContainer>
              <CardContainer classes="w-full" classes="mt-5 overflow-hidden">
                <h2 className="text-sm text-dark-50 uppercase">Success Rate</h2>
                {Object.keys(data.data)
                  .map((key) => {
                    return {
                      x: statusLabels[key],
                      y: data.data[key][data.data[key].length - 1].count,
                    }
                  })
                  .filter((d) => d.y !== 0).length == 0 && (
                  <div className="py-20 px-3 text-center font-serif">
                    No data to display!
                  </div>
                )}
                <PieChart
                  data={Object.keys(data.data)
                    .map((key) => {
                      return {
                        x: statusLabels[key],
                        y: data.data[key][data.data[key].length - 1].count,
                      }
                    })
                    .filter((d) => d.y !== 0)}
                />
              </CardContainer>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default GoalsReport
