import { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

import { GlobalContext } from '../context/GlobalContext'
import { Button, GoogleConnectButton } from '.'
import api from '../services/api'

const DataConnectButton = ({
  dataSource,
  isConnected,
  disconnectDataSource,
  connectDataSource,
  shopifyStoreSlug,
  disabled,
  canAddConnection,
  needsReconnect,
}) => {
  const globalContext = useContext(GlobalContext)
  const router = useRouter()

  const getButtonText = (service) => {
    if (isConnected) {
      if (needsReconnect) {
        return 'Reconnect'
      }
      return 'Disconnect'
    }
    return `Sign In With ${service}`
  }

  const redirectToChoosePlan = () => {
    router.push(
      `/workspaces/${
        router.query.workspaceId
      }/choose-plan?redirected=true&limit=data_connection&back=${encodeURIComponent(
        router.asPath,
      )}`,
    )
  }

  switch (dataSource.name) {
    case 'Google Analytics':
      return (
        <GoogleConnectButton
          disconnectDataSource={(args) =>
            disconnectDataSource({
              ...args,
              dataSourceId: dataSource.id,
            })
          }
          canAddConnection={canAddConnection}
          needsReconnect={needsReconnect}
          connectDataSource={(args) =>
            canAddConnection || needsReconnect
              ? connectDataSource({
                  ...args,
                  dataSourceId: dataSource.id,
                })
              : redirectToChoosePlan()
          }
          isConnected={isConnected}
          text={getButtonText('Google')}
        />
      )
    case 'Eventbrite':
      return (
        <Button
          text={getButtonText('Eventbrite')}
          theme={isConnected ? 'red' : 'light-teal'}
          size="small"
          action={
            isConnected && !needsReconnect
              ? (args) =>
                  disconnectDataSource({
                    ...args,
                    dataSourceId: dataSource.id,
                  })
              : () => {
                  if (canAddConnection || needsReconnect) {
                    localStorage.setItem(
                      'sunsett_eventbrite_connect_team_id',
                      globalContext.activeWorkspace.id,
                    )
                    document.location.href = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=${process.env.NEXT_PUBLIC_EVENTBRITE_API_KEY}&redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/eventbrite-auth`
                  } else {
                    redirectToChoosePlan()
                  }
                }
          }
        />
      )
    case 'Shopify':
      return (
        <Button
          disabled={disabled}
          text={getButtonText('Shopify')}
          theme={isConnected ? 'red' : 'light-teal'}
          size="small"
          action={
            isConnected && !needsReconnect
              ? (args) =>
                  disconnectDataSource({
                    ...args,
                    dataSourceId: dataSource.id,
                  })
              : () => {
                  if (canAddConnection || needsReconnect) {
                    api
                      .getNonce(globalContext.activeWorkspace.id, dataSource.id)
                      .then((response) => {
                        localStorage.setItem(
                          'sunsett-shopify-team-nonce',
                          response.nonce,
                        )
                        document.location.href =
                          process.env.NEXT_PUBLIC_SHOPIFY_STORE_URL
                        // document.location.href = `https://${shopifyStoreSlug}.myshopify.com/admin/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_SHOPIFY_API_KEY}&scope=read_customers,read_products,read_customers,read_orders,read_locations,read_shipping,read_fulfillments&redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/shopify-auth&state=${response.nonce}`
                      })
                      .catch(globalContext.handleFetchError)
                  } else {
                    redirectToChoosePlan()
                  }
                }
          }
        />
      )
    // default:
    //   return (
    //     <Button
    //       disabled={disabled}
    //       text={getButtonText()}
    //       theme={isConnected ? 'red' : 'light-teal'}
    //       size="small"
    //       action={
    //         isConnected
    //           ? disconnectDataSource
    //           : canAddConnection
    //           ? connectDataSource
    //           : redirectToChoosePlan
    //       }
    //     />
    //   )
  }
}

export default DataConnectButton
