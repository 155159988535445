import { Fragment } from 'react'
import GoogleLogin from 'react-google-login'

import { Button } from '.'

const GoogleConnectButton = ({
  isConnected,
  disconnectDataSource,
  connectDataSource,
  canAddConnection,
  text,
  needsReconnect,
}) => {
  return (
    <GoogleLogin
      clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
      scope="https://www.googleapis.com/auth/analytics.readonly"
      prompt="consent"
      responseType="code"
      render={(renderProps) => (
        <Fragment>
          {isConnected && !needsReconnect ? (
            <Button
              text={text}
              theme="red"
              size="small"
              action={disconnectDataSource}
            />
          ) : (
            <button
              class="w-56"
              onClick={
                canAddConnection || needsReconnect
                  ? renderProps.onClick
                  : connectDataSource
              }>
              <img
                src="/btn_google_signin_light_normal_web@2x.png"
                alt="Sign in With Google"
              />
            </button>
          )}
        </Fragment>
      )}
      onSuccess={(data) => connectDataSource(data)}
      onFailure={(fail) => console.log('fail', fail)}
      cookiePolicy={'single_host_origin'}
    />
  )
}

export default GoogleConnectButton
