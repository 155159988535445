import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { format } from 'date-fns'

import { ProgressBar, ContextualMenu, Icon, LineChart } from '.'
import PencilIcon from '../public/pencil.svg'
import TrashIcon from '../public/trash.svg'

import { theme } from '../tailwind.config'
import { statusColors } from '../utils'
import { Fragment } from 'react'
import { tzDate } from '../utils'

const ProgressCard = ({
  data,
  isProject = false,
  classes,
  onDelete,
  goal,
  goalLabel,
  startDate,
  endDate,
  isExpandable,
  disableContextualMenu = false,
  dataSourceImage,
  currentValue,
  currentValueLabel
}) => {
  const router = useRouter()
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <Link
      href={`/workspaces/${router.query?.workspaceId}/${
        isProject ? 'projects' : 'goals'
      }/${data.id}`}>
      <button className={`bg-white relative ${classes}`}>
        <div className="p-5 flex items-center justify-between w-full flex-wrap md:flex-nowrap">
          {dataSourceImage && (
            <img
              className="w-8 h-8 object-cover rounded-full mr-3"
              src={data.data_source.image}
            />
          )}
          <div className="flex items-center md:flex-2 w-full md:w-36">
            <div
              className="h-3 w-3 rounded-full"
              style={{
                backgroundColor:
                  statusColors[
                    data.last_status ? data.last_status.status : 'ontrack'
                  ],
              }}
            />
            <h3 className="ml-3 font-bold text-left">{data.name}</h3>
          </div>
          {currentValue && currentValueLabel ? (
            <div className="flex flex-col items-start md:px-8 py-3 md:py-0 md:flex-1 w-1/2 md:w-auto">
              <h4 className="text-dark-50 text-xs uppercase">{currentValueLabel}</h4>
              <p className="font-bold">{currentValue}</p>
            </div>
          ) : null}
          {goal && goalLabel ? (
            <div className="flex flex-col items-start md:px-8 py-3 md:py-0 md:flex-1 w-1/2 md:w-auto">
              <h4 className="text-dark-50 text-xs uppercase">{goalLabel}</h4>
              <p className="font-bold">{goal}</p>
            </div>
          ) : null}
          {startDate && (
            <div className="flex flex-col items-start md:px-8 pb-3 pt-6 md:pt-0 md:py-0 md:flex-1 w-1/2 md:w-auto">
              <h4 className="text-dark-50 text-xs uppercase">Start</h4>
              <p className="font-bold text-left">{startDate}</p>
            </div>
          )}

          {endDate && (
            <div className="flex flex-col items-start md:px-8 pb-3 pt-6 md:pt-0 md:py-0 md:flex-1 w-1/2 md:w-auto">
              <h4 className="text-dark-50 text-xs uppercase">End</h4>
              <p className="font-bold text-left">{endDate}</p>
            </div>
          )}
          <div className="md:flex-1 md:px-8 py-3 md:py-0 w-full md:w-auto">
          {data.progress ? 
            <Fragment>
              <div className="flex justify-between mb-2">
              <span className="uppercase text-xs text-dark-50">Progress</span>
              <span className="font-bold">
                {data.progress}%
              </span>
            </div>
            <ProgressBar
              percentComplete={data.progress > 100 ? 100 : data.progress}
            /> </Fragment>: null}
          </div> 
          {isExpandable && (
            <button
              className={`flex items-center mr-4 mt-3 md:mt-0 p-1 px-3 rounded-full border  transition-all text-navy-4 ${
                isExpanded
                  ? 'bg-white border-navy-4'
                  : 'bg-dark-15 border-dark-15'
              }`}
              onClick={(e) => {
                e.stopPropagation()
                setIsExpanded(!isExpanded)
              }}>
              {isExpanded ? 'Collapse' : 'Expand'}
              <img
                src="/chevron_down.svg"
                className={`ml-2 transition-all transform ${
                  isExpanded ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </button>
          )}
        </div>
        {isExpandable && (
          <div
            className={`transition-all overflow-hidden flex justify-end ${
              isExpanded ? 'max-h-full' : 'max-h-0'
            }`}>
            <div className="md:w-5/6">
              <LineChart
                 goalValue={goal.goal_end_value}
                lines={data.daily_data.map((d) => ({
                  x: format(new tzDate(d.date), 'MMM d'),
                  y: parseFloat(d.cumulative_data, 10),
                }))}
              />
            </div>
          </div>
        )}
        {!disableContextualMenu && (
          <ContextualMenu
            classes="top-3 right-0"
            items={[
              {
                text: `Edit ${isProject ? 'Project' : 'Goal'}`,
                href: `/workspaces/${data.team_id}/${
                  isProject ? 'projects' : 'goals'
                }/${data.id}/settings`,
                Icon: () => (
                  <Icon icon={PencilIcon} fill={theme.colors.navy[5]} />
                ),
              },
              {
                text: `Delete ${isProject ? 'Project' : 'Goal'}`,
                action: onDelete,
                Icon: () => (
                  <Icon icon={TrashIcon} fill={theme.colors.red[2]} />
                ),
                textClass: 'text-red-3',
              },
            ]}
          />
        )}
      </button>
    </Link>
  )
}

export default ProgressCard
