import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faChartBar } from '@fortawesome/free-solid-svg-icons'

const ChartToggle = ({ activeChartType = 'line', setActiveChartType }) => {
  return (
    <div className="rounded-full border border-dark-15 w-24 flex">
      <button
        className="flex-1 flex justify-center px-3 py-3"
        onClick={() => setActiveChartType('line')}>
        <FontAwesomeIcon
          icon={faChartLine}
          className={`h-5 w-5 ${
            activeChartType === 'line' ? 'text-teal-4' : 'text-dark-50'
          }`}
        />
      </button>
      <button
        className="flex-1 flex justify-center px-3 py-3 border-l border-dark-15"
        onClick={() => setActiveChartType('bar')}>
        <FontAwesomeIcon
          icon={faChartBar}
          className={`h-5 w-5 ${
            activeChartType === 'bar' ? 'text-teal-4' : 'text-dark-50'
          }`}
        />
      </button>
    </div>
  )
}

export default ChartToggle
