import { useState, useContext } from 'react'
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import Loader from 'react-loader-spinner'

import { TextInput, Button, FileInput, Icon, Avatar } from '.'
import { GlobalContext } from '../context/GlobalContext'
import api from '../services/api'
import CloseIcon from '../public/close_circle.svg'

const AccountSettings = ({ account, refetch }) => {
  const globalContext = useContext(GlobalContext)
  const { addToast } = useToasts()
  const [hasMadeChange, setHasMadeChange] = useState(false)
  const [localAccount, setLocalAccount] = useState(account || null)
  const [revertToAccount, setRevertToAccount] = useState(account || null)

  const updateLocalAccount = (key, val) => {
    setHasMadeChange(true)
    setLocalAccount({
      ...localAccount,
      [key]: val,
    })
  }

  const mutation = useMutation(() => api.updateUser(localAccount), {
    onSuccess: () => {
      setHasMadeChange(false)
      globalContext.updateUser({ user: localAccount })
      setRevertToAccount(localAccount)
      addToast('Account updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })
      refetch()
    },
    onError: globalContext.handleFetchError,
  })

  const revert = () => {
    setLocalAccount(revertToAccount)
    setHasMadeChange(false)
  }

  return (
    <div className="p-6">
      <div className="flex flex-col sm:flex-row items-center mb-6">
        <Avatar image={localAccount?.image} type="team" />
        <div className="flex-1 flex-col flex justify-center">
          <div className="flex flex-col sm:flex-row items-center">
            <FileInput
              onUpdate={(imageString) =>
                updateLocalAccount('image', imageString)
              }
            />
            {localAccount?.image && (
              <Button
                action={() => updateLocalAccount('image', '')}
                text="REMOVE"
                size="small"
                theme="red-transparent"
                IconLeft={() => (
                  <Icon
                    icon={(props) => <CloseIcon {...props} />}
                    size="small"
                    fill="#de4d15"
                  />
                )}
              />
            )}
          </div>
          <div className="text-dark-50 mt-4">
            Upload a photo for your profile (JPG, JPEG, PNG only; 1 MB Maximum File Size).
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <TextInput
          label="First Name"
          onChange={(e) => updateLocalAccount('first_name', e.target.value)}
          value={localAccount?.first_name}
          classes="flex-1"
        />
        <TextInput
          label="Last Name"
          onChange={(e) => updateLocalAccount('last_name', e.target.value)}
          value={localAccount?.last_name}
          classes="flex-1 md:ml-3"
        />
      </div>
      <TextInput
        label="Title"
        onChange={(e) => updateLocalAccount('title', e.target.value)}
        value={localAccount?.title}
      />
      <TextInput
        label="Email"
        onChange={(e) => updateLocalAccount('email', e.target.value)}
        value={localAccount?.email}
      />
      <div className="flex justify-end">
        <Button
          text="Discard"
          theme="ghost"
          classes="mr-3"
          disabled={!hasMadeChange}
          action={revert}
        />
        <Button
          text="Save"
          disabled={!hasMadeChange}
          action={mutation.mutate}
        />
      </div>
      {mutation.isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50 z-50">
          <Loader type="TailSpin" height="72" width="72" color="white" />
        </div>
      )}
    </div>
  )
}

export default AccountSettings
